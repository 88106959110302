import React, { ChangeEventHandler } from 'react';

interface RadioButtonProps {
    group: string,
    value: string,
    checked: boolean,
    onChange: ChangeEventHandler<HTMLInputElement>,
    children: HTMLElement|string
}

export const RadioButton = (props:RadioButtonProps) => {
    const id = `${props.group}-${props.value}`;
    return (
        <div className='radiobox--custom radiobox--custom__small'>
            <input
                type="radio"
                name={props.group}
                id={id}
                value={props.value}
                checked={props.checked}
                onChange={props.onChange}
            />
            <label htmlFor={id}>
                <span>{props.children}</span>
            </label>
        </div>
    );
};
