import { createSelector } from 'reselect';

const queryStringSelector = (state) => state.searchBox.queryString;
const settingsSelector = (state) => state.settings;
const documentTypeFiltersSelector = (state) => state.documentTypeFilters;

export const prospectusRegistrySearchStateSelector = createSelector(
    queryStringSelector,
    settingsSelector,
    documentTypeFiltersSelector,
    (queryStringSelector, settingsSelector, documentTypeFilters) => {
        const documentTypeFilter = documentTypeFilters.items[documentTypeFilters.activeFilter]
            ? documentTypeFilters.items[documentTypeFilters.activeFilter].key
            : '';        
        return {
            queryString: queryStringSelector,
            lang: settingsSelector.lang,
            activeDocumentTypeFilter: documentTypeFilter,
            activeDocumentTypeFilterId: documentTypeFilters.activeFilter,            
            url: `?q=${queryStringSelector}&l=${settingsSelector.lang}&d=${documentTypeFilter}`,
        };
    }
);
