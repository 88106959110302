import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    fetchFilters,
    selectDocumentType
} from './prospectusRegistryActions';
import Loader from '../components/loader';
import SelectList from './selectList';
import { translateLang } from '../translate';
import { LanguageContext } from '../components/LanguageContext';
import { isMobile } from '../util/ssrBase';

class ProspectusRegistrySearchFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: !isMobile
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(fetchFilters(this.context.code));
    }

    render() {
        const { dispatch, documentTypes } = this.props;
        const language = this.context;
        let resultHTML = '';

        if (documentTypes.loaded) {
            resultHTML = (
                <div className="w-full border-t border-b border-l-4 border-r border-bluegreen-300 bg-white-400 mb-6" >
                    <button
                        title={translateLang('newsarchive.headers.filter', language.code)}
                        type="button"
                        className="flex items-center justify-between w-full p-4 cursor-pointer hover:text-bluegreen-500 text-black-400 text-md"
                        aria-expanded={this.state.showFilters}
                        aria-controls="filterselectors"
                        onClick={() => this.setState({ showFilters: !this.state.showFilters })}
                    >
                        <span>{translateLang('newsarchive.headers.filter', language.code)}</span>
                        <span className="material-icons" aria-hidden={!this.state.showFilters}>
                            {this.state.showFilters ? 'close' : 'expand_more'}
                        </span>
                    </button>

                    {this.state.showFilters && (
                        <div className="common-row">
                            <div className="u-half-width">
                                <SelectList
                                    label={translateLang('prospectus.choosedocumenttype', language.code)}
                                    filters={documentTypes.items}
                                    show={documentTypes.loaded}
                                    defaultOptionText={translateLang('prospectus.alltypes', language.code)}
                                    activeFilter={documentTypes.activeFilter}
                                    onFilterClick={(id) => dispatch(selectDocumentType(id, name, true))}
                                />
                            </div>
                        </div>
                    )}
                </div>
            );
        } else {
            resultHTML = <Loader isLoading languageCode={language.code} />;
        }

        return resultHTML;
    }
}

ProspectusRegistrySearchFilters.propTypes = {
    classfilters: PropTypes.array
};

ProspectusRegistrySearchFilters.contextType = LanguageContext;

function mapStateToProps(state) {
    return {
        documentTypes: state.documentTypeFilters      
    };
}

export default connect(mapStateToProps)(ProspectusRegistrySearchFilters);
