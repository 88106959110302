import * as supervision from './components/subjectSelector/supervision';

export const NAME = 'themeCategoryPage';

export function init() {
    const dispatchSupervisionCategories = (items) => {
        const themePages = document.getElementsByClassName('topic-category-item-container');
        if (items.length === 0) {
            for (const item of themePages) {
                item.style.display = 'block';
            }
            return;
        }

        for (const item of themePages) {
            let categories = item.dataset.categories.split(' ');
            categories = categories.map((element) => parseInt(element));
            const found = items.some((r) => categories.indexOf(parseInt(r)) >= 0);
            if (!found) item.style.display = 'none';
            else {
                item.style.display = 'block';
            }
        }
    };

    supervision.bootstrap(dispatchSupervisionCategories);

    const initialSupervisionCategories = supervision.getSavedSupervisionCategories();
    dispatchSupervisionCategories(initialSupervisionCategories);
}
