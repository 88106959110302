import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { useDebouncedCallback } from 'use-debounce';
import Mark from 'mark.js';
import { document } from '../util/ssrBase';

interface FindInDocumentProps {
    pageHeading: string;
    publication: string;
    subPages: {
        heading: SubPageHeadings;
        pages: SubPageHeadings[];
    };
    language: string;
}
interface SubPageHeadings {
    id: string;
    title: string;
}

interface SectionHeading {
    id: string;
    type: string;
    title: string;
    hits: number;
}

interface ChapterHeading {
    id: string;
    type: string;
    title: string;
    hits: number;
    sectionHeadings: SectionHeading[];
}

interface DocumentHeading {
    id: string;
    type: string;
    title: string;
}

type Section = {
    sectionHeading: HTMLElement;
    sectionCont: HTMLElement[];
};

type Chapter = {
    heading: HTMLElement;
    cont: HTMLElement[];
    sections?: Section[];
};

const formatTitle = (text: string): string =>
    text
        .replace(/<a[^>]*>.*?<\/a>/g, '')
        .replace(/<[^>]+>|&nbsp;/g, '')
        .trim();

const formatId = (text: string): string =>
    text
        .toLowerCase()
        .replace(/æ/g, 'ae')
        .replace(/ø/g, 'oe')
        .replace(/å/g, 'aa')
        .replace(/[.\s]+/g, '-')
        .replace(/[^a-zA-Z0-9-]|&nbsp;/g, '');

const TABLET_BREAKPOINT = 1024;

export const FindInDocument: React.FC<FindInDocumentProps> = ({
    pageHeading,
    publication,
    subPages,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    language,
}) => {
    useEffect(() => {
        const urlHash = decodeURI(window.location.hash);
        if (urlHash) {
            const titleEl = document.getElementById(urlHash.substring(1, urlHash.length));
            if (titleEl) {
                titleEl.scrollIntoView();
            }
        }
    }, []);

    const [isSmallScreen, setIsSmallScreen] = useState<boolean | null>(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

    const debouncedHandleResize = useDebouncedCallback(() => {
        setIsSmallScreen(window.innerWidth < TABLET_BREAKPOINT);
    }, 200);

    useEffect(() => {
        debouncedHandleResize();

        window.addEventListener('resize', debouncedHandleResize);
        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
            debouncedHandleResize.cancel();
        };
    }, [debouncedHandleResize]);

    useEffect(() => {
        if (isSmallScreen) {
            if (isDrawerOpen) {
                document.body.classList.add('overflow-hidden');
            } else {
                document.body.classList.remove('overflow-hidden');
            }
        }
        return () => {
            document.body.classList.remove('overflow-hidden');
        };
    }, [isSmallScreen, isDrawerOpen]);

    const content = document.getElementById(publication) as HTMLElement;
    const [isMounted, setIsMounted] = useState(false);

    // State hooks
    const [chapters, setChapters] = useState<Chapter[]>([]);
    const [toc, setToc] = useState<ChapterHeading[]>([]);
    const [activeId, setActiveId] = useState<string | null>(null);
    const [openChapter, setOpenChapter] = useState<string | null>(null);

    const [searchTerm, setSearchTerm] = useState('');
    const [searchActive, setSearchActive] = useState<boolean>(false);
    const [resultsCount, setResultsCount] = useState<number>(0);
    const [currentResult, setCurrentResult] = useState<number>(0);

    // Button focus states
    const [focusPrevBtn, setFocusPrevBtn] = useState<boolean>(false);
    const [focusNextBtn, setFocusNextBtn] = useState<boolean>(false);

    // Refs
    const tocContainerRef = useRef<HTMLDivElement | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    // Memoized values
    const tocChapters = useMemo(() => toc, [toc]);

    const documentHeading = useMemo<DocumentHeading | null>(() => {
        if (!pageHeading) return null;
        const headingElement = document.querySelector(
            `[data-heading="${pageHeading}"]`
        ) as HTMLElement;

        if (!headingElement) return null;

        const headingTitle = formatTitle(headingElement.innerHTML);
        const headingId = formatId(headingTitle);
        headingElement.setAttribute('id', headingId); // Set page heading's id in the DOM, based on the header text

        return {
            id: headingId,
            type: headingElement.tagName,
            title: headingTitle,
        };
    }, [pageHeading]);

    const initialToc = useMemo<ChapterHeading[]>(() => {
        if (!content) return [];
        const h2Elements = Array.from(content.querySelectorAll('h2')) as HTMLElement[];
        const tocArr: ChapterHeading[] = [];

        h2Elements.forEach((element) => {
            const chapterTitle = formatTitle(element.innerHTML);
            const chapterId = formatId(chapterTitle);
            element.setAttribute('id', chapterId); // Set h2's id in the DOM, based on the header text

            const chapterEntry: ChapterHeading = {
                id: chapterId,
                type: element.tagName,
                title: chapterTitle,
                hits: 0,
                sectionHeadings: [],
            };

            let siblingElement = element.nextElementSibling as HTMLElement | null;
            while (siblingElement && siblingElement.tagName !== 'H2') {
                if (siblingElement.tagName === 'H3') {
                    const sectionTitle = formatTitle(siblingElement.innerHTML);
                    const sectionId = formatId(sectionTitle);
                    siblingElement.setAttribute('id', sectionId); // Set h3's id in the DOM, based on the header text

                    chapterEntry.sectionHeadings.push({
                        id: sectionId,
                        type: siblingElement.tagName,
                        title: sectionTitle,
                        hits: 0,
                    });
                }
                siblingElement = siblingElement.nextElementSibling as HTMLElement | null;
            }

            tocArr.push(chapterEntry);
        });

        return tocArr;
    }, [content]);

    // Initial TOC
    useEffect(() => {
        setToc(initialToc);
    }, [initialToc]);

    // Build chapters array
    useEffect(() => {
        const chaptersArray: Chapter[] = [];
        Array.from(document.querySelectorAll('h2')).forEach((h2) => {
            const cont: HTMLElement[] = [];
            const sections: Section[] = [];
            let currentSection: Section | null = null;
            let nextSibling: Element | null = h2.nextElementSibling;

            while (nextSibling) {
                if (nextSibling.tagName === 'H2' || nextSibling.querySelector('h2')) {
                    break;
                }
                if (nextSibling instanceof HTMLElement) {
                    if (nextSibling.tagName === 'H3') {
                        if (currentSection) sections.push(currentSection);
                        currentSection = {
                            sectionHeading: nextSibling,
                            sectionCont: [],
                        };
                    } else if (currentSection) {
                        currentSection.sectionCont.push(nextSibling);
                    } else {
                        cont.push(nextSibling);
                    }
                }
                nextSibling = nextSibling.nextElementSibling;
            }

            if (currentSection) sections.push(currentSection);

            chaptersArray.push(
                sections.length > 0 ? { heading: h2, cont, sections } : { heading: h2, cont }
            );
        });
        setChapters(chaptersArray);
    }, []);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setIsMounted(true);
        }
    }, []);

    // Update active chapter
    const updateActiveChapter = useCallback(() => {
        const viewportCenter = window.innerHeight / 2;
        let newActiveId = activeId;
        let newOpenChapter = openChapter;
        let found = false;

        chapters.forEach(({ heading, cont, sections }) => {
            if (sections) {
                sections.forEach((section) => {
                    const sectionHeadingRect = section.sectionHeading.getBoundingClientRect();
                    const sectionContentRect =
                        section.sectionCont[
                            section.sectionCont.length - 1
                        ]?.getBoundingClientRect() || sectionHeadingRect;
                    const sectionTop = sectionHeadingRect.top;
                    const sectionBottom = sectionContentRect.bottom;

                    if (sectionTop <= viewportCenter && sectionBottom >= viewportCenter) {
                        newActiveId = section.sectionHeading.id;
                        newOpenChapter = heading.id;
                        found = true;

                        if (newActiveId !== activeId) {
                            setActiveId(newActiveId);
                        }

                        if (newOpenChapter !== openChapter) {
                            setOpenChapter(newOpenChapter !== newActiveId ? newOpenChapter : null);
                        }
                    }
                });
            }

            if (!found) {
                const headingRect = heading.getBoundingClientRect();
                const contentRect = cont[cont.length - 1]?.getBoundingClientRect() || headingRect;
                const chapterTop = headingRect.top;
                const chapterBottom = contentRect.bottom;

                if (chapterTop <= viewportCenter && chapterBottom >= viewportCenter) {
                    newActiveId = heading.id;
                    newOpenChapter = heading.id;
                    found = true;

                    if (newActiveId !== activeId) {
                        setActiveId(newActiveId);
                    }

                    if (newOpenChapter !== openChapter) {
                        setOpenChapter(newOpenChapter);
                    }
                }
            }
        });

        if (newActiveId !== activeId || newOpenChapter !== openChapter) {
            setTimeout(() => {
                scrollToActiveElement(newActiveId, newOpenChapter);
            }, 200);
        }
    }, [activeId, openChapter, chapters]);

    const debouncedUpdateAfterScroll = useDebouncedCallback(() => {
        updateActiveChapter();
    }, 200);

    const debouncedUpdateAfterScrollMemo = useMemo(
        () => debouncedUpdateAfterScroll,
        [debouncedUpdateAfterScroll]
    );

    useEffect(() => {
        const handleScroll = () => {
            debouncedUpdateAfterScrollMemo();
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [debouncedUpdateAfterScrollMemo]);

    // Scroll to active chapter/section inside TOC
    const scrollToActiveElement = (chapterId: string | null, sectionId: string | null) => {
        const scrollTarget = chapterId !== sectionId ? sectionId : chapterId;

        if (scrollTarget && tocContainerRef.current) {
            const container = tocContainerRef.current;
            const activeElement = container?.querySelector(
                `[data-chapter="${scrollTarget}"]`
            ) as HTMLElement;

            if (activeElement) {
                const containerRect = container.getBoundingClientRect();
                const elementRect = activeElement.getBoundingClientRect();

                const isVisible =
                    elementRect.top >= containerRect.top &&
                    elementRect.bottom <= containerRect.bottom;

                if (!isVisible) {
                    const offset = elementRect.top - containerRect.top;

                    const scrollPosition =
                        container.scrollTop +
                        offset -
                        container.clientHeight / 2 +
                        activeElement.clientHeight / 2;

                    container.scrollTo({
                        top: scrollPosition,
                        behavior: 'smooth',
                    });
                }
            }
        }
    };

    // Search Handlers
    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleSearch();
    };

    const handleSearch = useCallback(() => {
        if (!inputRef.current || inputRef.current.value.trim() === '') {
            clearHighlights();
            return;
        }

        const currentSearchTerm = inputRef.current.value.trim();
        setSearchTerm(currentSearchTerm);
        setSearchActive(true);

        if (!content) return;

        let currentChapter: ChapterHeading | null = null;
        let currentSection: SectionHeading | null = null;

        const chapterHits: { id: string; chapterId?: string; element: HTMLElement }[] = [];

        content.querySelectorAll('h2, h3, p, ul, ol').forEach((el) => {
            if (el.tagName === 'H2') {
                currentChapter = updatedToc.find((chapter) => chapter.id === el.id) || null;
                currentSection = null;
            } else if (el.tagName === 'H3') {
                currentSection =
                    currentChapter?.sectionHeadings.find((section) => section.id === el.id) || null;
            }

            const text = el.textContent?.toLowerCase() || '';
            const searchTermLower = currentSearchTerm.toLowerCase();

            const matchCount = (text.match(new RegExp(searchTermLower, 'g')) || []).length;

            if (matchCount > 0) {
                if (currentSection) {
                    currentSection.hits = (currentSection.hits || 0) + matchCount;

                    chapterHits.push({
                        id: currentSection.id,
                        chapterId: currentChapter?.id,
                        element: el as HTMLElement,
                    });
                }
                if (currentChapter) {
                    currentChapter.hits = (currentChapter.hits || 0) + matchCount;

                    chapterHits.push({
                        id: currentChapter.id,
                        element: el as HTMLElement,
                    });
                }
            }
        });

        setToc((prevToc) => {
            if (JSON.stringify(prevToc) !== JSON.stringify(updatedToc)) {
                return updatedToc;
            }
            return prevToc;
        });

        const instance = new Mark(content);
        instance.unmark({
            done: () => {
                instance.mark(currentSearchTerm, {
                    separateWordSearch: false,
                    className: 'bg-bluegreen-500 text-white-400 not-italic',
                    each: (mark) => {
                        const markChapter = chapterHits.find(({ element: el }) =>
                            el.contains(mark)
                        );
                        mark.id = markChapter?.id || '';
                        if (markChapter?.chapterId) {
                            mark.setAttribute('data-chapter-id', markChapter?.chapterId);
                        }
                    },
                    done: (marks) => {
                        setResultsCount(marks);
                        if (marks > 0) {
                            setCurrentResult(0);
                            scrollToResult(0);
                            setFocusNextBtn(true);
                        } else {
                            setTimeout(() => {
                                inputRef.current?.focus();
                            }, 0);
                        }
                    },
                });
            },
        });
    }, [toc, content, searchTerm]);

    const scrollToResult = (index: number) => {
        if (!content) return;

        const marks = content.querySelectorAll('mark');
        if (marks.length === 0) return;

        marks.forEach((mark, i) => {
            const markElement = mark as HTMLElement;
            markElement.tabIndex = 0;

            if (i === index) {
                const viewportCenter = window.innerHeight / 2;
                const markRect = markElement.getBoundingClientRect();
                const markOffsetTop = window.scrollY + markRect.top;
                const scrollTop = markOffsetTop - viewportCenter + markRect.height / 2;

                window.scrollTo({ top: scrollTop, behavior: 'smooth' });

                markElement.className =
                    'italic tracking-[0.52px] bg-bluegreen-600 text-white-400 focus:outline-1 focus:outline-dashed';
            } else {
                markElement.className = 'not-italic bg-bluegreen-600 text-white-400';
            }
        });

        setCurrentResult(index);
    };

    const clearHighlights = () => {
        if (!content) return;

        const instance = new Mark(content);
        instance.unmark();

        setToc((prevToc) =>
            prevToc.map((chapter) => {
                const updatedSections = chapter.sectionHeadings.map((section) =>
                    section.hits !== 0 ? { ...section, hits: 0 } : section
                );
                return chapter.hits !== 0 || updatedSections.some((s) => s.hits !== 0)
                    ? { ...chapter, hits: 0, sectionHeadings: updatedSections }
                    : chapter;
            })
        );

        setResultsCount(0);
        setCurrentResult(0);
        setSearchTerm('');
        setSearchActive(false);
    };

    const navigateResults = (direction: 'previous' | 'next') => {
        setFocusPrevBtn(direction === 'previous');
        setFocusNextBtn(direction === 'next');

        if (!content) return;

        const marks = content.querySelectorAll('mark');
        if (marks.length === 0) return;

        const newIndex =
            direction === 'next'
                ? (currentResult + 1) % marks.length
                : (currentResult - 1 + marks.length) % marks.length;

        setTimeout(() => {
            scrollToResult(newIndex);
        }, 100);
    };

    const updatedToc = useMemo(
        () =>
            toc.map((chapter) => ({
                ...chapter,
                hits: 0,
                sectionHeadings: chapter.sectionHeadings.map((section) => ({
                    ...section,
                    hits: 0,
                })),
            })),
        [toc]
    );

    // Toggle expanded/collapsed chapter in TOC
    const toggleChapter = (id: string) => {
        setOpenChapter(openChapter === id ? null : id);
    };

    // Component: Find/search in document
    const SearchDocument: React.FC = React.memo(() => {
        const [inputValue, setInputValue] = useState(searchTerm);
        const [shouldSearch, setNewSearch] = useState<boolean>(!searchActive);

        const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const newInput = e.currentTarget.value;
            if (newInput.trim() !== '' && newInput !== searchTerm) {
                setNewSearch(true);
            }
            setInputValue(newInput);
        };

        // TO DO: Translations
        const label = shouldSearch ? 'Finn i dokument' : 'Nullstill søk';
        const icon = shouldSearch ? 'find_in_page' : 'close';
        const resultLabel =
            resultsCount > 0
                ? `Treff ${currentResult + 1} av ${resultsCount} på "${searchTerm}"`
                : `Vi fant ingen treff på "${searchTerm}"`;

        return (
            <div className="flex flex-col gap-2 mb-4">
                <h2 className="font-semibold">Finn i dokument</h2>
                <form
                    id="findInDocumentForm"
                    role="search"
                    className={classNames(
                        'peer relative flex w-full h-10 find-in-document',
                        searchActive && resultsCount === 0 ? 'noHits' : 'hits'
                    )}
                    onSubmit={handleFormSubmit}
                >
                    <input
                        type="search"
                        id="findInDocument"
                        ref={inputRef}
                        value={inputValue}
                        placeholder="Hva leter du etter?"
                        aria-label="Søk i dokumentet"
                        autoComplete="off"
                        className={classNames(
                            'w-full peer-input bg-lightgray-400',
                            searchActive && searchTerm
                                ? 'placeholder:text-black-400'
                                : 'placeholder:text-[#7E7E7E]'
                        )}
                        onChange={onInputChange}
                    />

                    <button
                        type="button"
                        onClick={() => (shouldSearch ? handleSearch() : clearHighlights())}
                        className="flex items-center justify-center -ml-1"
                        aria-label={label}
                    >
                        <span className="text-base material-icons-outlined" aria-hidden="true">{icon}</span>
                    </button>
                </form>
                {searchActive &&
                    (resultsCount > 0 ? (
                        <div
                            className={classNames(
                                'flex items-center gap-2',
                                inputValue !== searchTerm ? 'opacity-50' : 'opacity-100'
                            )}
                        >
                            <div className="inline-flex gap-1">
                                <button
                                    type="button"
                                    autoFocus={focusPrevBtn}
                                    className="flex items-center justify-center previousResult"
                                    onClick={() => navigateResults('previous')}
                                >
                                    <span className="text-base material-icons-outlined">
                                        arrow_upward
                                    </span>
                                </button>
                                <button
                                    type="button"
                                    autoFocus={focusNextBtn}
                                    className="flex items-center justify-center nextResult"
                                    onClick={() => navigateResults('next')}
                                >
                                    <span className="text-base material-icons-outlined">
                                        arrow_downward
                                    </span>
                                </button>
                            </div>
                            <p className="text-base leading-tight">{resultLabel}</p>
                        </div>
                    ) : (
                        <div
                            className={`flex ${
                                inputValue !== searchTerm ? 'opacity-50' : 'opacity-100'
                            }`}
                        >
                            <p className="leading-tight text-magenta-400">{resultLabel}</p>
                        </div>
                    ))}
            </div>
        );
    });

    // Component: TOC

    // TO DO: Translations
    const Toc: React.FC = React.memo(() => (
        <nav role="navigation" aria-label="Table of content" className="flex flex-col gap-2">
            <h3 className="font-semibold">Innhold</h3>
            <div
                ref={tocContainerRef}
                className="overflow-y-scroll max-h-[50vh] lg:max-h-[80vh] relative pt-2 bg-lightgray-400 ml-1 shadow-[-4px_0px_theme('colors.yellow.500')]"
            >
                <ul className="relative flex flex-col px-1.5 py-1 -mb-3 gap-y-2.5">
                    {!!documentHeading && (
                        <li
                            key={documentHeading.id}
                            id={documentHeading.id}
                            className="grid items-start grid-flow-col pl-1 gap-x-1"
                        >
                            <h4>
                                <a
                                    href={`#${documentHeading.id}`}
                                    className="font-semibold leading-tight underline text-md text-bluegreen-600 decoration-1 hover:no-underline"
                                >
                                    {documentHeading.title}
                                </a>
                            </h4>
                        </li>
                    )}
                    {tocChapters.map((chapter) => {
                        const isChapterActive =
                            chapter.id === activeId ||
                            chapter.sectionHeadings.some((section) => section.id === activeId);

                        const hasSections = chapter.sectionHeadings.length > 0;

                        const hasOnlySectionHits =
                            hasSections &&
                            isChapterActive &&
                            chapter.sectionHeadings.reduce(
                                (totalHits, section) => totalHits + (section.hits > 0 ? 1 : 0),
                                0
                            ) === chapter.hits;

                        return (
                            <li
                                key={chapter.id}
                                data-chapter={chapter.id}
                                className={classNames(
                                    'grid items-start grid-flow-col',
                                    hasSections
                                        ? 'grid-cols-[20px_auto] gap-x-1 items-baseline ml-0.5'
                                        : 'ml-6 pl-0.5',
                                    !isChapterActive ? 'font-semibold' : 'font-normal'
                                )}
                            >
                                {hasSections && (
                                    <button
                                        type="button"
                                        className="row-span-2 leading-none h-6 w-6"
                                        onClick={() => toggleChapter(chapter.id)}
                                        aria-expanded={openChapter === chapter.id}
                                        aria-controls={`section-${chapter.id}`}
                                    >
                                        <span className="text-xl material-icons-outlined text-bluegreen-600">
                                            {openChapter === chapter.id
                                                ? 'remove_circle_outline'
                                                : 'add_circle_outline'}
                                        </span>
                                    </button>
                                )}

                                <a
                                    href={`#${chapter.id}`}
                                    className={classNames(
                                        'flex text-base w-fit text-bluegreen-600 leading-tight py-0.5 underline decoration-1 hover:no-underline border-l-2 pl-1',
                                        chapter.hits > 0 && !hasOnlySectionHits
                                            ? 'border-yellow-600'
                                            : 'border-transparent',
                                        chapter.id === activeId && activeId !== undefined
                                            ? 'font-semibold'
                                            : 'font-normal'
                                    )}
                                >
                                    {chapter.title}
                                </a>
                                {hasSections && (
                                    <ul
                                        className={`${
                                            openChapter === chapter.id
                                                ? 'flex flex-col gap-y-0.5 mt-1 visible'
                                                : 'invisible h-0 overflow-hidden'
                                        }`}
                                    >
                                        {chapter.sectionHeadings.map((section) => (
                                            <li
                                                key={section.id}
                                                data-chapter={chapter.id}
                                                className={classNames(
                                                    'flex mx-1.5 border-l-2',
                                                    section.hits > 0
                                                        ? 'border-yellow-600'
                                                        : 'border-transparent'
                                                )}
                                            >
                                                <a
                                                    href={`#${section.id}`}
                                                    className={classNames(
                                                        'block ml-1 text-sm leading-tight text-bluegreen-600 py-1 underline hover:no-underline decoration-1',
                                                        section.id === activeId &&
                                                            activeId !== undefined
                                                            ? 'font-semibold'
                                                            : 'font-normal'
                                                    )}
                                                >
                                                    {section.title}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        );
                    })}

                    {subPages && subPages.pages.length > 0 && (
                        <li
                            key={subPages.heading.id}
                            data-chapter={subPages.heading.id}
                            className={classNames(
                                'grid grid-flow-col grid-cols-[20px_auto] gap-x-1 items-baseline ml-0.5'
                            )}
                        >
                            <button
                                type="button"
                                className="row-span-2 leading-none h-6 w-6"
                                onClick={() => toggleChapter(subPages.heading.id)}
                                aria-expanded={openChapter === subPages.heading.id}
                                aria-controls={`section-${subPages.heading.id}`}
                            >
                                <span className="text-lg material-icons-outlined text-bluegreen-600">
                                    {openChapter === subPages.heading.id
                                        ? 'remove_circle_outline'
                                        : 'add_circle_outline'}
                                </span>
                            </button>

                            <a
                                href={`#${subPages.heading.id}`}
                                className={classNames(
                                    'flex text-base w-fit text-bluegreen-600 leading-tight py-0.5 underline decoration-1 hover:no-underline border-l-2 border-transparent pl-1',
                                    subPages.heading.id === activeId && activeId !== undefined
                                        ? 'font-semibold'
                                        : 'font-normal'
                                )}
                            >
                                {subPages.heading.title}
                            </a>

                            <ul
                                className={`${
                                    openChapter === subPages.heading.id
                                        ? 'flex flex-col gap-y-0.5 mt-1 visible'
                                        : 'invisible h-0 overflow-hidden'
                                }`}
                            >
                                {subPages.pages.map((page) => (
                                    <li
                                        key={page.id}
                                        data-chapter={subPages.heading.id}
                                        className={classNames(
                                            'flex mx-1.5 border-l-2 border-transparent'
                                        )}
                                    >
                                        <a
                                            href={`#${page.id}`}
                                            className={classNames(
                                                'block ml-1 text-sm leading-tight text-bluegreen-600 py-1 underline hover:no-underline decoration-1',
                                                page.id === activeId && activeId !== undefined
                                                    ? 'font-semibold'
                                                    : 'font-normal'
                                            )}
                                        >
                                            {page.title}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    )}
                </ul>

                <div className="sticky bottom-0 w-full h-8 pointer-events-none bg-gradient-to-b from-transparent to-black-100" />
            </div>
        </nav>
    ));

    if (isMounted) {
        const drawerLabel = (
            <span>
                {searchActive ? (
                    <>
                        {resultsCount > 0 ? 'Treff: ' : 'Ingen treff: '}
                        <em>&quot;{searchTerm}&quot;</em>
                    </>
                ) : (
                    'Finn i dokument'
                )}
            </span>
        );

        return (
            <>
                {isSmallScreen ? (
                    // TO DO: Position relative to potential cookie banner
                    <div className="flex flex-col gap-6 publication-utils">
                        {isDrawerOpen && (
                            <div className="fixed inset-0 z-10 transition-opacity duration-300 ease-in-out bg-white-400 bg-opacity-80" />
                        )}
                        <div
                            className={`fixed bottom-0 bg-white-400 left-0 w-full z-50 transition-transform duration-300 ease-in-out bg-white h-[86vh] ${
                                isDrawerOpen
                                    ? 'translate-y-0 backdrop-opacity-80'
                                    : 'translate-y-[calc(100%-2.5rem)]'
                            }`}
                        >
                            <div className="relative flex items-center justify-center w-full gap-2 p-3 text-sm font-semibold leading-4 text-white-400 bg-bluegreen-500">
                                <span aria-hidden="true" className="text-base material-icons-outlined">                                    
                                        find_in_page
                                </span>
                                {drawerLabel}
                                <button
                                    type="button"
                                    className="absolute right-3"
                                    onClick={toggleDrawer}
                                >
                                    <span className="text-2xl text-white-400 material-icons-outlined">
                                        {isDrawerOpen ? 'close' : 'keyboard_arrow_up'}
                                    </span>
                                </button>
                            </div>

                            <div
                                className={`p-6 transition-opacity duration-150 ease-in-out ${
                                    isDrawerOpen
                                        ? 'opacity-100 h-full'
                                        : 'opacity-0 pointer-events-none'
                                }`}
                            >
                                <SearchDocument />
                                <Toc />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="flex-col hidden gap-6 lg:flex publication-utils">
                        <SearchDocument />
                        <Toc />
                    </div>
                )}
            </>
        );
    }
    return null;
};
