import React, { FC, useContext } from "react";
import { VirkeregSearchHit } from "../../generatedTypes/FT.Web.Controllers.Api.Models.Virkereg";
import { translateLang } from "../../translate";
import { LanguageContext } from "../../components/LanguageContext";

export const VregSearchHit: FC<VirkeregSearchHit> = (hit: VirkeregSearchHit) => {
    const languageContext = useContext(LanguageContext);
    return (
        <div className="p-6 bg-white-400 mb-3" key={hit.id}>
            {hit.typeDescription === 'fromVirkereg' &&
                <div className="text-xs text-yellow-600">{translateLang('vreg.search.fromVreg', languageContext.code)}</div>
            }
            
            <h2 className=" text-xl font-semibold listing">
                <a href={hit.url} title={hit.name} className=" text-black-400 hover:text-bluegreen-500 uppercase">
                    {hit.name}
                </a>
            </h2>

            <ul className="flex flex-wrap lg:flex-nowrap flex-row mt-2 -mx-3 -mb-3">
                <li className="flex items-center space-x-1 p-3">
                    <span className="material-icons text-bluegreen-500 text-lg" aria-hidden="true">corporate_fare</span>
                    <span className="text-yellow-600 uppercase text-base font-semibold">
                        {translateLang(`vreg.entityTypes.${hit.type.toLowerCase()}`, languageContext.code)}
                    </span>
                </li>
                {hit.licences &&
                    <li className="flex items-center space-x-1 p-3">
                        <span className="material-icons text-bluegreen-500 text-lg" aria-hidden="true">list_alt</span>
                        <span className="text-yellow-600 uppercase text-base font-semibold">
                            {hit.licences}
                        </span>
                    </li>
                }
                {hit.country &&
                    <li className="flex items-center space-x-1 p-3">
                        <span className="material-icons text-bluegreen-500 text-lg" aria-hidden="true">public</span>
                        <span className="text-yellow-600 uppercase text-base font-semibold">
                            {hit.country}
                        </span>
                    </li>
                }
                {hit.county &&
                    <li className="flex items-center space-x-1 p-3">
                        <span className="material-icons text-bluegreen-500 text-lg" aria-hidden="true">location_pin</span>
                        <span className="text-yellow-600 uppercase text-base font-semibold">
                            {hit.county}
                        </span>
                    </li>
                }
            </ul>
        </div>);
}