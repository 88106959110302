import React, { useState } from 'react';
import { translateLang } from '../../translate';

interface SortablePageListingProps {
    language: string;
    childPages: ChildPage[];
}

interface ChildPage {
    title: string;
    url: string;
    preamble: string;
}

export const SortablePageListing: React.FC<SortablePageListingProps> = ({
    language,
    childPages,
}) => {
    const [isAlphabetical, setIsAlphabetical] = useState(false);

    const sortedPages = isAlphabetical
        ? [...childPages].sort((a, b) => a.title.localeCompare(b.title))
        : childPages;

    const sortButtonLabel = `${translateLang(
        isAlphabetical ? 'publications.resetSortAlphabetically' : 'publications.sortAlphabetically',
        language
    )}`;

    return (
        <div className="flex flex-col gap-6 pt-4">
            <button
                type="button"
                onClick={() => setIsAlphabetical(!isAlphabetical)}
                className="flex items-center pl-1 ml-3 w-fit gap-0.5 text-bluegreen-600 hover:bg-bluegreen-100 focus:outline-1 focus:outline-bluegreen-500 focus:outline-dashed focus-visible:outline-1 focus-visible:outline-bluegreen-500 focus-visible:outline-dashed group"
            >
                <span className="underline group-hover:no-underline">{sortButtonLabel}</span>
                <span className="text-lg no-underline material-icons">
                    {isAlphabetical ? 'close' : 'swap_vert'}
                </span>
            </button>

            {sortedPages.map((page) => (
                <div key={page.url} className="bg-white-400 p-normal p-nochild">
                    <h2 className="mb-3">
                        <a
                            href={page.url}
                            className="text-xl font-semibold text-bluegreen-600 lg:text-2xl icon icon--arrow__right hover:underline decoration-1 underline-offset-4"
                        >
                            {page.title}
                        </a>
                    </h2>
                    {page.preamble && (
                        <div
                            className="text-base text-black-400"
                            dangerouslySetInnerHTML={{ __html: page.preamble }}
                        />
                    )}
                </div>
            ))}
        </div>
    );
};
