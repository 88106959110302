import PropTypes from 'prop-types';
import React from 'react';

export default function ProspectusRegistrySearchResultItem(props) {
    return (
        <article>
            <div className="flex flex-row justify-between text-sm">
                <span className='text-yellow-600'>
                    {props.type}
                </span>
                <span className="text-black-400">{props.metadata}</span>
            </div>
            <div className="w-full lg:w-9/12">
                <h2 className=" text-xl font-semibold mb-2">
                    <a href={props.url} className=" text-bluegreen-500 hover:underline hover:decoration-1 hover:underline-offset-4 icon icon--arrow__right">
                        {props.name}
                    </a>
                </h2>
            </div>
        </article>
    );
}

ProspectusRegistrySearchResultItem.propTypes = {
    name: PropTypes.string.isRequired,
    published: PropTypes.string,
    url: PropTypes.string.isRequired,
    metadata: PropTypes.string,
    type: PropTypes.string,
};
