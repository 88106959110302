/*
  This helper is used to map searchState to a query string of URL params,
  which is used for API URLs, or to replace the window history state.

  Currently, searchStates and corresponding API parameters are not necessarily
  defined consistently across the app.
  E.g., some filters default to -1 when not selected, others default to "".
  Most typefilters use numbers as values, but the prospectus registry uses strings.

  Thus, for the time being this method is more complex than it needs to be,
  and should be revisited when we introduce typing.
*/
import { window } from "./ssrBase";

export function mapToUrlParams(mapper) {
    let parameters = [];
    const addIfNotNullWithOptionalCondition = (key, value, condition = () => true) => {
        if (value && condition(value)) {
            parameters = [...parameters, `${key}=${encodeURIComponent(value)}`];
        }
    };

    mapper(addIfNotNullWithOptionalCondition);

    if (parameters.length > 0) {
        return `?${parameters.join('&')}`;
    }

    return '';
}

export function replaceUrlParams(urlParams) {
    window.history.pushState({}, '', window.location.pathname + urlParams);
}

export function removeUrlParams() {
    window.history.pushState({}, '', window.location.pathname);
}
