import React, { useContext } from 'react';
import { AnyAction } from 'redux';
import { useAppDispatch } from "../../redux/hooks";
import { translateLang } from "../../translate";
import { LanguageContext } from '../LanguageContext';
import { SupervisionCategory, SupervisionCategoryViewModel } from '../../generatedTypes/FT.Web.ViewModels';

interface SelectedSubjectsProps {
    checkedSupervisionCategories: number[],
    checkSupervisionCategory(id: number, name: string): AnyAction,
    supervisionCategories: SupervisionCategoryViewModel
}

export const SelectedSubjects: React.FC<SelectedSubjectsProps> = (props): JSX.Element | null => {
    const dispatch = useAppDispatch();
    const languageContext = useContext(LanguageContext);
    const selectedSubjectsToShow = 3;

    let allCategories: SupervisionCategory[] = [];
    props.supervisionCategories?.supervisionCategories?.map((o) => allCategories.push(...o.supervisionCategories));
    allCategories = [...allCategories];
    // some subjects have no name - either obsolete or different language
    const unnamedSubjects = props.checkedSupervisionCategories.filter(o => allCategories.find(f => f.id === o) === undefined).length;

    if(props.checkedSupervisionCategories.length - unnamedSubjects === 0)
        return null;

    return (           
        <section className="border-t border-black-200 mb-4 mx-4 pt-2">
            <p className="text-sm mb-1">{translateLang('subjectSelector.selectedSubjects', languageContext.code)}</p>
            <ul className="-mx-1 flex flex-wrap">
                {props.checkedSupervisionCategories.slice(0, selectedSubjectsToShow).map(o => {
                    const categoryName = allCategories.find(f => f.id === o)?.name;
                    if (categoryName)
                        return (
                            <li className='p-1' key={o}>
                                <button
                                    type="button"
                                    className="text-sm bg-bluegreen-100 p-1 icon icon--close after:text-bluegreen-500 after:content-['close'] after:ml-0 hover:text-bluegreen-500 hover:bg-bluegreen-100"
                                    onClick={() => { dispatch(props.checkSupervisionCategory(o, categoryName))}}
                                >
                                    {categoryName}
                                </button>
                            </li>);

                    return null;
                }
                )}
                
                {(props.checkedSupervisionCategories.length - unnamedSubjects) > selectedSubjectsToShow &&                
                    (<li className='px-1 py-2 text-sm'>
                        &#43;{ (props.checkedSupervisionCategories.length - unnamedSubjects)  - selectedSubjectsToShow }
                    </li>)}
            </ul>
        </section>);
}
