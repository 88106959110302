import React, { ReactNode } from 'react';
import { TableCell } from '../../generatedTypes/FT.EpiServer.Models.Table';
import { b64, isNumeric, numberFormat } from '../../util/utilityFunctions';

interface Props {
    array?: string[] | null,
    arrayFixed?: Array<{ name: string, x: string[], y: TableCell[] }> | null,
    tableIsFixed: boolean,
    sorting?: {
        index: number,
        setIndex: (index: number) => void,
        reverse: boolean
    } | null,
    sortable: boolean,
    rightAlignContent: boolean
}

export class Thead extends React.Component<Props, unknown> {

    handleSortingOnClick = (index: number): void | null => {
        if (this.props.sorting && this.props.sorting.setIndex)
            return this.props.sorting?.setIndex(index);
        return null;
    }

    getItemValue = (item: string): string => (
        isNumeric(parseFloat(item)) ? numberFormat({ num: item, format: 'nb' }) : item
    )

    getItemValueFixed = (item: TableCell): ReactNode => {
        if (item && item.value) {
            if (item.url && item.url !== "") {
                return <a href={item.url}>{item.value}</a>
            }
            if (isNumeric(parseFloat(item.value))) {
                return numberFormat({ num: item.value, format: 'nb' })
            }
            return item.value;
        }
        return null;
    }

    renderButton = (item: string, index: number): ReactNode => (
        <button
            className="c_table__heading-container c_table__heading-container--button c_table__heading-container--button--sortable"
            onClick={() => this.handleSortingOnClick(index)}
            type="button"
        >
            {this.props.sorting && (
                <div
                    style={{
                        width: '.8em',
                        height: '1em',
                        minWidth: '.8em',
                        margin: '0 5px',
                        display: 'inline-block'
                    }}
                >
                    {this.props.sorting.index === index && this.props.sorting.reverse &&
                        <img src='/Content/Images/Icons/icon_arrow_up_sort_table.svg' alt='sort table' className='c_sort-caret-icon' />
                    }
                    {this.props.sorting.index === index && !this.props.sorting.reverse &&
                        <img src='/Content/Images/Icons/icon_arrow_down_sort_table.svg' alt='sort table' className='c_sort-caret-icon' />
                    }
                </div>
            )}
            <span>{item}</span>
        </button>
    )


    render(): JSX.Element {
        return (
            <thead className="c_table-head">
                {!this.props.tableIsFixed && (
                    <tr>
                        {this.props.array && this.props.array.map((item, index) => (
                            <th
                                key={b64.encode(`th-key ${item}_${index}`)}
                                className={`${this.props.rightAlignContent ? 'c_table--rightAlign' : ''}`}
                                scope={index === 0 ? '' : 'col'}
                            >
                                {this.props.sortable && this.renderButton(item, index)}
                                {!this.props.sortable && this.getItemValue(item)}
                            </th>
                        ))}
                    </tr>
                )}
                {this.props.tableIsFixed && this.props.arrayFixed && (
                    <tr>
                        <th
                            scope="col"                 
                            className='sticky left-0 w-[200px]'>
                            {this.props.arrayFixed[0].x}
                        </th>
                        {this.props.arrayFixed[0].y && this.props.arrayFixed[0].y.map((item, index) => (
                            <th
                                key={b64.encode(`th-key--tableIsFixed ${item}_${index}`)}
                                scope="col"
                                className='w-[200px]'
                            >
                                {this.getItemValueFixed(item)}
                            </th>
                        ))}
                    </tr>
                )}
            </thead>
        );
    }
}
