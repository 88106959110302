import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translateLang } from '../translate';

export default class SelectList extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.show) {
            return (
                <div className="px-4 mb-6">
                    <ul className="flex flex-col -m-3">
                        <li className="radiobox--custom m-3">
                            <input
                                type="radio"
                                name="ftSelect"
                                value="-1"
                                id="ftSelectDefault"
                                onChange={(e) =>
                                    this.props.onFilterClick(
                                        Number(e.target.value),
                                        'n/a'
                                    )
                                }
                                defaultChecked
                            />
                            <label htmlFor="ftSelectDefault">
                                {this.props.defaultOptionText}
                            </label>
                        </li>

                        {this.props.filters.map((filter) => (
                            <li className="radiobox--custom m-3" key={filter.id}>
                                <input
                                    type="radio"
                                    name="ftSelect"
                                    value={filter.id}
                                    id={`selectlist_${filter.id}`}
                                    title={filter}
                                    onChange={(e) =>
                                        this.props.onFilterClick(
                                            Number(e.target.value),
                                            'n/a'
                                        )
                                    }
                                />
                                <label htmlFor={`selectlist_${filter.id}`}>
                                    {filter.name}
                                </label>
                            </li>
                        ))
                        }
                    </ul>
                </div>
            );
        }
        return null;
    }
}

SelectList.propTypes = {
    onFilterClick: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
    show: PropTypes.bool.isRequired,
    defaultOptionText: PropTypes.string.isRequired,
    activeFilter: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    language: PropTypes.string
};
