import React, { FC } from 'react';
import { ExpandBox } from '../../../components/expandBox/ExpandBox';
import { VirkeregLicenceType } from '../../../generatedTypes/FT.Web.Controllers.Api.Models.Virkereg';
import { GroupedCheckboxList } from './GroupedCheckboxList';
import { vregUserReportState } from '../../Services/VregUserReportState';

interface ServicesFilterProps {
    selectedLicenceType: VirkeregLicenceType
}

export const ServicesFilter: FC<ServicesFilterProps> = (props) =>

    <ExpandBox
        key={props.selectedLicenceType.code}
        titleString={props.selectedLicenceType.name}
        ariaLabelKey=""
        expanded 
    >
        {props.selectedLicenceType.serviceGroups.map(sgroup =>

            <GroupedCheckboxList
                onClickAction={vregUserReportState.actions.toggleService}
                onSelectAllAction={vregUserReportState.actions.toggleServiceGroup}
                serviceGroup={sgroup}
                key={`serviceGroup_${sgroup.id}`}
            />

        )}

        {props.selectedLicenceType.classifications[0].services.length > 0 && props.selectedLicenceType.classifications.map(classification =>
            <div key={`classification_${classification.id}`}>
                <GroupedCheckboxList
                onClickAction={vregUserReportState.actions.toggleService}
                onSelectAllAction={vregUserReportState.actions.toggleServiceGroup}
                serviceGroup={classification}                
                />
            </div>
        )}



    </ExpandBox>     
