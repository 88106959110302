import { isServer, window } from "./ssrBase";

export const scrollToPrevPosition = (page) => {
    const scrollPosition = window.sessionStorage?.getItem(page);

    if(!scrollPosition)
        return;

    const onRefreshBackOrForward =
        performance.navigation &&
        (performance.navigation.type === 1 || performance.navigation.type === 2);

    // scrollPosition is "undefined" in IE, but IE has scroll persist by default anyway.
    if (scrollPosition !== 'undefined') {
        if (onRefreshBackOrForward) {
            // Need delay because search results must load before scroll
            setTimeout(() => {
                window.scrollTo(0, scrollPosition);
            }, 500);
        } else {
            clearSessionStorage();
        }
    }
};

export const setScrollPosition = (page) => {
    window.onunload = () => {
        window.sessionStorage?.setItem(page, window.scrollY);
    };
};

export const getSessionStorageResult = (page) => { 
    if(isServer)
        return null;
    
    return JSON.parse(window.sessionStorage.getItem(page));
};

export const setSessionStorageResult = (page, result) =>
    window.sessionStorage.setItem(page, JSON.stringify(result));

export const clearSessionStorage = () => window.sessionStorage.clear();
