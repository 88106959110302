import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import NewsArchiveSearchBox from './searchBox';

const mapStateToProps = (state) => ({
    typeFilters: state.typeFilters
});

const header = (props) => {
    const header = props.typeFilters?.loaded && props.typeFilters.activeFilters.length === 1 ?
        props.typeFilters.items.find(f => f.id === props.typeFilters.activeFilters[0]).name
        : props.pageName;

    return (
        <div className="flex justify-end mb-6">
            <div className="w-full lg:w-8/12 lg:pl-5">
                <h1 className="text-2xl lg:text-3xl font-normal leading-h1">{`${header} ${props.headerSuffix}`}</h1>
                <p className=" text-sm mb-6">{props.pageName}</p>
                <div className="flex w-full ">
                    <NewsArchiveSearchBox />
                </div>
            </div>
        </div>);
}

header.propTypes = {
    pageName: PropTypes.string.isRequired,
    typeFilters: PropTypes.object,
    headerSuffix: PropTypes.string
}

export const NewsArchiveHeader = connect(
    mapStateToProps
)(header);