import { en } from './locales/en';
import { no } from './locales/no';
import flattenObject from './util/flattenObject';
import { window } from './util/ssrBase';

const noTranslations = flattenObject(no);
const enTranslations = flattenObject(en);

function pluralizeKey(key, count) {
    if (count === 0) return `${key}.zero`;
    if (count === 1) return `${key}.one`;
    return `${key}.other`;
}

function translateLang(key, lang, subsitutions = {}) {
    const myTranslations = lang === 'en' ? enTranslations : noTranslations;
    const pluralizedKey = !isNaN(subsitutions.count) ? pluralizeKey(key, subsitutions.count) : key;

    let result = myTranslations[pluralizedKey];

    if (!result) {
        return `Mangler tekst for ${pluralizedKey} (${lang})`;
    }

    Object.entries(subsitutions).forEach((substitution) => {
        result = result.replace(`%(${substitution[0]})s`, substitution[1]);
    });

    return result;
}

/**
 * @deprecated - use `translateLang()` instead
*/
function translate(key, subsitutions = {}) {
    return translateLang(key, getLocale(), subsitutions);
}

const getLocale = () => window.location.pathname === '/en' || window.location.pathname.startsWith('/en/') ? 'en' : 'no';

export { getLocale, translate, translateLang };
