import React, { FC, useState } from "react";

interface Props {
    id: string,
    rangepart: string
}

export const DecimalInput: FC<Props> = (props): JSX.Element | null => {
    const [value, setValue] = useState<string|undefined>("");
   
    return (
        <input
            id={props.id}
            type="text"
            className="number w-full lg:w-1/2" 
            data-rangepart={props.rangepart}
            pattern="[0-9.,]+"
            step="0.01"
            name={props.id}
            lang="nb-NO"
            placeholder="0,0"
            onChange={(event) => {                                
                setValue(event.currentTarget.value.replace('.', ',').replace(/[^0-9,]/g,''));
            }}
            value={value}
        />);
}