import React, { useState, useEffect, useRef, RefObject } from 'react';
import { CollapsiblePanelProps } from '../../generatedTypes/FT.Web.ViewModels.ReactProps/collapsiblePanelProps'
import { LinkProp } from '../../generatedTypes/FT.Web.ViewModels.ReactProps/linkProp';
import { isMobile, window } from '../../util/ssrBase';

export const CollapsiblePanel: React.FC<CollapsiblePanelProps> = (props): JSX.Element | null => {
    const isActiveAnchor = () => 
        window.location.hash.replace('#', '') === props.contentId.toString();  
        
    const isOpenByDefault = () => 
        props.openByDefault === true;

    // default open only if anchor in url
    const [open, setOpen] = useState( isOpenByDefault() || isActiveAnchor());
    const myRef = useRef<HTMLDivElement | null>(null);
    const executeScroll = (ref: null | RefObject<HTMLDivElement | null>) => {
        if (ref && ref.current) {
            ref.current.scrollIntoView({ block: 'start' });
        }
    };

    useEffect(() => {
        if (isActiveAnchor()) {
            executeScroll(myRef);
        }
    });

    return (
        <div
            className="bg-white-400 p-6 mb-6 border-l-4 border-bluegreen-400"
            id={props.contentId.toString(10)}
            ref={myRef} >
            <button
                type='button'
                className="flex justify-between items-center w-full text-black-400 hover:text-bluegreen-500 group p-3 -m-3 text-md"
                aria-label={props.title}
                onClick={() => setOpen(!open)}>
                <span className="group-hover:underline text-bluegreen-600 font-semibold">
                    {props.title}
                </span>
                <span aria-hidden="true" className={`${open === true ? "rotate-180" : "rotate-0"} material-icons transition-all text-lg`}  >
                    expand_more
                </span>
            </button>

            {open &&    
                (
                    <div className='mb-6' dangerouslySetInnerHTML={{ __html: props.descriptionText }} />
                )}

            <div className='flex flex-col-reverse lg:flex-row lg:justify-between'>
                {open && props.relatedLinks &&
                    (
                        <ul className="space-y-3 article--bodytext__list-none">
                            {props.relatedLinks.map((link: LinkProp) => (
                                <li key={link.text}>
                                    <a href={link.href} aria-label={link.title || link.text}
                                        className={`icon icon--chevron__right underline hover:no-underline decoration-1 underline-offset-4 text-bluegreen-600 ${link.attributes.show === "False" && props.useFilter ? 'hidden' : ''}`}>
                                        {link.text}                                        
                                    </a>
                                </li>
                            ))}
                        </ul>)
                }
                {open && props.coverImage && props.coverImage.url && !isMobile &&
                    (<picture className="relative w-full lg:w-2/12 text-center lg:text-right mb-6 lg:mb-0 group">
                        {props.relatedLinks &&
                            (
                                <a href={`${props.relatedLinks[0].href}`} className="absolute inset-0">
                                    <span className="sr-only">{props.relatedLinks[0].text}</span>
                                </a>
                            )}

                        <source type="image/webp"
                            srcSet={`${props.coverImage.url}?width=300&height=400&rmode=crop&format=webp`} />

                        <img src={`${props.coverImage.url}?width=300&height=400rmode=crop`}
                            alt={props.coverImage.altText}
                            title={props.coverImage.credit}
                            width="300"
                            height="400"
                        className="w-full" />
                    </picture>)
                }
            </div>
        </div>
    );
}
