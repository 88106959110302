import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translateLang } from '../translate';

export default class FTYearPicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dateList: this.getYearList()
        };
    }

    getYearList = () => {        
        const selectableYears = [];
        const currentYear = new Date().getFullYear();
        const limitYear = currentYear - this.props.yearsBack
        for (let i = currentYear; i >= limitYear; i--) {
            selectableYears.push( i );            
        }

        return selectableYears;
    };

    

    render() {
        return (
            <div className="mt-2 mb-2">
                <ul className="flex flex-wrap -m-3">
                    <li className="radiobox--custom m-3">
                        <input
                            type="checkbox"
                            name="ftYear"
                            value="-1"
                            id="ftYearDefault"
                            checked={this.props.years?.length === 0 || this.props.years === undefined}
                            onChange={(e) => this.props.onDateSet(e.target.value)}
                        />
                        <label htmlFor="ftYearDefault">
                            {translateLang('newsarchive.buttons.chooseYear', this.props.language)}
                        </label>
                    </li>

                    {this.state.dateList.map((year) => (
                        <li className="radiobox--custom m-3" key={year}>
                            <input
                                type="checkbox"
                                name="ftYear"
                                value={year}
                                id={`yearRadio_${year}`}
                                checked={this.props.years !== undefined && this.props.years?.indexOf(parseInt(year,10)) !== -1}
                                onChange={(e) => this.props.onDateSet(e.target.value)}
                            />
                            <label htmlFor={`yearRadio_${year}`} className='mx-1'>
                                {year.toString()}
                            </label>
                        </li>
                    ))
                    }
                </ul>
            </div>
        );
    }
}

FTYearPicker.propTypes = {
    years: PropTypes.arrayOf(PropTypes.number),
    onDateSet: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    yearsBack: PropTypes.number.isRequired
};
