import React from 'react';
import { PageTypeViewModel } from '../../generatedTypes/FT.Web.ViewModels';

interface Props {
    pageType: PageTypeViewModel;
    url: string;
    metadata: string;
    name: string;
    preamble: string;
    published: string;
}

export const SearchResultItem: React.FC<Props> = ({
    pageType,
    url,
    metadata,
    name,
    preamble,
    published,
}) => {
    const pageName = pageType?.name ?? '';

    return (

        <article>
            <div className="flex flex-col lg:flex-row lg:justify-between space-y-3 lg:space-y-0 text-sm">
                <span className='text-yellow-600'>
                    {pageName} {metadata}
                </span>
                <span className="text-black-400">{published}</span>
            </div>
            <div className="w-full lg:w-9/12">
                <h2 className=" text-xl font-semibold mb-2">
                    <a href={url} className=" text-bluegreen-500 hover:underline hover:decoration-1 hover:underline-offset-4 icon icon--arrow__right">
                        {name}
                    </a>
                </h2>
                <p className="search-result-item-preamble search-result-item-markup" dangerouslySetInnerHTML={{__html: preamble}} />
            </div>
        </article>

    );
};
