import React from 'react';
import { TableBlockViewModel } from '../../generatedTypes/FT.Web.ViewModels.Blocks';
import { FixedColumnTableBlock } from './fixedTableBlock';
import { TableBlock } from './tableBlock';

export const TableComponent: React.FC<TableBlockViewModel> = (props) =>
{
    if(props.fixedColumnLeft === true)
    return (<FixedColumnTableBlock blockModel={props} />)

    return (<TableBlock blockModel={props} />)
}

