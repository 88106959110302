import React from 'react';
import { TableColumnWithUrl } from '../../generatedTypes/FT.EpiServer.Models.Table';
import { TableBlockViewModel } from '../../generatedTypes/FT.Web.ViewModels.Blocks';
import { deepClone } from '../../util/utilityFunctions';
import { FixedLeftColumnDataMapper } from './tableUtils';
import { Tbody } from './tbody';
import { Thead } from './thead';

interface State { 
    blockModel: TableBlockViewModel | null,
    sorting: {
          index: number,
          reverse: boolean
      }
  }
  
  interface Props {    
      blockModel: TableBlockViewModel | null
  }
  
  export class FixedColumnTableBlock extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);		
		this.state = {
            blockModel: props.blockModel,
			sorting: {
				index: 0,
				reverse: false
			}
		};
	}

    sortByIndex = (selectedIndex: number) : void  => {
        if (this.state.sorting.index !== selectedIndex) {
            this.setState({
                sorting: {
                    index: selectedIndex,
                    reverse: false
                }
            });
        } else {
            this.setState(previousState => ( {sorting : { index: previousState.sorting.index, reverse: !previousState.sorting.reverse}}));
        }
    }

    createRowHeading = (columns: TableColumnWithUrl[]) : string[] => {
        const headingsRow: string[] = [];
        for (let i = 0; i < columns.length; i+=1) {
            headingsRow.push(columns[i].heading);
        }
        return headingsRow;
    }


	render() : JSX.Element {
        if (this.state.blockModel) {
            const columns = deepClone(this.state.blockModel.tableData.columns);
            const fixedData = FixedLeftColumnDataMapper({ array: columns, addSumRow: this.state.blockModel.addSumRow, sumHeading: this.state.blockModel.sumHeading });

            return (
                <div
                    className={`                        
                        ${this.state.blockModel.fullWidth ? '' : ''}
                    `}
                >
                    <section aria-label={`table_+ ${this.state.blockModel.tableData.tableHeading}`}>
                        <h3 className={`${this.state.blockModel.tableData && this.state.blockModel.tableData.hideTableHeading ? 'hidden' : ''}`}
                        >
                            {this.state.blockModel.tableData.tableHeading}
                        </h3>
                        <table
                            className={`
                                table-fixed w-full
                                ${this.state.blockModel.lastRowBold ? 'griddy-table--sum' : ''}
                                ${this.state.blockModel.fadeDiagonal ? 'fadeDiagonal' : ''}
                                ${this.state.blockModel.fatColumns ? 'griddy-table--large-cells' : ''}
                            `}
                        >
                            <caption
                                className="sr-only"
                                id={this.state.blockModel.tableData.tableHeading}
                            >
                                {this.state.blockModel.tableData.tableHeading}
                            </caption>
                            <Thead
                                arrayFixed={fixedData}
                                tableIsFixed
                                sorting={this.state.blockModel.sortable ? {
                                    index: this.state.sorting.index,
                                    setIndex: index => this.sortByIndex(index),
                                    reverse: this.state.sorting.reverse
                                } : null}
                                sortable={this.state.blockModel.sortable}
                                rightAlignContent={this.state.blockModel.rightAlignContent} 
                            />
                            <Tbody
                                fixedArray={fixedData}
                                tableIsFixed							
                                sorting={this.state.blockModel.sortable ? {
                                    index: this.state.sorting.index,
                                    setIndex: index => this.sortByIndex(index),
                                    reverse: this.state.sorting.reverse
                                } : null}
                                noFirstCol={this.state.blockModel.noFirstCol}
                                rightAlignContent={this.state.blockModel.rightAlignContent}                            
                                bodyRef=''                              
                            />
                        </table>

                        {this.state.blockModel.reference &&
                            <div>
                                Kilde: {this.state.blockModel.reference}
                            </div>
                        }
                        {this.state.blockModel.explanation &&
                            <div >
                                {this.state.blockModel.explanation}
                            </div>
                        }
                    </section>
                </div>
            );
        }
        return (<div>Loading...</div>)
	}
}