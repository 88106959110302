
import 'whatwg-fetch';
import { store } from '../../redux/supervisionStore';
import { isServer } from '../../util/ssrBase';

export function bootstrap(saveCallback:(x:number[]) => void):void {
    let currentState = store.getState();
    store.subscribe(() => {
        if (
            currentState.checkedSupervisionCategories !==
            store.getState().checkedSupervisionCategories
        ) {
            saveCallback(store.getState().checkedSupervisionCategories);

            if (!isServer) {
                localStorage.setItem(
                    'supervisionCategories',
                    JSON.stringify(store.getState().checkedSupervisionCategories)
                );
            }
        }
        currentState = store.getState();
    });
}

export function getSavedSupervisionCategories():[] {
    if (!isServer) {
        const storedCategories = localStorage.getItem('supervisionCategories');
        const savedItems = storedCategories !== null ? JSON.parse(storedCategories) : null;
        return (savedItems || []);
    }

    return [];
}
