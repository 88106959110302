import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { VirkeregReportJsonResponse } from '../../generatedTypes/FT.Web.Controllers.Api.Models.Virkereg.Reports';
import { VirkeregLicenceType } from '../../generatedTypes/FT.Web.Controllers.Api.Models.Virkereg';
import { CheckedChanged, MultiCheckedChanged } from './CheckedChanged';
import { window } from '../../util/ssrBase';
import { mapToUrlParams, replaceUrlParams } from '../../util/urlHelpers';

const queryParams = new URLSearchParams(window.location.search);

function getPassporting() {
    const passporting = queryParams.get('passporting') || 'Domestic';
    if (passporting === 'Domestic')
        return 'Domestic';

    if (passporting === 'Outbound')
        return 'Outbound';

    return 'Inbound';
}

function getUrlParams(state: VregUserReportState) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return mapToUrlParams((addIfNotNull: any) => {
        state.selectedLicenceTypeCodes.map((filter) => addIfNotNull('licenceTypes', filter));
        addIfNotNull('passporting', state.passporting);
        addIfNotNull('p', state.pageNumber);
        addIfNotNull('group', queryParams.get('group'));
        addIfNotNull('countryCode', state.countryCode);
        addIfNotNull('countyNumber', state.countyNumber)
        state.services.map((filter) => addIfNotNull('serviceIds', filter));


    });
}

function setQuerystring(state: string) {
    replaceUrlParams(state)
}

interface VregUserReportState {
    pageNumber: number
    totalPages: number
    pageSize: number
    reportResult: null | VirkeregReportJsonResponse
    passporting: 'Domestic' | 'Inbound' | 'Outbound',
    countryCode: string | null,
    includedProviderTypes: string[],
    countyNumber: number | null,
    services: string[],
    licenceGroupLicenceTypes: VirkeregLicenceType[],
    selectedLicenceTypeCodes: string[]
}

const initialState: VregUserReportState = {
    pageNumber: 1,
    totalPages: 10,
    pageSize: 10,
    reportResult: null,
    passporting: getPassporting(),
    countryCode: queryParams.get('countryCode') || null,
    includedProviderTypes: [],
    countyNumber: parseInt(queryParams.get('countyNumber') || '', 10) || null,
    services: queryParams.getAll('serviceIds') || [],
    licenceGroupLicenceTypes: [],
    selectedLicenceTypeCodes: queryParams.getAll('licenceTypes') || []
};

export const vregUserReportState = createSlice({
    name: 'vregUserReportState',
    initialState,
    reducers: {
        setTotalPages: (state, action: PayloadAction<number>) => {
            state.totalPages = action.payload;
        },
        goToFirstPage: (state) => {
            state.pageNumber = 1;
            setQuerystring(getUrlParams(state))
        },
        incrementPage: (state) => {
            state.pageNumber += 1;
            setQuerystring(getUrlParams(state))
        },
        decrementPage: (state) => {
            state.pageNumber -= 1;
            setQuerystring(getUrlParams(state))
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.pageNumber = action.payload;
            setQuerystring(getUrlParams(state))
        },
        setReportResult: (state, action: PayloadAction<VirkeregReportJsonResponse>) => {
            state.reportResult = action.payload
            state.totalPages = action.payload.totalPages
            setQuerystring(getUrlParams(state))
        },
        toggleLicenceType: (state, action: PayloadAction<string>) => {
            const existingLicence = state.selectedLicenceTypeCodes.findIndex(f => f === action.payload);
            if (existingLicence > -1) {
                state.selectedLicenceTypeCodes.splice(existingLicence, 1)
            }
            else {
                state.selectedLicenceTypeCodes.push(action.payload);
            }
            setQuerystring(getUrlParams(state))
        },
        setPassporting: (state, action: PayloadAction<'Domestic' | 'Inbound' | 'Outbound'>) => {
            state.passporting = action.payload
            setQuerystring(getUrlParams(state))
        },
        setCountry: (state, action: PayloadAction<string | null>) => {
            state.countryCode = action.payload
            setQuerystring(getUrlParams(state))
        },
        setIncludedProviderTypes: (state, action: PayloadAction<string>) => {
            const itemIndex = state.includedProviderTypes.indexOf(action.payload)
            if (itemIndex > -1)
                state.includedProviderTypes.splice(itemIndex, 1)
            else
                state.includedProviderTypes.push(action.payload)
        },
        setCountyNumber: (state, action: PayloadAction<number>) => {
            state.countyNumber = action.payload
            setQuerystring(getUrlParams(state))
        },
        toggleService: (state, action: PayloadAction<CheckedChanged>) => {
            const existingIndex = state.services.findIndex(a => a === action.payload.id);
            if (existingIndex > -1) {
                state.services.splice(existingIndex, 1);
            }
            else {
                state.services.push(action.payload.id);
            }
            setQuerystring(getUrlParams(state))
        },
        toggleServiceGroup: (state, action: PayloadAction<MultiCheckedChanged>) => {
            if (action.payload.checked) {
                action.payload.ids.map((type) => {
                    const existingIndex = state.services.indexOf(type.id);
                    if (existingIndex === -1) state.services.push(type.id);
                    return type;
                });
            } else {
                const unwantedIds = action.payload.ids.map(a => a.id);
                state.services = state.services.filter(f => unwantedIds.indexOf(f) === -1);
            }
            setQuerystring(getUrlParams(state))
        },
        setAvailableLicenceGroupTypes: (state, action: PayloadAction<VirkeregLicenceType[] | undefined>) => {
            if (action.payload !== undefined)
                state.licenceGroupLicenceTypes = action.payload;
        }
    },
});

export const vregUserReportStateReducer = vregUserReportState.reducer;
export const vregUserReportStateActions = vregUserReportState.actions;