import { trackEvent } from '../eventTracking';
import { clearSessionStorage, getSessionStorageResult } from '../util/persistScroll';
import { mapToUrlParams, replaceUrlParams } from '../util/urlHelpers';

export const SEARCH_LOADED = 'SEARCH_LOADED';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const LOADED_MORE = 'LOADED_MORE';

export function searchLoaded(searchResult) {
    return {
        type: SEARCH_LOADED,
        searchResult,
    };
}

export function setSearchQuery(queryString, isChanged) {
    // Clear sessionStorage hvis query er endret for å få nye resultater
    if (isChanged) {
        clearSessionStorage();
    }
    trackEvent('Søk', queryString);
    return {
        type: SET_SEARCH_QUERY,
        queryString,
    };
}

function getUrlParams(searchState, page) {
    const { queryString, lang } = searchState;
    return mapToUrlParams((addIfNotNull) => {
        addIfNotNull('q', queryString);
        addIfNotNull('l', lang);
        addIfNotNull('p', page);
    });
}

function fetchSearch(searchState) {
    const searchApi = '/api/search/pillar2';
    const q = getUrlParams(searchState, searchState.page);
    replaceUrlParams(q);

    const pillar2Result = getSessionStorageResult('pillar2Result');

    if (pillar2Result) {
        return (dispatch) => {
            dispatch(searchLoaded(pillar2Result));
        };
    }

    return (dispatch) =>
        fetch(searchApi + q).then((response) =>
            response.json().then((result) => dispatch(searchLoaded(result)))
        );
}

export function initSearch(searchState) {
    return (dispatch) => dispatch(fetchSearch(searchState));
}

export function loadedMore(searchResult) {
    trackEvent('Last flere', `Side ${searchResult.page}`);
    return {
        type: LOADED_MORE,
        searchResult,
    };
}

export function loadMore(searchState, currentPage) {
    const searchApi = '/api/search/pillar2';
    const q = getUrlParams(searchState, currentPage);

    return (dispatch) =>
        fetch(searchApi + q).then((response) =>
            response.json().then((result) => dispatch(loadedMore(result)))
        );
}
