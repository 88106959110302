import React, { FC, useState, useContext, useEffect, ChangeEvent } from 'react';
import { useDebounce } from 'use-debounce';
import { trackEvent } from '../eventTracking';
import { translateLang } from '../translate';
import { LanguageContext } from '../components/LanguageContext';
import { submitFeedback } from '../components/feedback/feedbackApi';


export const Popup: FC = () => {
    const emailInputId = 'brukerkontakInput';
    const hasClosedPopupKey = 'hasClosedUserSurveyPopup';
    const [isOpen, setIsOpen] = useState(false);
    const [mainOpen, setMainOpen] = useState(false);
    const [done, setDone] = useState(false);
    const languageContext = useContext(LanguageContext);
    const [eMail, setEmail] = useState('');
    const [eMailValid, setEmailValid] = useState(true);
    const [debouncedEmailValid] = useDebounce(eMailValid, 600);

    const open = () => {
        setIsOpen(true);
    }

    const close = (fromCloseButton: boolean) => {
        setIsOpen(false);
        localStorage.setItem(hasClosedPopupKey, 'true');
        trackEvent(fromCloseButton ? 'Lukk popup' : 'Påmeldt brukerundersøkelse', '');
    }

    const signupForSurvey = (email: string) => {
        // send e-mail to feedback
        if (email.length > 4) {
            submitFeedback(`Brukerundersøkelse: ${email}`, languageContext.code);
            setMainOpen(false);
            setDone(true);
            setTimeout(() => close(false), 7000);
        }
    };

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.validity.valid === true) {
            setEmailValid(true);
            setEmail(e.currentTarget.value);
        }
        else {
            setEmailValid(false);
            setEmail('');
        }
    }

    useEffect(() => {
        if (localStorage.getItem(hasClosedPopupKey) === null)
            setTimeout(() => open(), 5000);
    }, []);

    if (languageContext.code === 'en')
        return null; // this popup is for Norwegian site only

    return (
        <div className='fixed bottom-10 right-0 w-full z-1000'> {/* Popup remains fixed bottom right */}
            <div className='container flex justify-end px-0 md:px-6'>
                <div className={`${isOpen ? 'max-w-[575px] w-[575px] justify-end rounded-3xl p-3 bg-gradient-to-r from-bluegreen-600 to-bluegreen-300 mb-6' : 'hidden'}`}>
                    <div className='bg-black-100 p-3 rounded-[calc(1.25rem-2px)]'>
                        <div className='relative mx-4'>

                            {done === false &&
                                <>
                                    <div className='absolute right-0 top-0 text-right'>
                                        <button
                                            className=" icon icon--close"
                                            onClick={() => close(true)}
                                            type='button'
                                            aria-label='Lukk'
                                        />
                                    </div>

                                    <h3 className='text-lg font-semibold'>{translateLang('usersurvey.header')}</h3>

                                    <div className={`${mainOpen ? 'hidden' : 'text-center '}`}>
                                        <button type='button' className='text-bluegreen-500 font-semibold' onClick={() => setMainOpen(true)}>
                                            {translateLang('usersurvey.clickToReadMore')} <span className='material-icons'>expand_more</span>
                                        </button>
                                    </div>
                                </>
                            }

                            {mainOpen &&
                                <>
                                    <p className='mb-6 '>
                                        Finanstilsynet skal gjennomføre en brukerundersøkelse av virksomhetsregisteret i løpet av mars.
                                        <br />Undersøkelsen foretas via Teams og vil vare i inntil 30 minutter.
                                    </p>

                                    <p className='mb-6'>
                                        Alle brukerdata vil bli behandlet konfidensielt og slettes etter at brukerundersøkelsen er gjennomført.</p>

                                    <p className='mb-6'>Oppgi din e-postadresse, så blir du kontaktet av en av våre ansatte for å avtale tid.</p>

                                    <label htmlFor={emailInputId}
                                        className='font-semibold'>Din e-postadresse
                                        <br />
                                        <input
                                            type='email'
                                            id={emailInputId}
                                            placeholder='Din e-postadresse'
                                            className={`my-4 w-full lg:w-8/12 ${eMailValid ? '' : 'border-red-500'}`}
                                            onChange={(e) => handleOnChange(e)} />
                                    </label>

                                    <button type='button' className='text-bluegreen-500 font-semibold underline lg:px-4' onClick={() => signupForSurvey(eMail)}>
                                        Send inn &nbsp; <span className='material-icons'>send</span>
                                    </button>

                                    {debouncedEmailValid === false &&
                                        <p>E-postadressen er ikke gyldig!</p>
                                    }
                                </>
                            }

                            {done &&
                                <>
                                    <p>
                                        <h3 className='text-lg font-semibold'>Takk for at du vil stille opp!</h3>
                                        <br />
                                        Kommunikasjonsstaben i Finanstilsynet
                                    </p>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >



    );

}
