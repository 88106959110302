import { Component } from 'react';
import { connect } from 'react-redux';
import { initSearch } from './pillar2Actions';

class Pillar2SearchState extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { dispatch } = this.props;
        dispatch(initSearch(this.props));
        return null;
    }
}

function mapStateToProps(state) {
    return {
        queryString: state.searchBox.queryString,
        lang: state.settings.lang
    };
}

export default connect(mapStateToProps)(Pillar2SearchState);
