import React, { FC } from "react";
import { BorderCrossingActivityVm } from "../../generatedTypes/FT.Web.ViewModels.Vreg";
import { translateLang } from "../../translate";
import { RelationList } from "../RelationList";
import {GroupedBorderCrossingList} from "./GroupedBorderCrossingList";

interface Props {
    crossBorder: BorderCrossingActivityVm,
    languageCode: string,
    isBranch: boolean
}

export const CrossBorder: FC<Props> = (props) =>
{
 const activitiesHeader = ():string => {
    if(props.isBranch === false){       
        if(props.crossBorder.singleHostCountry)
                return translateLang('vreg.bordercrossingActivity.toCountryWithoutEstablishment', props.languageCode, [props.crossBorder.hostCountry]);
        return translateLang('vreg.bordercrossingActivity.toCountryWithoutEstablishment', props.languageCode, [translateLang('vreg.bordercrossingActivity.multipleCountries', props.languageCode)]);           
    }    
    
    return translateLang('vreg.bordercrossingActivity.viaThisBranch', props.languageCode, [props.crossBorder.hostCountry]);
}
    
 
 return (<>
        <h3 className=" text-xl font-semibold mb-2">
            {translateLang('vreg.bordercrossingActivity.title', props.languageCode)}
        </h3>
        <p className="mb-6">
            {translateLang('vreg.bordercrossingActivity.canOperateFrom', props.languageCode, [props.crossBorder.homeCountry])}
        </p>

        {/* Activities are the current unit's activities */}
        {props.crossBorder?.activities.length > 0 &&
            <GroupedBorderCrossingList
             Heading={activitiesHeader()}
             CrossBorderActivities={props.crossBorder.activities}
             ListCountries={!props.crossBorder.singleHostCountry}
            />  
        }

        {/* Branches are any activities performed through the current unit's branch establishments */}

        {props.crossBorder?.branches?.length > 0 &&
            <div className="mb-6">
                 <RelationList
                    heading={props.crossBorder.singleHostCountry ? 
                        translateLang('vreg.bordercrossingActivity.toCountryViaBranch', props.languageCode, [props.crossBorder.hostCountry])
                        : translateLang('vreg.bordercrossingActivity.toCountryViaBranch', props.languageCode, [translateLang('vreg.bordercrossingActivity.multipleCountries', props.languageCode)])
                    }
                    Relations={props.crossBorder.branches}
                    id="crossBorderViaAgents"
                    languageCode={props.languageCode}
                />               
            </div>
        }

        {props.crossBorder?.toOtherCountriesViaBranches?.length > 0 &&
            <>
            <RelationList
                    heading={props.crossBorder.singleHostCountry ? translateLang('vreg.bordercrossingActivity.toCountryWithoutEstablishment', props.languageCode, [props.crossBorder.hostCountry]) 
                    : translateLang('vreg.bordercrossingActivity.toCountryWithoutEstablishment', props.languageCode, [translateLang('vreg.bordercrossingActivity.multipleCountries', props.languageCode)])
                    }
                    Relations={props.crossBorder.toOtherCountriesViaBranches}
                    id="crossBorderViaAgents"
                    languageCode={props.languageCode}
                />               
            </>
        }

        {/* Agents are any activities performed through the current unit's agents  */}
        {props.crossBorder?.agents?.length > 0 &&
            <div className="mb-6">
                <RelationList
                    heading={props.crossBorder.singleHostCountry ? translateLang('vreg.bordercrossingActivity.toCountryViaAgent', props.languageCode, [props.crossBorder.hostCountry]) 
                    : translateLang('vreg.bordercrossingActivity.toCountryViaAgent', props.languageCode, [translateLang('vreg.bordercrossingActivity.multipleCountries', props.languageCode)]) }
                    Relations={props.crossBorder.agents}
                    id="crossBorderViaAgents"
                    languageCode={props.languageCode}
                />
            </div>
        }

        {props.crossBorder?.throughThisBranch?.length > 0 &&
            <GroupedBorderCrossingList
                Heading={translateLang('vreg.bordercrossingActivity.toCountriesViaThisBranchWithoutEstablishment', props.languageCode, [props.crossBorder.hostCountry])}
                CrossBorderActivities={props.crossBorder.throughThisBranch}
                ListCountries
            />            
        }

    </>);
}