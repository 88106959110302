import React, { FC } from "react";
import { VregDetailViewModel, VregServiceProviderType } from "../generatedTypes/FT.Web.ViewModels.Vreg";
import { translateLang } from "../translate";
import { Info } from "./Details/Info";

export const Header: FC<VregDetailViewModel> = (props: VregDetailViewModel) =>
    <div className="bg-white-400 p-6 mb-6">
        <h1 className="font-normal text-2xl lg:text-3xl uppercase leading-h1">
            {props.name}
        </h1>

        <ul className="flex flex-wrap lg:flex-nowrap flex-row mt-2 -mx-3 -mb-3">
            <li className="flex items-center space-x-1 px-3 py-1.5 lg:p-3">
                <span className="material-icons text-bluegreen-500 text-lg" aria-hidden="true">{props.entityType === 'Company' ? 'corporate_fare' : 'person_outline'}</span>
                <span className="text-yellow-600 uppercase text-base font-semibold">
                    {props.isBranch ?
                        translateLang('vreg.entityTypes.branch', props.languageCode)
                        :
                        translateLang(`vreg.entityTypes.${props.entityType.toLowerCase()}`, props.languageCode)
                    }
                </span>
            </li>

            {props.licences?.filter(f => f.serviceProviderType === VregServiceProviderType.Licensed).length > 1 && (
                <li className="flex items-center space-x-1 px-3 py-1.5 lg:p-3">
                    <span className="material-icons text-bluegreen-500 text-lg" aria-hidden="true">list_alt</span>
                    <span className="text-yellow-600 uppercase text-base font-semibold">
                        {props.licences.filter(f => f.serviceProviderType === VregServiceProviderType.Licensed).length} {translateLang('vreg.licences', props.languageCode)}
                    </span>
                </li>)}

            {props.licences?.length === 1 && (
                <li className="flex items-center space-x-1 px-3 py-1.5 lg:p-3">
                    <span className="material-icons text-bluegreen-500 text-lg" aria-hidden="true">list_alt</span>
                    <span className="text-yellow-600 uppercase text-base font-semibold">
                        {props.licences[0].licenceType}
                    </span>
                </li>)}

            {props.licences?.filter(f => f.serviceProviderType === VregServiceProviderType.Agent).length > 1 && (
            <li className="flex items-center space-x-1 px-3 py-1.5 lg:p-3">
                <span className="material-icons text-bluegreen-500 text-lg" aria-hidden="true">home_work</span>
                <span className="text-yellow-600 uppercase text-base font-semibold">
                    Agent for {props.licences?.filter(f => f.serviceProviderType === VregServiceProviderType.Agent).length}
                </span>
            </li>)}

            {props.country &&
                <li className="flex items-center space-x-1 px-3 py-1.5 lg:p-3">
                    <span className="material-icons text-bluegreen-500 text-lg" aria-hidden="true">public</span>
                    <span className="text-yellow-600 uppercase text-base font-semibold">
                        {props.country}
                    </span>
                </li>
            }

            {props.isNorwegian &&
                <li className="flex items-center space-x-1 px-3 py-1.5 lg:p-3">
                    <span className="material-icons text-bluegreen-500 text-lg" aria-hidden="true">location_pin</span>
                    <span className="text-yellow-600 uppercase text-base font-semibold">
                        {props.region}
                    </span>
                </li>
            }

        </ul>

        {(props.links || props.orgNumber || props.postalAddress || props.businessAddress || props.auditorNumber || props.professionalDirectorFor || (props.entityType !== "Person" && props.finanstilsynetId)) &&
            <>
                <div className="block bg-black-100 h-1 my-6" />
                <Info {...props} />
            </>
        }
    </div>