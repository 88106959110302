import React, { FC } from "react";
import { VregDetailViewModel } from "../../generatedTypes/FT.Web.ViewModels.Vreg";
import { RelationList } from "../RelationList";

interface Props {
    model: VregDetailViewModel,
    languageCode: string,
}

export const Relations: FC<Props> = (props: Props) => {
    if((props.model.relations === null)
        || ( props.model.relations.length === 0))
        return null;

    return (
        <section
            className="bg-white-400 p-6 lg:px-12 lg:py-9 border border-l-8 border-bluegreen-200 mb-6 relative"
        >
            <div className="flex flex-col lg:flex-row">
                <div className="w-full lg:w-8/12">
                    {props.model.relations.map((relation) => 
                        <RelationList
                            Relations={relation.relations}
                            languageCode={props.languageCode}
                            heading={relation.key}
                            headerSize="text-2xl"
                            id={`${relation.key.replace(' ', '')}List`}
                        />
                    )
                    }                    
                </div>
            </div>
        </section>
    );
}
