import React, { FC, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import { VirkeregSearchRequestDto, useSearchQuery } from "../Services/VregApi";
import { VregRootState } from "./vregSearchStore";
import { VirkeregSearchRequest } from "../../generatedTypes/FT.Web.Controllers.Api.Models.Virkereg";
import { translateLang } from "../../translate";
import { LanguageContext } from "../../components/LanguageContext";
import { vregSearchActions } from "../Services/VregSearchState";
import { SkeletonLoader } from "../../components/SkeletonLoader";
import { VregSearchHit } from "./VregSearchHit";

export const VregSearchResults: FC = () => {
    const languageContext = useContext(LanguageContext);
    const query = useSelector((state: VregRootState) => state.vregSearchState.query);
    const countryCode = useSelector((state: VregRootState) => state.vregSearchState.country);
    const pageNumber = useSelector((state: VregRootState) => state.vregSearchState.pageNumber);
    const entityType = useSelector((state: VregRootState) => state.vregSearchState.registerType);
    const countyNumbers = useSelector((state: VregRootState) => state.vregSearchState.selectedCounties);
    const licenceTypes = useSelector((state: VregRootState) => state.vregSearchState.selectedLicenceTypes);
    const mobileFilterOpen = useSelector((state: VregRootState) => state.vregSearchState.mobileFilterOpen);
    const debouncedQuery = useDebounce(query, 350);

    const { data, error, isLoading, isFetching, isSuccess } = useSearchQuery({
        body: {
            licenceTypes,
            query: debouncedQuery[0] || query,
            pageNumber,
            countryCode,
            entityType,
            countyNumbers
        } as VirkeregSearchRequest,
        language: languageContext.code,

    } as VirkeregSearchRequestDto);

    const dispatch = useDispatch();

    /* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }]*/

    useEffect(() => {
        dispatch(vregSearchActions.goToFirstPage());
    }, [query, countryCode, entityType, countyNumbers, licenceTypes]);

    useEffect(() => {
        data && dispatch(vregSearchActions.setTotalPages(data?.totalPages));
    }, [data]);

    if (error) {
        return (
            <h2 className="font-semibold">{translateLang('vreg.search.error', languageContext.code)}</h2>
        )
    }

    if (isLoading || isFetching){
        if(mobileFilterOpen) {
            return <></>
        }
        return (
            <SkeletonLoader type="list" />
        )
    }

    if(data?.totalHits === -1)
        return null

    if (isSuccess && data?.totalHits === 0) {
        return (
            <div className="px-6 py-3 bg-white-400 mb-6 text-sm leading-5">
                {translateLang('vreg.search.noHits', languageContext.code)}
            </div>
        )
    }

    return (
        <>
            {/* <!-- Search result summary --> */}
            <div className="px-6 py-3 bg-white-400 mb-6 text-sm leading-5">
                {translateLang('vreg.search.showingHitsXofY', languageContext.code, [
                    data?.page,
                    data?.totalPages,
                    query,
                    data?.totalHits,
                ])}
            </div>
            {/* <!-- // Search result summary --> */}

            {/* <!-- Search result - element --> */}
            {data?.hits.map((hit) => (
                <VregSearchHit key={hit.id} {...hit} />
            ))}
        </>
    );
}