import React, { FC, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LanguageContext } from '../../../components/LanguageContext';
import { translateLang } from '../../../translate';
import { useGetCountiesQuery } from '../../Services/VregApi';
import { ApiRequest } from '../../Services/ApiRequest';
import Loader from '../../../components/loader';
import { vregSearchActions } from '../../Services/VregSearchState';
import { VregRootState } from '../vregSearchStore';
import { SelectedItems } from './selectedItems';
import { CheckedChanged } from '../../Services/CheckedChanged';

export const CountyFilter: FC = () => {
    const [open, setOpen] = useState(false);
    const languageContext = useContext(LanguageContext);
    const { data, error, isLoading } = useGetCountiesQuery({
        language: languageContext.code,
    } as ApiRequest);
    const dispatch = useDispatch();
    const selectedCounties = useSelector((state: VregRootState) => state.vregSearchState.selectedCounties);

    const isChecked = (id: string) => {
        const found = selectedCounties.find(t => t === id);
        if(found)
            return true;
        return false;
    }
    
    return (
        <div className="w-full bg-white-400 lg:border lg:border-l-4 lg:py-4 lg:px-5 mb-6 lg:border-bluegreen-200">
            {/* <!-- Button - toggle filterpane --> */}
            {/* <!-- Bytt mellom icon--close og icon--expand__more --> */}
            <button
                type="button"
                onClick={() => setOpen(!open)}
                className={`w-full text-base font-semibold icon ${ open ? "icon--close" : "icon--expand__more"} icon--position__farright hover:text-bluegreen-500`}
                aria-label={translateLang('vreg.filters.county.title', languageContext.code)}
            >
                {translateLang('vreg.filters.county.title', languageContext.code)}
            </button>
            {/* <!-- // Button - toggle filterpane --> */}

            {isLoading && <Loader isLoading languageCode={languageContext.code} />}

            {error && 
                <h2 className='error'>{error}</h2>
            }

            {selectedCounties &&
                <SelectedItems checkItem={vregSearchActions.selectCounty} checkedItems={
                    data?.counties.filter(c => selectedCounties.indexOf(c.code.toString()) > -1).map<CheckedChanged>(county => ({
                        name: county.name,
                        id: county.code.toString()
                    })) || []
                } helptextKey="" />
            }

            {open && (
                <div className="flex flex-col mt-4 divide-y divide-black-200">
                    <div className="flex flex-col border-t border-black-200 mt-2 pt-2">
                        <div className="checkbox--custom checkbox--custom__small">
                            <button type="button" onClick={() => {dispatch(vregSearchActions.selectAllOrNoCounty())}} 
                                className='hover:text-bluegreen-500'>
                                {translateLang('vreg.buttons.resetCounties', languageContext.code)}
                            </button>
                        </div>
                        {data?.counties.map(county => 
                            <div className="checkbox--custom checkbox--custom__small" key={county.code}>
                                    <input type="checkbox" 
                                        id={`county_${county.code}`} 
                                        value={county.code} 
                                        onChange={() => {dispatch(vregSearchActions.selectCounty({id: county.code.toString(), name: county.name }))}}
                                        checked={isChecked(county.code.toString())} />
                                <label htmlFor={`county_${county.code}`} >
                                    {county.name}</label>
                            </div>
                        )}
                    </div>

                    {/* <!-- Close filter pane --> */}
                    <section className="pt-6 text-right">
                        <button
                            type="button"
                            onClick={() => setOpen(false)}
                            className="text-sm font-normal text-left icon icon--close hover:text-bluegreen-500 underline hover:no-underline"
                        >
                            {translateLang('vreg.buttons.close', languageContext.code)}
                        </button>
                    </section>
                </div>
            )}
        </div>
    );
};
