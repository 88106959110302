import React, { FC, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { VregRootState } from '../Search/vregSearchStore';
import { LanguageContext } from '../../components/LanguageContext';
import { Paginator } from '../../components/search/Paginator';
import { vregUserReportStateActions } from '../Services/VregUserReportState';

export const UserReportPaginator : FC = () => {
    const languageContext = useContext(LanguageContext);
    const dispatch = useDispatch();
    const pageNumber = useSelector((state: VregRootState) => state.vregUserReportState.pageNumber);
    const totalPages = useSelector((state: VregRootState) => state.vregUserReportState.totalPages); 
    
    return(
        <Paginator
        language={languageContext.code}
        onLoadMoreClickNext={()  => {
            dispatch(vregUserReportStateActions.incrementPage());
            window.scrollTo(0, 0);
        }}
        onLoadMoreClickPrev={()  => {
            dispatch(vregUserReportStateActions.decrementPage());
            window.scrollTo(0, 0);
        }}
        onJumpToPageClick={(page:number) => {
            dispatch(vregUserReportStateActions.setPage(page));
            window.scrollTo(0,0)
        }}
        searchResultPage={pageNumber}
        searchResultTotalPages={totalPages}
    />   
    )
}