import React from 'react'

interface TextBlockProps{
    header: string,
    mainBody: TrustedHTML
}

export const TextBlock = (props : TextBlockProps) => 
<div className='side-container bg-white-400 p-4 mb-2'>
    <h2 className='font-semibold mb-3 text-2xl'>{props.header}</h2>
    
    <div className='article-main-body article--bodytext' dangerouslySetInnerHTML={{ __html: props.mainBody}} />
</div>