import React, { useState } from "react";
import { ImageBankBlockViewModel } from "../../generatedTypes/FT.Web.ViewModels/imageBankBlockViewModel";

export const ImageBankImage: React.FC<ImageBankBlockViewModel> = (props): JSX.Element | null => {
    const [showMore, setShowMore] = useState(false);

    return (
        <div className={`dipcard ${showMore ? 'dipcard--open' : ''}`} >
            {showMore && (
                <button
                    type="button"
                    onClick={() => setShowMore(false)}
                    aria-label="Lukk vising av flere bilder"
                    className="btn btn--ghost text-xl absolute right-0 top-0">
                    <span className="material-icons" aria-hidden="true">
                        close
                    </span>
                </button>
            )}
            <div className="flex flex-row space-x-6 p-normal">

                <div className="dipcard--imagecontainer">
                    {props.imageFiles && (
                        <img src={`${props.imageFiles[0].url}?format=webp&height=150&rmode=crop`} alt={props.imageFiles[0].altText} />
                    )}
                </div>
                <div className="flex flex-col w-full justify-between">
                    <div>
                        <h2 className="dipcard--name">{props.header}</h2>
                        <p className="dipcard--title">{props.subHeading}</p>
                    </div>

                    <div>
                        {props.imageFiles && (
                            <p className="dipcard--credential">{props.imageFiles[0].credit}</p>
                        )}
                        <p className="flex flex-col space-y-3 lg:space-y-0 lg:flex-row lg:justify-between w-full lg:w-2/3">
                            <a href={props.imageFiles[0].url} title="Last ned bilde" download={props.imageFiles[0].fileName} className="text-bluegreen-500 underline decoration-1 underline-offset-4 hover:no-underline icon icon--file__download">
                                Last ned bilde
                            </a>

                            {props.imageFiles?.length > 1 && !showMore && (
                                <button
                                    type="button"
                                    className="text-left text-bluegreen-500 underline decoration-1 underline-offset-4 hover:no-underline icon icon--expand__more"
                                    onClick={() => setShowMore(!showMore)}>
                                    Flere bilder
                                </button>
                            )}
                        </p>
                    </div>
                </div>

            </div>

            {/*  Skjul og vis meg */}

            <div className={`dipcard--more  ${showMore ? '' : 'hidden'} `}>
                <div className="absolute z-50 -top-6 bg-lightgray-400 w-full p-6 border-x border-b border-l-4 border-bluegreen-300">
                    {props.imageFiles?.slice(1).map((o) =>
                        <div className="flex flex-row space-x-6" key={o.url}>
                            <div className="dipcard--imagecontainer">
                                <img src={`${o.url}?format=webp&height=150&rmode=crop`} alt={o.altText} title={o.description} />
                            </div>
                            <div className="flex flex-col w-full justify-end">
                                <div>
                                    <p className="dipcard--credential">{o.credit}</p>
                                    <p className="flex flex-col space-y-3 lg:space-y-0  lg:flex-row lg:justify-between w-full lg:w-2/3">
                                        <a href={o.url} download={o.fileName} title={`Last ned bilde ${o.fileName}`} className="text-bluegreen-500 underline decoration-1 underline-offset-4 hover:no-underline icon icon--file__download">
                                            Last ned bilde
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>

                    )}
                </div>
            </div>
            {/* <!-- // Skjul og vis meg --> */}
        </div>
    )
}