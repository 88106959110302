import React, { FC, useState } from "react";

interface TooltipProps {
    srlabel: string,
    tooltip: string
}

export const Tooltip: FC<TooltipProps> = (props) => {

    const [open, setOpen] = useState(false);

    return (

        <div className="relative" >

            <button type="button" onClick={() => setOpen(!open)} aria-label="Vis informasjon" className="icon icon--info text-lg text-black-400 hover:text-bluegreen-500">
                <span className="sr-only">
                    {props.srlabel}
                </span>
            </button>

            {/* <!-- Tip (toggle class hidden or use React to toggle html) --> */}
            <div className={`absolute z-50 bottom-12 ${open ? '': 'hidden'}`} >
                <button type="button" className="relative block w-[344px] bg-black-400 bg-opacity-90 p-3 text-white-400 text-base rounded-lg -translate-x-1/2" aria-hidden={!open} onClick={() => setOpen(false)} >
                    <div className="w-0 h-0 border-l-[20px] border-r-[20px] border-t-[20px] border-l-transparent border-r-transparent border-t-black-400 border-opacity-90 -bottom-5 left-[calc(50%-10px)] absolute" />
                    {props.tooltip}
                </button>
            </div>
        </div>
    )
}