import * as supervision from './components/subjectSelector/supervision';

export const NAME = 'topicCategoryPage';

export function init() {
    const dispatchSupervisionCategories = (items) => {
        const topicCategories = document.getElementsByClassName('topic-category-item-container');
        if (items.length === 0) {
            for (const item of topicCategories) {
                item.style.display = 'block';
            }
            return;
        }

        for (const item of topicCategories) {
            const id = parseInt(item.id);
            if (items.indexOf(id) === -1) item.style.display = 'none';
            else {
                item.style.display = 'block';
            }
        }
    };

    supervision.bootstrap(dispatchSupervisionCategories);

    const initialSupervisionCategories = supervision.getSavedSupervisionCategories();
    dispatchSupervisionCategories(initialSupervisionCategories);
}
