import React, { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { vregSearchStateSlice } from '../../Services/VregSearchState';
import { translateLang } from '../../../translate';
import { LanguageContext } from '../../../components/LanguageContext';

export const CountryFilter: FC = () => {
    const dispatch = useDispatch();
    const languageContext = useContext(LanguageContext);

    return (
        <div className="w-full bg-white-400 lg:border lg:border-l-4 lg:py-4 lg:px-5 mb-6 lg:border-bluegreen-200">
            <div className="w-full text-base font-semibold" aria-label="Land">
                {translateLang('vreg.filters.country.title', languageContext.code)}
            </div>
            <div className="mt-8">
                <div className="flex flex-col lg:flex-row lg:space-x-8 space-y-2 lg:space-y-0">
                    <div className="radiobox--custom">
                        <input
                            type="radio"
                            id="Norge"
                            name="country"
                            value="NOR"
                            onClick={() => dispatch(vregSearchStateSlice.actions.setCountry('NOR'))}
                        />
                        <label htmlFor="Norge">
                        {translateLang('vreg.filters.country.norway', languageContext.code)}
                        </label>
                    </div>
                    <div className="radiobox--custom">
                        <input
                            type="radio"
                            id="landalle"
                            name="country"
                            onClick={() => dispatch(vregSearchStateSlice.actions.setCountry(null))}
                            defaultChecked
                        />
                        <label htmlFor="landalle">
                            {translateLang('vreg.filters.country.all', languageContext.code)}
                        </label>
                    </div>

                    {/* <div className="radiobox--custom">
                        <input
                            type="radio"
                            id="Andreland"
                            name="country"
                            onClick={() =>
                                dispatch(vregSearchStateSlice.actions.setCountry('NotNOR'))
                            }
                        />
                        <label htmlFor="Andreland">
                        {translateLang('vreg.filters.country.other', languageContext.code)}
                        </label>
                    </div> */}
                </div>
            </div>
        </div>
    );
};
