import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translateLang } from '../../translate';
import { LanguageContext } from '../LanguageContext';

class SearchBox extends Component {
    static context = LanguageContext;

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.debounce = this.debounce.bind(this);
    }

    componentDidMount() {
        this.searchBox.focus();
        if (this.props.searchOnMount) {
            this.props.onSearch(this.props.initialQuery, false);
        }
    }

    handleChange(input) {
        const query = input.target.value;
        const search = () => this.props.onSearch(query, true);
        this.debounce(search, 300);
    }

    handleSubmit(event) {
        event.preventDefault();
        const query = event.target.query.value;
        const search = () => this.props.onSearch(query, true);
        this.debounce(search, 300);
    }

    debounce(func, wait) {
        const component = this;
        const later = function later() {
            component.timeout = null;
            func();
        };
        clearTimeout(component.timeout);
        component.timeout = setTimeout(later, wait);
    }

    render() {
        const { initialQuery } = this.props;

        return (
            <form className="common-row search-form flex w-full search-form-content" onSubmit={(e) => this.handleSubmit(e)}>
                <input
                    aria-label={translateLang('search.searchFieldLabel', this.context.code)}
                    onChange={this.handleChange}
                    ref={(element) => {
                        this.searchBox = element;
                    }}
                    type="text"
                    name="query"
                    className="search-input-box w-full"
                    id="js-searchQuery"
                    defaultValue={initialQuery}
                    placeholder={this.props.placeholderText}
                />
                <button className='btn btn--primary' type='button'>{this.props.buttonText}</button>
            </form>
        );
    }
}

SearchBox.propTypes = {
    initialQuery: PropTypes.string,
    onSearch: PropTypes.func.isRequired,
    placeholderText: PropTypes.string,
    searchOnMount: PropTypes.bool,
    buttonText: PropTypes.string
};

SearchBox.defaultProps = {
    buttonText: 'Søk'
}

SearchBox.contextType = LanguageContext;


export default SearchBox;
