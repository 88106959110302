import { combineReducers } from 'redux';
import { getLocale } from '../translate';
import {
    CHECK_METAFILTER,
    CHECK_TYPEFILTER,
    FILTERS_LOADED,
    LOADED_MORE,
    RESET_FILTERS,
    SEARCH_LOADED,
    SET_DATE_FILTERS,
    SET_SEARCH_QUERY,
    SET_SUPERVISION_CATEGORIES,
    SET_PAGE,
} from './actions';

const initialSettingsState = {
    lang: getLocale(),
};

const initialTypeFiltersState = {
    loaded: false,
    activeFilters: [],
    items: [],
};

const initialMetaDataFiltersState = {
    loaded: false,
    items: [],
};

const initialDateFiltersState = {
    items: []
};

const initialSearchResultState = {
    loading: false,
    items: [],
};

const initialSearchBoxState = {
    queryString: '',
};

const initialSupervisionCategoriesState = {
    activeItems: [],
    override: false
};

export const initialState = {
    typeFilters: initialTypeFiltersState,
    metaDataFilters: initialMetaDataFiltersState,
    dateFilters: initialDateFiltersState,
    searchResult: initialSearchResultState,
    searchBox: initialSearchBoxState,
    supervisionCategories: initialSupervisionCategoriesState,
    settings: initialSettingsState,
    overrideSubjectSelector: false
};

const settings = (state = initialSettingsState) => state;

const typeFilters = (state = initialTypeFiltersState, action) => {
    switch (action.type) {
        case CHECK_TYPEFILTER:
            return {
                ...state,
                activeFilters: state.activeFilters.includes(action.id)
                    ? state.activeFilters.filter((f) => f !== action.id)
                    : state.activeFilters.concat([action.id]),
            };
        case FILTERS_LOADED: {
            return {
                ...state,
                loaded: true,
                items: action.filters.filter((item) => !item.isMetaFilter),
            };
        }
        case RESET_FILTERS: {
            return {
                ...state,
                activeFilters: []
            }            
        }
        default:
            return state;
    }
};

const metaDataFilters = (state = initialMetaDataFiltersState, action) => {
    switch (action.type) {
        case CHECK_TYPEFILTER:
            return { ...state, activeFilter: -1 };

        case CHECK_METAFILTER:
            return {
                ...state,
                activeFilter: action.id === state.id ? -1 : action.id,
            };
        case FILTERS_LOADED:
            return {
                ...state,
                loaded: true,
                items: action.filters.filter((item) => item.isMetaFilter),
            };
        case RESET_FILTERS: 
            return { ...state, activeFilter: -1 };
        default:
            return state;
    }
};

const dateFilters = (state = initialDateFiltersState, action) => {
    switch (action.type) {
        case SET_DATE_FILTERS:
            if(action.dateFilters === -1){
                state.items = [];
                return {...state};
            }
            
            if( state.items.indexOf(action.dateFilters) === -1 ){                
                state.items.push(action.dateFilters)
            } else {                
                state.items.splice(state.items.indexOf(action.dateFilters), 1)
            }   

            return {
                ...state
            };
        case RESET_FILTERS:
            state.items = [];
            return {...state};
        default:
            return state;
    }
};

const searchResult = (state = initialSearchResultState, action) => {
    switch (action.type) {
        case SET_SEARCH_QUERY:
        case CHECK_TYPEFILTER:
        case CHECK_METAFILTER:
        case SET_DATE_FILTERS:
        case SET_SUPERVISION_CATEGORIES:
            return { ...state, loading: true };

        case SEARCH_LOADED:
            return {
                ...state,
                loading: false,
                items: action.searchResult.hits,                
                total: action.searchResult.totalHits,
                page: action.searchResult.page,
                pageSize: action.searchResult.pageSize,
                totalPages: action.searchResult.totalPages
            };
        case LOADED_MORE: 
            return {
                ...state,
                loading: false,
                items: action.searchResult.hits,
                total: action.searchResult.totalHits,
                pageSize: action.searchResult.pageSize,
                totalPages: action.searchResult.totalPages                
            };
        
        case SET_PAGE: 
            state.page = action.page;
            return {
                ...state,
                page: action.page
            };
        
        default:
            return state;
    }
};

const searchBox = (state = initialSearchBoxState, action) => {
    switch (action.type) {
        case SET_SEARCH_QUERY:
            return { ...state, queryString: action.queryString };
        default:
            return state;
    }
};

const supervisionCategories = (state = initialSupervisionCategoriesState, action) => {
    switch (action.type) {
        case SET_SUPERVISION_CATEGORIES:
            return { ...state, activeItems: action.activeItems };
        case RESET_FILTERS:
            return {...state, activeItems: []};
        default:
            return state;
    }
};

const newsarchiveReducer = combineReducers({
    typeFilters,
    metaDataFilters,
    dateFilters,
    searchResult,
    searchBox,
    supervisionCategories,
    settings,
});

export default newsarchiveReducer;
