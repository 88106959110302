import React, { FC, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { LanguageContext } from '../../../components/LanguageContext';
import { ExpandBox } from '../../../components/expandBox/ExpandBox';
import { useGetCountiesQuery } from '../../Services/VregApi';
import { ApiRequest } from '../../Services/ApiRequest';
import { SkeletonLoader } from '../../../components/SkeletonLoader';
import { vregUserReportStateActions } from '../../Services/VregUserReportState';
import { VregRootState } from '../../Search/vregSearchStore';
import { RadioButton } from '../../../components/forms/RadioButton';

export const CountyFilter: FC = () => {
    const radioButtonGroup = 'countyGroup'
    const languageContext = useContext(LanguageContext);
    const dispatch = useDispatch();
    const { data, isLoading, isFetching } = useGetCountiesQuery({
        language: languageContext.code
    } as ApiRequest);
    const state = useSelector((s: VregRootState) => s.vregUserReportState);

    return (
        <ExpandBox ariaLabelKey='vreg.filters.county.title' titleKey='vreg.filters.county.title' expanded={state.countyNumber != null} >
            {isLoading || isFetching && 
                    <SkeletonLoader type='list' />
                }            
            
            <div className='p-normal flex-col space-y-2'>                
                {data?.counties.map(county => 
                    <div className='custom--checkbox' key={county.code}>
                        <RadioButton 
                            group={radioButtonGroup}                            
                            value={county.code.toString()}
                            checked={state.countyNumber === county.code}                            
                            onChange={() => dispatch(vregUserReportStateActions.setCountyNumber(county.code))}
                            >
                            {county.name}
                        </RadioButton>
                    </div>
                )}
            </div>
        </ExpandBox>
    );
}