import React, { FC, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LanguageContext } from "../../components/LanguageContext";
import { Tooltip } from "../Components/Tooltip";
import { RadioButton } from "../../components/forms/RadioButton";
import { translateLang } from "../../translate";
import { useGetCountriesQuery, useGetLicenceGroupQuery } from "../Services/VregApi";
import { LicenceGroupRequest } from "../Services/LicenceGroupRequest";
import { window } from "../../util/ssrBase";
import { vregUserReportStateActions } from "../Services/VregUserReportState";
import { VregRootState } from "../Search/vregSearchStore";
import { SkeletonLoader } from "../../components/SkeletonLoader";
import { ApiRequest } from "../Services/ApiRequest";
import { ServicesFilter } from "./Filters/ServicesFilter";
import { CountyFilter } from "./Filters/Counties";

export const Header: FC = () => {
  const languageContext = useContext(LanguageContext);
  const queryParams = new URLSearchParams(window.location.search);
  const code = parseInt(queryParams.get("group") ?? "11", 10); // default to show "Bank and finance" if group parameter is not set

  const { data, error, isLoading, isFetching, isSuccess } = useGetLicenceGroupQuery({ groupId: code, language: languageContext.code } as LicenceGroupRequest);
  const countries = useGetCountriesQuery({ language: languageContext.code } as ApiRequest);
  const state = useSelector((s: VregRootState) => s.vregUserReportState);
  const dispatch = useDispatch();

  const toggleBorderCrossing = () => {
    if (state.passporting === "Outbound")
      dispatch(vregUserReportStateActions.setPassporting("Inbound"));
    else
      dispatch(vregUserReportStateActions.setPassporting("Outbound"));
  }

  if ((isLoading || isFetching) || (countries.isLoading || countries.isFetching)) {
    return <SkeletonLoader type="header" />
  }

  if (error) {
    return <h2 className="text-2xl font-semibold">En feil oppsto under lasting</h2>
  }

  if (isSuccess && data) {
    dispatch(vregUserReportStateActions.setAvailableLicenceGroupTypes(data.licenceTypes))
  }

  return (
    <div className="bg-white-400 p-6 mb-6">
      <h1 className="font-normal text-2xl lg:text-3xl">
        {data?.name}
      </h1>

      <div className="block bg-black-100 h-1 my-6" />

      <div className="flex flex-col space-y-4 mb-7">
        <div className="flex space-x-3 items-center">

          <RadioButton
            group="virktyp"
            value="Domestic"
            checked={state.passporting === "Domestic"}
            onChange={() => dispatch(vregUserReportStateActions.setPassporting("Domestic"))}
          >
            {translateLang('vreg.reports.licencedNorwegianBusiness', languageContext.code)}
          </RadioButton>

          <Tooltip
            srlabel={translateLang('vreg.reports.tooltips.show', languageContext.code)}
            tooltip={translateLang('vreg.reports.tooltips.licencedNorwegianBusiness', languageContext.code)}
          />
        </div>
        <div className={`flex flex-col w-full lg:w-4/12 mt-4 px-4 lg:space-x-6 mb-1 ${state.passporting === 'Domestic' ? '' : 'hidden'}`}>
          <CountyFilter />
        </div>

        <div className="flex flex-col">

          <div className="flex space-x-3 items-center">
            <RadioButton
              group="virktyp"
              value="Outbound"
              checked={state.passporting !== "Domestic"}
              onChange={() => dispatch(vregUserReportStateActions.setPassporting("Outbound"))}
            >
              {translateLang('vreg.reports.crossBorderBusiness', languageContext.code)}
            </RadioButton>

            <Tooltip
              srlabel={translateLang('vreg.reports.tooltips.show', languageContext.code)}
              tooltip={translateLang('vreg.reports.tooltips.crossBorderBusiness', languageContext.code)}
            />
          </div>

          <div className={`flex flex-col lg:flex-row mt-4 px-4 lg:space-x-6 mb-1 ${state.passporting !== 'Domestic' ? '' : 'hidden'}`}>

            <div className="inline-flex items-center bg-bluegreen-200 rounded-full p-1 text-xs font-semibold mb-6 ">
              <button onClick={() => toggleBorderCrossing()}
                type="button" className={`p-3 rounded-full ${state.passporting === "Inbound" ? "bg-bluegreen-200" : "bg-bluegreen-600  text-white-400 "}`}>
                {translateLang(`vreg.reports.crossBorderSettings.Norway${state.passporting}`, languageContext.code)}
                {state.passporting === "Outbound" &&
                  <span className="icon icon--custom">east</span>
                }
              </button>
              <button onClick={() => toggleBorderCrossing()}
                type="button"
                className={`p-3 rounded-full ${state.passporting === "Outbound" ? "bg-bluegreen-200" : "bg-bluegreen-600  text-white-400 "}`}>
                {state.passporting === "Inbound" &&
                  <span className="icon icon--custom">west</span>
                }
                {translateLang(`vreg.reports.crossBorderSettings.${state.passporting}`, languageContext.code)}
              </button>
            </div>
            <label htmlFor="bordercrossing_country" >
              <span className="sr-only">{translateLang('vreg.reports.crossBorderSettings.select', languageContext.code)}</span>
              <select
                id='bordercrossing_country'
                className="p-3 w-full border-t border-b border-l-4 border-r border-bluegreen-300 bg-white-400 relative"
                onChange={(e) => dispatch(vregUserReportStateActions.setCountry(e.target.value))}
                value={state.countryCode || ''}
              >
                {countries.data?.map(country =>
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                )}
              </select>
            </label>
          </div>
        </div>
      </div>

      <div className="mb-6">
        {translateLang('vreg.reports.tooltips.selectServicesShort', languageContext.code)}
      </div>

      <div className="flex overflow-x-auto lg:overflow-visible hide-scrollbar lg:flex-wrap gap-3 w-full   mb-6">
        {state?.licenceGroupLicenceTypes          
          .map(licenceType =>
          <div className='checkbox--custom checkbox--custom__small' key={licenceType.code}>
            <input
              id={`${licenceType.code}_chkbx`}
              onChange={() => dispatch(vregUserReportStateActions.toggleLicenceType(licenceType.code))}
              type="checkbox" 
              checked={state.selectedLicenceTypeCodes.indexOf(licenceType.code) > -1}
              />
            <label htmlFor={`${licenceType.code}_chkbx`}>
              {licenceType.name}
            </label>
          </div>
        )}
      </div>

      <div className="flex overflow-x-auto lg:overflow-visible hide-scrollbar lg:flex-wrap gap-3 w-full lg:w-10/12">
        {state.licenceGroupLicenceTypes
          .filter(f => state.selectedLicenceTypeCodes.includes(f.code) && (f.classifications[0].services.length > 0 || f.serviceGroups.length > 0))
          .map(selectedLicenceType =>
            <ServicesFilter selectedLicenceType={selectedLicenceType} key={selectedLicenceType.code} />
          )}
      </div>
    </div >
  )
}