import qs from 'qs';
import { SearchResult, SearchResultSuggestion } from '../../generatedTypes/FT.Web.Search.Models';
import { GlobalSearchFilter } from '../../generatedTypes/FT.Web.Search.Models/globalSearchFilter';
import { SearchRequestState, SuggestSearchRequestState } from './reducer';

export const globalSearch = async (searchState: SearchRequestState): Promise<SearchResult> => {
    const urlParams = {
        query: searchState.queryParams.queryString,
        language: searchState.lang,
        page: searchState.page,
        filter: searchState.queryParams.activeFilters,
        sortByDate: searchState.queryParams.sortByDate,
    };

    const queryString = qs.stringify({ ...urlParams }, { encodeValuesOnly: true });

    return fetch(`/api/search/global?${queryString}`).then((response: Response) => response.json());
};

export const getFilters = async (lang: string): Promise<GlobalSearchFilter[]> =>
    fetch(`/api/search/global/filters?lang=${lang}`).then((response) => response.json());

export const suggestSearch = async (
    searchState: SuggestSearchRequestState
): Promise<SearchResultSuggestion[]> => {
    const urlParams = {
        query: searchState.query,
        language: searchState.lang,
    };

    const queryString = qs.stringify({ ...urlParams }, { encodeValuesOnly: true });

    return fetch(`/api/search/global/suggest?${queryString}`).then((response: Response) =>
        response.json()
    );
};
