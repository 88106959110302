import { extend, getDocument, getWindow, ssrDocument, ssrWindow } from 'ssr-window';

/*
    This here class helps us handle Server-Side rendering of React components
    By mocking the document and window objects, we can postpone rewriting some of the old components
    Usage: import { window, document } from './util/ssrWindow'
    Et voìla!    
 */

extend(ssrDocument, {
    /* eslint-disable */
    getElementsByClassName() : Array<object> { return new Array(); },
    getElementsByTagName() : Array<object> { return new Array(); }
    /* eslint-enable */
});

extend(ssrWindow, {
    Element: {
        prototype: {}
    },
    server: true,
    devToolsExtension: null
});

const window = getWindow();
const document = getDocument();
const isServer = getWindow().navigator.userAgent === '';
const isMobile = !isServer && window.innerWidth < 768;

export { window }
export { document }
export { isServer }
export { isMobile }
