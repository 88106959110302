const colorsLong = [
    '#16535B',
    '#0CA3BC',
    '#117B8C',
    '#5B5234',
    '#A39558',
    '#776D46',
    '#745986',
    '#A18FB1',
    '#3D234E',
    '#5470AD',
    '#1F3972',
    '#7F3972',
];
const colorsShort = ['#16535B', '#117B8C', '#1890A6', '#0CA3BC', '#5B5234', '#776D46', '#A39558'];
const lineChartColors = [
    '#0CA3BC',
    '#117B8C',
    '#5B5234',
    '#A39558',
    '#16535B',
    '#0CA3BC',
    '#117B8C',
    '#5B5234',
    '#A39558',
    '#16535B',
    '#0CA3BC',
    '#117B8C',
];
const combinationChartColors = ['#9EDAE4', '#0CA3BC', '#F4EDBF', '#D2C174'];

// options added here should also be added to ClientResources/Scripts/TinyMcePlugins/highcharts-loader.js
export const highchartsOptions: Highcharts.Options = {
    chart: {
        style: { fontFamily: 'Arial, sans-serif', color: '#282828' },
        spacingBottom: 30,
    },
    title: {
        style: { fontFamily: 'Arial, sans-serif', color: '#282828', align: 'left' },
    },
    subtitle: {
        style: { fontFamily: 'Arial, sans-serif', color: '#282828', align: 'left' },
    },
    accessibility: {
        landmarkVerbosity: 'disabled',
    },
    colors: colorsLong,
    credits: {
        text: 'Kilde: Finanstilsynet',
        href: 'https://www.finanstilsynet.no/',
        position: {
            align: 'left',
            x: 10,
        },
    },
    exporting: {
        enabled: true,
    },
    legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        itemStyle: { fontFamily: 'Arial, sans-serif', color: '#282828' },
        title: {
            style: { fontFamily: 'Arial, sans-serif', color: '#282828' },
        },
    },
    plotOptions: {
        area: {
            fillOpacity: 0.35,
            lineWidth: 1.5,
        },
        series: {
            lineWidth: 3,
            borderColor: '#000000',
            borderWidth: 1,
        },
        column: { borderRadius: 0 },
        bar: {
            borderRadius: 0,
        },
        line: {
            lineWidth: 3,
        },
    },
    responsive: {
        rules: [
            {
                condition: {
                    maxWidth: 927,
                },
                chartOptions: {
                    legend: {
                        align: 'center',
                        verticalAlign: 'bottom',
                        layout: 'horizontal',
                    },
                },
            },
        ],
    },
    xAxis: [
        {
            lineWidth: 1,
            tickPosition: 'inside',
            tickLength: 6,
            title: {
                style: { fontFamily: 'Arial, sans-serif', color: '#282828' },
            },
        },
    ],
    yAxis: [
        {
            lineWidth: 1,
            tickWidth: 1,
            tickLength: 6,
            tickPosition: 'inside',
            gridLineWidth: 0,
            title: {
                align: 'high',
                offset: 0,
                rotation: 0,
                y: -20,
                style: { fontFamily: 'Arial, sans-serif', color: '#282828' },
            },
        },
        {
            lineWidth: 1,
            tickWidth: 1,
            tickLength: 6,
            tickPosition: 'inside',
            gridLineWidth: 0,
            opposite: true,
            linkedTo: 0,
            title: {
                text: undefined,
                style: { fontFamily: 'Arial, sans-serif', color: '#282828' },
            },
        },
    ],
    tooltip: {
        valueDecimals: 2,
    },
};

const getColors = (chartType: string, isCombinationChart: boolean, seriesLength: number) => {
    if (chartType === 'line') return lineChartColors;
    if (isCombinationChart) return combinationChartColors;
    return seriesLength > 7 ? colorsLong : colorsShort;
};

const getLineWithForSeriesIndex = (index: number): number => {
    switch (index) {
        case 0:
            return 2;
        case 1:
            return 3.25;
        case 2:
            return 1.5;
        case 3:
            return 2.25;
        case 4:
            return 1.5;
        case 5:
            return 2.5;
        case 6:
            return 2.75;
        case 7:
            return 1.75;
        case 8:
            return 2.75;
        case 9:
            return 1.5;
        case 10:
            return 2.5;
        default:
            return 1.5;
    }
};

const getAreaOptions = (index: number): Highcharts.SeriesAreaOptions => {
    switch (index) {
        case 0:
            return {
                type: 'area',
                color: '#16535B',
                lineWidth: 2.5,
                fillOpacity: 0,
            };
        case 1:
            return {
                type: 'area',
                color: '#0CA3BC',
                fillColor: 'rgba(12,163,188,0.15)',
            };
        case 2:
            return {
                type: 'area',
                color: '#16535B',
                dashStyle: 'ShortDot',
                fillColor: {
                    pattern: {
                        path: {
                            d: 'M 0.5 0.5 H 4.5 V 4.5 H 0.5 L 0.5 0.5',
                            fill: '#16535B',
                            strokeWidth: 0,
                        },
                        width: 12,
                        height: 12,
                        color: '#16535B',
                        opacity: 0.9,
                    },
                },
            };
        case 3:
            return {
                type: 'area',
                color: '#16535B',
                lineWidth: 1,
                fillColor: 'rgba(17,123,140,0.35)',
            };
        case 4:
            return {
                type: 'area',
                color: '#A39558',
                lineWidth: 1.25,
                dashStyle: 'Dash',
                fillColor: 'rgba(163,149,88,0.35)',
            };
        case 5:
            return {
                type: 'area',
                color: '#776D46',
                dashStyle: 'ShortDash',
                fillColor: 'rgba(119,109,70,0.35)',
            };
        default:
            return {
                type: 'area',
            };
    }
};

const getBorderColor = (index: number): string => {
    const color = combinationChartColors?.[index];
    switch (color) {
        case '#9EDAE4':
        case '#0CA3BC':
            return '#0CA3BC';
        case '#F4EDBF':
        case '#D2C174':
            return '#A39558';
        default:
            return '#ffffff';
    }
};

// adjust options based on chart type etc
export const getDynamicOptions = (options: Highcharts.Options): Highcharts.Options => {
    const chartType = options.chart?.type || 'line';
    const isStacked = !!options.plotOptions?.series?.stacking;
    const seriesLength = options.series?.length ?? 0;
    const seriesTypes = options.series?.map((s) => s.type).filter(Boolean) ?? [];
    const isCombinationChart =
        (chartType === 'column' && seriesTypes.includes('line')) ||
        (chartType === 'line' && seriesTypes.includes('column'));

    return {
        ...options,
        colors: getColors(chartType, isCombinationChart, seriesLength),
        yAxis: Array.isArray(options.yAxis)
            ? (options.yAxis as Highcharts.YAxisOptions[])?.map((yAxis, index) => {
                  if (index === 0) {
                      const hasLongTitle = yAxis.title?.text && yAxis.title?.text?.length > 10;
                      if (!hasLongTitle) return yAxis;
                      return {
                          ...yAxis,
                          title: {
                              ...yAxis.title,
                              layout: 'horizontal',
                              align: 'middle',
                              verticalAlign: 'middle',
                              rotation: 270,
                              offset: 40,
                          },
                      };
                  }
                  return yAxis;
              })
            : options.yAxis,
        legend: {
            ...options.legend,
            ...((chartType === 'column' && !isStacked) ||
            (chartType === 'bar' && isStacked) ||
            chartType === '"columnrange"'
                ? {
                      align: 'center',
                      verticalAlign: 'bottom',
                      layout: 'horizontal',
                  }
                : {}),
        },
        chart: {
            ...options.chart,
            style: options.chart?.style && { ...options.chart.style, fontSize: undefined },
        },
        series: options.series?.map((series, index) => {
            if (series.type === 'line' || (chartType === 'line' && !series.type))
                return {
                    ...series,
                    lineWidth: isCombinationChart ? 2 : getLineWithForSeriesIndex(index),
                    color: isCombinationChart ? '#5B5234' : series.color,
                };
            if (isCombinationChart && (series.type as string) !== 'line') {
                // casting to string is neccessary, due to "line" incorrectly missing from type
                return { ...series, borderWidth: 1, borderColor: getBorderColor(index) };
            }
            if (series.type === 'area' || (chartType === 'area' && !series.type)) {
                return {
                    ...series,
                    fillOpacity: 0.35,
                    ...getAreaOptions(index),
                };
            }
            return series;
        }),
    };
};
