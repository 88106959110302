import React, { FC, useContext } from "react"
import { LanguageContext } from "./LanguageContext"
import { translateLang } from "../translate";

interface SkeletonProps {
  type: "header" | "list"
}

export const SkeletonLoader: FC<SkeletonProps> = (props) => {
  const languageContext = useContext(LanguageContext);

  if (props.type === "header") {
    return (
      <div className="w-full p-4 mx-auto bg-white-400 h-80 mb-6"
        aria-label={translateLang('common.loading', languageContext.code)}>
        <div className="animate-pulse flex space-x-4">
          <div className="flex-1 space-y-6 py-1">
            <div className="h-10 bg-black-200 rounded w-4/12" />
            <div className="space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-3 bg-black-200 rounded col-span-2" />
                <div className="h-3 bg-black-200 rounded col-span-1" />
              </div>
              <div className="h-3 bg-black-200 rounded" />
            </div>
          </div>
        </div>
      </div>)

  }

  return (<>
    <div className="w-full p-4 mx-auto bg-white-400 mb-6">
      <div className="animate-pulse h-2 bg-black-200 rounded col-span-1" />
    </div>

    {[1,2,3].map((i) => (
    <div className="w-full p-4 mx-auto bg-white-400 mb-6" key={`a${i}`}
      aria-label={translateLang('common.loading', languageContext.code)}>
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-6 py-1">
          <div className="h-6 bg-black-200 rounded col-1" />
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="h-2 bg-black-200 rounded col-span-5" />
              <div className="h-2 bg-black-200 rounded col-span-5" />              
            </div>
            <div className="grid grid-cols-3 gap-4">
              <div className="h-2 bg-black-200 rounded col-span-5" />
              <div className="h-2 bg-black-200 rounded col-span-5" />              
            </div>
          </div>
        </div>
      </div>
    </div>
    ))}
  </>)

}