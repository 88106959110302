import React from 'react';
import { Filter, ThemeList } from '../generatedTypes/FT.Web.ViewModels.Theme';
import { ThemesGroupList } from './themesGroupList';
import { ThemesOverviewFilter }  from './themesOverviewFilter';

interface Props {
    language: string | null | undefined
}

interface State {
  filters?: Filter[];
  themes?: ThemeList[];
  selectedFilters: string[];  
}

export class ThemesOverviewComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            filters: undefined,
            themes: undefined,
            selectedFilters: []           
        }
        this.getViewModel();
    }   
    
    getViewModel() : void {        
        fetch(`/api/themes/overview?language=${this.props.language}`)
        .then(response => response.json())
        .then((response) => {                      
            this.setState({                
                filters: response.filters,
                themes: response.themes              
            })
        });
    }

    getSelectedFilters = (prevSelectedFilters: string[], clickedFilter: string): string[] => {
        let filters = [];
        if (prevSelectedFilters.indexOf(clickedFilter) > -1) {
            filters = prevSelectedFilters.filter(x => x !== clickedFilter);
        }
        else {
            prevSelectedFilters.push(clickedFilter);
            filters = prevSelectedFilters;
        }
        return filters;
    }

    filterClicked = (id: string) : void => { 
        const selectedFiltersCopy = this.state.selectedFilters;          
        this.setState({
            selectedFilters: this.getSelectedFilters(selectedFiltersCopy, id)
        })
    }

    isSelected = (themeId: string) : boolean => { 
        // if no filters are selected -> show all
        if (this.state.selectedFilters.length === 0) {
            return true;
        }
        if (this.state.selectedFilters.includes(themeId)) {
            return true;
        } 
        return false;
    };

    render() : JSX.Element {
        return (
            <div className='flex flex-col lg:flex-row lg:space-x-6'>
                    {this.state.filters && 
                        <ThemesOverviewFilter filters={this.state.filters} clickHandler={(id) => this.filterClicked(id)} selectedFilters={this.state.selectedFilters} />        
                    }                    
                
                <main className="w-full lg:w-9/12">                    
                    {this.state.themes && this.state.themes.filter(t => this.isSelected(t.themeId)).map(themeList => (
                        <ThemesGroupList key={`themelist_${themeList.themeId}`} list={themeList} /> 
                    ))}
                </main>
            </div>
        )
    }
}
