export type MergeObjectType = { [key: string]: string | MergeObjectType | MergeObjectType[] };

// Merge obj2 into obj1, without overwriting nested values.
export const mergeObjects = (obj1: MergeObjectType = {}, obj2: MergeObjectType = {}) => {
    const merged: MergeObjectType = { ...obj1 };

    Object.keys(obj2).forEach((key) => {
        const obj2Value = obj2[key];
        const obj1Value = obj1[key];

        if (Array.isArray(obj2Value)) {
            // Overwrite if arrays contain primitive values, otherwise merge items at corresponding indices
            if (
                Array.isArray(obj1Value) &&
                obj2Value.every((val) => typeof val === 'object') &&
                obj1Value.every((val) => typeof val === 'object')
            ) {
                if(obj1Value.length>obj2Value.length){
                    merged[key] = obj1Value.map((item, i) =>
                        obj1Value[i]
                            ? mergeObjects(obj1Value[i] as MergeObjectType, item as MergeObjectType)
                            : item
                    );
                }
               else merged[key] = obj2Value.map((item, i) =>
                    obj1Value[i]
                        ? mergeObjects(obj1Value[i] as MergeObjectType, item as MergeObjectType)
                        : item
                );
            } else {
                merged[key] = obj2Value;
            }
        } else if (typeof obj2Value === 'object' && obj2Value !== null) {
            merged[key] =
                typeof obj1Value === 'object' && !Array.isArray(obj1Value)
                    ? mergeObjects(obj1Value as MergeObjectType, obj2Value as MergeObjectType)
                    : obj2Value;
        } else {
            merged[key] = obj2Value;
        }
    });


    return merged;
};
