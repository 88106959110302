import React from 'react';

// TODO rewrite to TSX is 99% complete but how to specify children as multiple html elements? not finished
export const RadioButton = ({ group, value, checked, onChange, children }) => {
    const id = `${group}-${value}`;
    return (
        <div className='radiobox--custom'>
            <input
                type="radio"
                name={group}
                id={id}
                value={value}
                checked={checked}
                onChange={onChange}
            />
            <label htmlFor={id} className="font-semibold">
                <span>{children}</span>
            </label>
        </div>
    );
};
