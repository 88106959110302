import qs from 'qs';
import React from 'react';
import { Provider } from 'react-redux';
import { addTabbingEventListener } from '../util/eventListeners';
import { setScrollPosition, scrollToPrevPosition } from '../util/persistScroll';
import { window } from '../util/ssrBase';
import { globalSearchReducer, initialState } from './data/reducer';
import { SearchPageSearchBox } from './searchBox';
import { store } from './theStore';
import { getLanguage, LanguageContext } from '../components/LanguageContext';

export const NAME = 'searchpage';

function populateInitialState() {
    if (window.location.search) {
        const queryFromUrl = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });

        const pageFromQuery = queryFromUrl.page as string ?? '1';
        initialState.searchBox.queryParams = {
            queryString: (queryFromUrl.q as string) ?? '',
            activeFilters: (queryFromUrl.filter as string[]) ?? [],
            sortByDate: queryFromUrl.sortbydate ? queryFromUrl.sortbydate === 'true' : false,
            page: parseInt(pageFromQuery, 10)
        };
    }
}

addTabbingEventListener();

interface SearchPageProps {
    language: string,
    title: string
}

export type Store = ReturnType<typeof globalSearchReducer>;

export class SearchPageSearch extends React.Component<SearchPageProps, unknown> {

    constructor(props: SearchPageProps) {
        super(props);
        setScrollPosition(NAME);
        populateInitialState();
    };

    componentDidMount() {
        scrollToPrevPosition(NAME); 
    };

    render() : JSX.Element {
            return (
            <Provider store={store}>
                <LanguageContext.Provider value={ getLanguage(this.props.language)}>
                    <SearchPageSearchBox title={this.props.title} />
                </LanguageContext.Provider>
            </Provider>
            );
    }
} 
