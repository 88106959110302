import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface VregStandardReportState {
    pageNumber: number
    totalPages: number
    pageSize: number
}

const initialState: VregStandardReportState = {
    pageNumber: 1,
    totalPages: 10,
    pageSize: 20
};

export const vregStandardReportSlice = createSlice({
    name: 'vresStandardReport',
    initialState,
    reducers: {
        setTotalPages: (state, action: PayloadAction<number>) => {
            state.totalPages = action.payload;
        },
        goToFirstPage: (state) => {
            state.pageNumber = 1;
        },
        incrementPage: (state) => {
            state.pageNumber += 1;
        },
        decrementPage: (state) => {
            state.pageNumber -= 1;
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.pageNumber = action.payload;
        }
    },
});

export const vregStandardReportReducer = vregStandardReportSlice.reducer;
export const vregStandardReportActions = vregStandardReportSlice.actions;