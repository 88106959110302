import React, { FC } from "react";
import { Provider } from "react-redux";
import { store } from "./vregSearchStore";
import { getLanguage, LanguageContext } from "../../components/LanguageContext";
import { VregMainWrapper } from "./VregMainWrapper";

interface VregSearchProps {
    languageCode: string,
    showPopup: boolean
}

export const VregSearchInputAndResults: FC<VregSearchProps> = (props: VregSearchProps) =>
        <Provider store={store}  >
            <LanguageContext.Provider value={getLanguage(props.languageCode)}>
                <React.StrictMode>
                        <VregMainWrapper showPopup={props.showPopup} />
                </React.StrictMode>
            </LanguageContext.Provider>
        </Provider>
