import React from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import URLSearchParams from '@ungap/url-search-params';
import Pillar2SearchBox from './pillar2SearchBox';
import Pillar2SearchResults from './pillar2SearchResults';
import Pillar2SearchState from './pillar2SearchState';
import pillar2Reducer, { initialState } from './pillar2reducer';
import { window } from '../util/ssrBase';
import { LanguageContext, getLanguage } from '../components/LanguageContext';

function populateInitialState(language) {
    if (window.location.search) {
        const params = new URLSearchParams(window.location.search.substring(1));
        initialState.searchBox.queryString = params.get('q') || '';
    }
    initialState.settings.lang = language;
}

export class Pillar2Archive extends React.Component {

    store = null;

    constructor(props) {
        super(props);
        populateInitialState(this.props.language);
        this.store = createStore(pillar2Reducer, initialState, applyMiddleware(thunk));
    }

    render() {
        return (
            <LanguageContext.Provider value={getLanguage(this.props.language)}>
                <Provider store={this.store}>

                    <div className="lg:pl-5">
                        <div className="flex w-full mb-6">
                            <Pillar2SearchBox />
                        </div>
                    </div>

                    <Pillar2SearchState />

                    <div className="lg:pl-5">
                        <Pillar2SearchResults />
                    </div>

                </Provider >
            </LanguageContext.Provider>
        );
    }
} // End of init
