import React, {useState} from 'react';
import CheckboxList from '../components/CheckboxList';
import { Filter } from '../generatedTypes/FT.Web.ViewModels.Theme';
import { translate } from '../translate';

interface Props {
    filters: Filter[],
    selectedFilters: string[],
    clickHandler: (id: string) => void
}

export const ThemesOverviewFilter: React.FC<Props> = (props) => {
    const [collapsed, setCollapsed] = useState(true);

    const { filters, selectedFilters } = props;

    const isChecked = (item: Filter) => (selectedFilters ? selectedFilters.includes(item.id) : false);

    function checked(id: string): void {
        props.clickHandler(id);
    }

    return (
        <aside className="w-full lg:w-3/12 px-6 lg:px-0 relative">
            <div
                role="region" aria-labelledby="theme-filter-header"
                className="bg-white-400 p-6 lg:p-0 -mx-6 lg:mx-0 mb-6">
                <div
                    className="w-full border-t border-b border-l-4 border-r border-bluegreen-300 bg-lightgray-400 lg:bg-white-400 theme-filter-header-container">
                    <button 
                        onClick={() => setCollapsed(!collapsed)}
                        title="Åpne filtervalg"
                        type="button"
                        className="flex items-center justify-between w-full p-4 cursor-pointer hover:text-bluegreen-500 text-black-400 text-md" aria-expanded="true" aria-controls="Foobar" id="theme-filter-header">
                        <span>{translate('themesOverview.headers.filter')} 
                         {selectedFilters?.length > 0 && (
                            <>
                               &nbsp; ({selectedFilters.length})
                            </>
                         )}
                        </span>
                        <span className="material-icons" aria-hidden="true">
                            {collapsed ? "expand_more" : "expand_less"}
                            
                        </span>
                    </button>
                    {!collapsed && (
                    <div className="px-4" id="Foobar" aria-hidden="false">
                        <CheckboxList
                            typefilters={filters.map((item) => ({
                                checked: isChecked(item),
                                ...item,
                            }))}
                            onTypeFilterClick={(id) => checked(id)}
                        />
                    </div>
                    )}
                </div>
            </div>
        </aside >
    )
}
