import zenscroll from 'zenscroll';
import { document, window } from '../util/ssrBase';

// NET-676: Originally this page was meant for the Annual Report publication, hence the AnnualReport-prefix
// Later it was requested that this page type should be used as a generic page for alle publications,
// but the request came to close to feature release so we didn't have time to refactor class names, properties
// or anything related to the AnnualReport name.

export const NAME = 'annualReportPage';
const menuElements = Array.from(
    document.getElementsByClassName('annual-report-menu-items')
) as HTMLElement[];

const viewPortHeight = window.visualViewport?.height || window.innerHeight;
let enableWheelEvent = true;

export function init(): void {
    annualReportScrollSpy();

    menuElements.forEach((e) => {
        const container = document.getElementById(e.innerText);
        if (container) {
            e.onclick = () => {
                smoothScrollTo(container);
            };
        }
    });
}

function isInMiddleOfViewPort(element: HTMLElement) {
    const rect = element.getBoundingClientRect();
    return (
        rect.top <= viewPortHeight * 0.4 && (rect.bottom < viewPortHeight ? rect.top >= 0 : true)
    );
}

function isInViewPort(element: HTMLElement) {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

const annualReportScrollSpy = () => {
    window.addEventListener(
        'wheel',
        (e) => {
            e.preventDefault(); // Only works with thrid listener-parameter {passive: false}

            if (!enableWheelEvent) return;
            enableWheelEvent = false;

            const elements = Array.from(
                document.getElementsByClassName('focus-background')
            ) as HTMLElement[];
            const headerElement = document.getElementById('js-globalheader') || new HTMLElement();
            const preambleElement = document.getElementById('js-preamble') || new HTMLElement();

            if (!elements.includes(headerElement)) elements.unshift(headerElement);

            let scrollDirection;
            let targetElement = elements[0];

            // get scroll direction:
            if (e.deltaY <= 0) scrollDirection = 'up';
            else if (e.deltaY > 0) scrollDirection = 'down';

            // Initially set target up and down
            const [first, second] = elements;
            let targetUp = first;
            let targetDown =
                !isInViewPort(preambleElement) && isInViewPort(headerElement)
                    ? preambleElement
                    : second;

            // loop through elements to see if any elements are visible. If so, set target up and down based on this.
            elements.forEach((el) => {
                if (el.classList.contains('visible')) {
                    targetUp = elements[elements.indexOf(el) - 1];
                    targetDown = elements[elements.indexOf(el) + 1];
                }
            });

            // determine which of targetUp or targetDown to scroll to, based on scrollDirection:
            if (scrollDirection === 'down') targetElement = targetDown;
            else if (scrollDirection === 'up') targetElement = targetUp;
            // end if

            smoothScrollTo(targetElement);

            // To support trackpads firing wheel events at much faster rate than the mouse wheel
            // we need to throttle the wheel event
            setTimeout(() => {
                enableWheelEvent = true;
            }, 1000);
        },
        { passive: false }
    );

    window.addEventListener('scroll', () => {
        const elements = Array.from(
            document.getElementsByClassName('focus-background')
        ) as HTMLElement[];

        const activeSections = elements.filter(isInMiddleOfViewPort);
        const inactiveSections = elements.filter((x) => !isInMiddleOfViewPort(x));

        // Add styling to focused element
        if (activeSections.length > 0) {
            activeSections[0].classList.add('visible');
            const readMoreButton = activeSections[0].getElementsByClassName(
                'annual-report-button read-more'
            )[0] as HTMLElement;
            readMoreButton.classList.remove('hidden');

            const headerId = activeSections[0].id;
            const activeHeader = menuElements.filter((x) => x.innerText === headerId)[0];
            activeHeader?.classList.add('active');
        }

        // Remove styling to unfocused elements
        inactiveSections.forEach((e) => {
            e.classList.remove('visible');
            const readMoreButton = e.getElementsByClassName(
                'annual-report-button read-more'
            )[0] as HTMLElement;
            readMoreButton.classList.add('hidden');
        });

        // Highlight menu element corresponding to focused element
        menuElements
            .filter((e) => activeSections.length === 0 || !(activeSections[0].id === e.innerText))
            .map((e) => e.classList.remove('active'));
    });
};

const smoothScrollTo = (element: HTMLElement) => {
    if (element) {
        const mainPageImage = element.getElementsByClassName('main-page-link-image')[0];

        // Do not want element at top of page, hence subtracting some pixels
        let scrollPosition = element.offsetTop - 170;

        // Subtract more pixels if no link-image
        if (!mainPageImage && viewPortHeight >= 870) {
            scrollPosition = element.offsetTop - 300;
        }

        zenscroll.toY(scrollPosition, 600);
    }
};
