import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setSearchQuery } from './prospectusRegistryActions';
import SearchBox from '../components/search/searchBox';
import { translateLang } from '../translate';
import { LanguageContext } from '../components/LanguageContext';

class ProspectusRegistrySearchBox extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { dispatch, searchBox } = this.props;
        const language = this.context;

        return (
            <SearchBox
                initialQuery={searchBox.queryString}
                onSearch={(query, isChanged) => dispatch(setSearchQuery(query, isChanged))}                
                searchOnMount
                buttonText={translateLang('newsarchive.buttons.search', language.code)}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        searchBox: state.searchBox
    };
}

ProspectusRegistrySearchBox.propTypes = {
    searchBox: PropTypes.object
};

ProspectusRegistrySearchBox.contextType = LanguageContext;

export default connect(mapStateToProps)(ProspectusRegistrySearchBox);
