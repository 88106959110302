export function init() {
    // const getCellValue = (tr, idx) => tr.children[idx].innerText || tr.children[idx].textContent;

    const getCellValue = (tr, idx) => {
      const value = tr.children[idx].innerText || tr.children[idx].textContent;
      return value;
    }

    const comparer = (idx, asc) => (a, b) => ((v1, v2) => 
        v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ? v1 - v2 : v1.toString().localeCompare(v2)
        )(getCellValue(asc ? a : b, idx), getCellValue(asc ? b : a, idx));

    // Move th inside tbody to thead
    document.querySelectorAll('table.sortable').forEach( (table) => {
      const thead = document.createElement('THEAD');
      const tbody = table.getElementsByTagName('TBODY')[0];
      const tr = table.getElementsByTagName('TR')[0];
      
      // Insert the thead as the first element in the table
      table.insertBefore(thead, table.childNodes[0])
      tbody.removeChild(tr);
      thead.appendChild(tr);
    });

    // Append eventlistner
    document.querySelectorAll('table.sortable th.sort').forEach(th => th.addEventListener('click', ((e) => {
      const table = th.closest('table');
      const tbody = table.querySelector('tbody');

      table.querySelectorAll('.sort').forEach(sort => {
        sort.classList.remove('asc', 'desc');
      });


      if (this.asc === true) {
        th.classList.add('desc');
      } else {
        th.classList.add('asc');
      }

      Array.from(tbody.querySelectorAll('tr'))
        .sort(comparer(Array.from(th.parentNode.children).indexOf(th), this.asc = !this.asc))
        .forEach(tr => tbody.appendChild(tr) );
    })));

}