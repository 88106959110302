import { trackEvent } from '../eventTracking';
import { replaceUrlParams } from '../util/urlHelpers';
import { newsarchiveSearchStateSelector } from './selectors';

export const CHECK_TYPEFILTER = 'CHECK_TYPEFILTER';
export const CHECK_METAFILTER = 'CHECK_METAFILTER';
export const FILTERS_LOADED = 'FILTERS_LOADED';
export const SEARCH_LOADED = 'SEARCH_LOADED';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const SET_DATE_FILTERS = 'SET_DATE_FILTERS';
export const SET_SUPERVISION_CATEGORIES = 'SET_SUPERVISION_CATEGORIES';
export const LOADED_MORE = 'LOADED_MORE';
export const RESET_FILTERS = 'RESET_FILTERS';
export const SET_PAGE = 'SET_PAGE';

function searchLoaded(searchResult) {
    return {
        type: SEARCH_LOADED,
        searchResult,
    };
}

function search(currentPage) {
    const searchApi = '/api/search/nyhetsarkiv';
    
    return (dispatch, getState) =>{
        const state = getState();
        const searchState = newsarchiveSearchStateSelector(state, currentPage);
        const q = searchState.url;
        replaceUrlParams(q);

        fetch(searchApi + q).then((response) =>
            response.json().then((result) => dispatch(searchLoaded(result)))
        );
    };
}

export function checkTypeFilter(id, name) {
    return (dispatch) => {
        trackEvent('Filtrer nyhetstype', name);
        dispatch({
            type: SET_PAGE,
            page: 1
        });
        dispatch({
            type: CHECK_TYPEFILTER,
            id,
        });        
        dispatch(search(1));
    };
}

export function checkMetaFilter(id, name) {
    return (dispatch) => {
        trackEvent('Filtrer (nivå to)', name);
        dispatch({
            type: SET_PAGE,
            page: 1
        });
        dispatch({
            type: CHECK_METAFILTER,
            id,
        });        
        dispatch(search(1));
    };
}

export function filtersLoaded(filters) {
    return {
        type: FILTERS_LOADED,
        filters,
    };
}

export function setDateFilter(dateFilters) {    
    return (dispatch) => {
        trackEvent('Filtrer dato', dateFilters.name);
        dispatch({
            type: SET_PAGE,
            page: 1
        });
        dispatch({
            type: SET_DATE_FILTERS,
            dateFilters,
        });
        
        dispatch(search(1));
    };
}

export function handleSearchQueryChange(queryString) {
    return (dispatch) => {
        trackEvent('Søk', queryString);
        dispatch({
            type: SET_PAGE,
            page: 1
        });
        dispatch({
            type: SET_SEARCH_QUERY,
            queryString,
        });        
        dispatch(search());
    };
}

export function setSavedSupervisionCategories(items) {
    return (dispatch) => {
        dispatch({
            type: SET_SUPERVISION_CATEGORIES,
            activeItems: items,
        });        
        dispatch(search());
    };
}

export function loadedMore(searchResult) {
    trackEvent('Last flere', `Side ${searchResult.page}`);   
    return {
        type: LOADED_MORE,
        searchResult,
    };
}

export function loadMore(searchState, currentPage) {
    

    return (dispatch) => {
        dispatch({
            type: SET_PAGE,
            page: currentPage
        });

        dispatch(search(currentPage));

    }
}

export function fetchFilters(lang) {
    return (dispatch) =>
        fetch(`/api/search/nyhetsarkiv/filters?l=${lang}`).then((response) =>
            response.json().then((filters) => dispatch(filtersLoaded(filters)))
        );
}

export function resetFilters(){
    return (dispatch) => dispatch({
        type: RESET_FILTERS
    });
}
