import React, { FC, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LanguageContext } from '../../components/LanguageContext';
import { Paginator } from '../../components/search/Paginator';
import { window } from '../../util/ssrBase';
import { VregRootState } from '../Search/vregSearchStore';
import { vregStandardReportActions } from '../Services/VregStandardReportState';

export const StandardReportPaginator : FC = () => {
    const languageContext = useContext(LanguageContext);
    const dispatch = useDispatch();
    const pageNumber = useSelector((state: VregRootState) => state.vregStandardReportState.pageNumber);
    const totalPages = useSelector((state: VregRootState) => state.vregStandardReportState.totalPages); 
    
    return(
        <Paginator
        language={languageContext.code}
        onLoadMoreClickNext={()  => {
            dispatch(vregStandardReportActions.incrementPage());
            window.scrollTo(0, 0);
        }}
        onLoadMoreClickPrev={()  => {
            dispatch(vregStandardReportActions.decrementPage());
            window.scrollTo(0, 0);
        }}
        onJumpToPageClick={(page: number) => {
            dispatch(vregStandardReportActions.setPage(page));
            window.scrollTo(0,0);
        }}
        searchResultPage={pageNumber}
        searchResultTotalPages={totalPages}
    />   
    )
}