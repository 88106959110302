import React, { FC } from 'react';
import { GroupedBorderCrossingActivityViewModel } from "../../generatedTypes/FT.Web.ViewModels.Vreg";

interface IGroupedBorderCrossingListProps {
    CrossBorderActivities: GroupedBorderCrossingActivityViewModel[]
    Heading: string,
    ListCountries: boolean
}

export const GroupedBorderCrossingList: FC<IGroupedBorderCrossingListProps> = (props) =>
    <div className="mb-6" >
        <h3 className=" text-xl font-semibold mb-2" >
            {props.Heading}
        </h3>
        
        {props.CrossBorderActivities.map(group =>
            <div className="mb-6" key={group.groupName} >
                <p className="mb-3" >
                    <strong>
                        {group.groupName}
                    </strong>
                </p>
                <ul className=" list-inside ml-5" >
                    {
                        group.services.map(service =>
                            <li className="mb-3" key={service.serviceName} >
                                {service.serviceName}

                                {props.ListCountries &&
                                <ol className=" list-outside list-dis md:ml-8 lg:ml-8 mt-3 md:w-8/12 lg:w-8/12 lg:columns-4 md:columns-2" >
                                   {
                                       service.borderCrossings.map(crossing =>
                                           <li key={crossing.hostCountry + service.serviceName + group.groupName} >
                                               {crossing.hostCountry}
                                           </li>
                                       )
                                   }
                                </ol>
                                }
                                
                            </li>
                        )
                    }
                </ul>
            </div>
        )
        }
    </div>
