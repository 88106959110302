import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setSearchQuery } from './pillar2Actions';
import SearchBox from '../components/search/searchBox';
import { translateLang } from '../translate';
import { LanguageContext } from '../components/LanguageContext';

class Pillar2SearchBox extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { dispatch, searchBox } = this.props;
        return (
            <SearchBox
                initialQuery={searchBox.queryString}
                onSearch={(query, isChanged) => dispatch(setSearchQuery(query, isChanged))}
                searchOnMount
                buttonText={translateLang('newsarchive.buttons.search', this.context.code)}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        searchBox: state.searchBox
    };
}

Pillar2SearchBox.propTypes = {
    searchBox: PropTypes.object
};

Pillar2SearchBox.contextType = LanguageContext;

export default connect(mapStateToProps)(Pillar2SearchBox);
