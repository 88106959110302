import React from 'react';
import { translateLang } from '../translate';

interface NoHitsProps {
    language: string
}

export const NoHits: React.FC<NoHitsProps> = (props) => (
    <main className="bg-white-400 p-6">
        <h2 className="text-xl mb-4">{translateLang('globalsearch.nohits.header', props.language)}</h2>
        <p className="mb-2.5">
            {translateLang('globalsearch.nohits.searchtips', props.language)}
        </p>
        <ul className="list-disc list-inside mb-2.5">
            <li>{translateLang('globalsearch.nohits.spelling', props.language)}</li>
            <li>{translateLang('globalsearch.nohits.formulation', props.language)}</li>
            <li>{translateLang('globalsearch.nohits.abbreviation', props.language)}</li>
            <li>{translateLang('globalsearch.nohits.registers', props.language)}</li>
        </ul>
        <span className="">
            {translateLang('globalsearch.nohits.menu', props.language)}
        </span>
    </main>
);
