import React,{ FC, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useDebouncedCallback } from "use-debounce";
import { vregSearchStateSlice } from "../Services/VregSearchState";
import { LanguageContext } from "../../components/LanguageContext";
import { translateLang } from "../../translate";
import { VregRootState } from "./vregSearchStore";

export const VregSearchInput: FC = () => {
    
    const dispatch = useDispatch();
    const languageContext = useContext(LanguageContext);
    const state = useSelector((s: VregRootState) => s.vregSearchState); 
    const [query, setQuery] = useState(state.query);

    const callback =(v : string)=> {
        dispatch(vregSearchStateSlice.actions.setQuery(v))
    }
   
    const debouncedCallback = useDebouncedCallback(callback, 500);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onChange=(v : any) => {
        const newValue =  v.currentTarget.value;
        setQuery(newValue)
        debouncedCallback(newValue);
    };
    
    return (
        <form className="mb-6 px-6 lg:px-0" onSubmit={(e) => e.preventDefault()} >
            <fieldset>
                <legend className="sr-only">
                    {translateLang('vreg.search.legend', languageContext.code)}                    
                </legend>
                    
                <div className="flex">
                    <label htmlFor="vreg_search_input" className="sr-only">
                        {translateLang('vreg.search.placeholder', languageContext.code)}
                    </label>
                    <input
                        type="search"
                        id="vreg_search_input"
                        className="w-full placeholder:text-black-300"
                        onChange={onChange}
                        value={query}
                        placeholder={translateLang('vreg.search.placeholder', languageContext.code)}/>
                        
                    <button type="button" onClick={(e) => { e.preventDefault();}} className="btn btn--primary px-6">
                    {translateLang('vreg.search.searchButtonText', languageContext.code)}
                    </button>
                </div>
            </fieldset>
        </form>)
}