import React from 'react';
import { TableCell, TableColumnWithUrl } from '../../generatedTypes/FT.EpiServer.Models.Table';
import { TableBlockViewModel } from '../../generatedTypes/FT.Web.ViewModels.Blocks';
import { Tbody } from './tbody';
import { Thead } from './thead';

interface State {
    blockModel: TableBlockViewModel | null,
    sorting: {
        index: number,
        reverse: boolean
    }
}

interface Props {
    blockModel: TableBlockViewModel | null
}

export class TableBlock extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            blockModel: props.blockModel,
            sorting: {
                index: 0,
                reverse: false
            }
        }
    }

    isNumeric = (value: string): boolean => (
        !Number.isNaN(Number(value))
    )

    sortByIndex = (selectedIndex: number): void => {
        if (this.state.sorting.index !== selectedIndex) {
            this.setState({
                sorting: {
                    index: selectedIndex,
                    reverse: false
                }
            });
        } else {
            this.setState(previousState => ({ sorting: { index: previousState.sorting.index, reverse: !previousState.sorting.reverse } }));
        }
    }

    createRowHeading = (columns: TableColumnWithUrl[]): string[] => {
        const headingsRow: string[] = [];
        for (let i = 0; i < columns.length; i += 1) {
            headingsRow.push(columns[i].heading);
        }
        return headingsRow;
    }

    createRowData = (columns: TableColumnWithUrl[], maxRows: number): TableCell[][] => {
        const rows: TableCell[][] = [];
        let rowCount = 0;
        let maxRowCount = 0;
        for (let i = 0; i < columns.length; i += 1) {
            const column = columns[i];
            if (column.values.length > maxRowCount) {
                maxRowCount = column.values.length;
            }
        }

        while (rowCount < maxRowCount
            && rowCount < maxRows) {
            const rowData: TableCell[] = [];
            for (let i = 0; i < columns.length; i += 1) {
                const col = columns[i];
                const value = col.values[rowCount] ? col.values[rowCount] : { value: '', url: '' };
                rowData.push(value);
            }
            rows.push(rowData);
            rowCount += 1;
        }
        return rows;
    }

    createSumRow = (rows: TableCell[][]): TableCell[] => {
        const result: TableCell[] = [];
        for (let columnIndex = 0; columnIndex < rows[0].length; columnIndex += 1) {
            const arr: number[] = [];
            for (let currentRow = 0; currentRow < rows.length; currentRow += 1) {
                const value = rows[currentRow][columnIndex];
                if (this.isNumeric(value.value)) {
                    if (arr[currentRow]) {
                        arr[currentRow] += parseFloat(value.value);
                    } else {
                        arr.push(parseFloat(value.value));
                    }
                }
            }
            if (arr.length === 0) {
                result.push({ value: this.state.blockModel ? this.state.blockModel.sumHeading : '', url: '' });
            } else {
                result.push({ value: arr.reduce(this.reducer).toFixed(1), url: '' });
            }
        }
        return result;
    }

    reducer = (accumulator: number, currentValue: number): number => (accumulator + currentValue)

    render(): JSX.Element {
        if (this.state.blockModel) {
            const headingsRow = this.createRowHeading(this.state.blockModel.tableData.columns);
            const rows = this.createRowData(this.state.blockModel.tableData.columns, this.state.blockModel.maxRows);
            rows.sort((a, b) => a[this.state.sorting.index].value.localeCompare(b[this.state.sorting.index].value));

            if (this.state.sorting.reverse) {
                rows.reverse();
            }

            if (this.state.blockModel.addSumRow) {
                rows.push(this.createSumRow(rows));
            }

            return (
                <div
                    className={`article--bodytext__responsivetable overflow-auto
                        ${this.state.blockModel.fullWidth ? ' w-full' : ''}
                    `}
                >
                    <section className="c_table-section" aria-label={`table  ${this.state.blockModel.tableData.tableHeading}`}>
                        <h3 className={`
                            ${this.state.blockModel.tableData && this.state.blockModel.tableData.hideTableHeading ? 'u_visuallyhidden' : ''}
                        `}
                        >
                            {this.state.blockModel.tableData.tableHeading}
                        </h3>
                        <table
                            className={`table-auto ${this.state.blockModel.fadeDiagonal ? ' fadeDiagonal' : ''}
                        `}
                        >
                            <caption className="u_visuallyhidden">
                                {this.state.blockModel.tableData.tableHeading}
                            </caption>
                            <Thead
                                array={headingsRow}
                                sorting={this.state.blockModel.sortable ? {
                                    index: this.state.sorting.index,
                                    setIndex: (index: number) => this.sortByIndex(index),
                                    reverse: this.state.sorting.reverse
                                } : null}
                                sortable={this.state.blockModel.sortable}
                                rightAlignContent={this.state.blockModel.rightAlignContent}
                                tableIsFixed={false}
                            />
                            <Tbody
                                array={rows}
                                sorting={this.state.blockModel.sortable ? {
                                    index: this.state.sorting.index,
                                    setIndex: (index: number) => this.sortByIndex(index),
                                    reverse: this.state.sorting.reverse
                                } : null}
                                noFirstCol={this.state.blockModel.noFirstCol}
                                rightAlignContent={this.state.blockModel.rightAlignContent}
                                tableIsFixed={false}
                                bodyRef=''
                            />
                        </table>
                        {this.state.blockModel.reference &&
                            <div className="c_table-reference">
                                Kilde: {this.state.blockModel.reference}
                            </div>
                        }
                        {this.state.blockModel.explanation &&
                            <div className="c_table-explanation">
                                {this.state.blockModel.explanation}
                            </div>
                        }
                    </section>

                </div>
            )
        }
        return (<div />)
    }
}
