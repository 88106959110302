import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useDebouncedCallback } from 'use-debounce';
import { translateLang } from '../../translate';

interface PaginatorProps {
    language: string;
    onLoadMoreClickPrev: () => void;
    onLoadMoreClickNext: () => void;
    onJumpToPageClick: (page: number) => void;
    searchResultPage: number;
    searchResultTotalPages: number;
}

const MOBILE_BREAKPOINT = 640;
const MAX_VISIBLE_PAGES = 7; // Maximum number of pages to display before adding ellipsis

// Inspired by the Terminator movies with Ahnold Schwarzenegger
export const Paginator: React.FC<PaginatorProps> = ({
    language,
    searchResultPage,
    searchResultTotalPages,
    onJumpToPageClick,
    onLoadMoreClickPrev,
    onLoadMoreClickNext,
}) => {
    const [isMobile, setIsMobile] = useState<boolean>(false);

    const debouncedHandleResize = useDebouncedCallback(() => {
        setIsMobile(window.innerWidth < MOBILE_BREAKPOINT);
    }, 200);

    useEffect(() => {
        debouncedHandleResize();

        window.addEventListener('resize', debouncedHandleResize);
        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
            debouncedHandleResize.cancel();
        };
    }, [debouncedHandleResize]);

    // Boundaries for when to show ellipsis on left/right side of the pagination buttons
    const ELLIPSIS_BOUNDARIES = useMemo(
        () => ({
            start: isMobile ? 3 : 4,
            end: isMobile ? searchResultTotalPages - 2 : searchResultTotalPages - 3,
        }),
        [isMobile, searchResultTotalPages]
    );

    // Number of pages to display around the current page
    const PAGE_RANGE = useMemo(
        () => ({
            start: searchResultPage - (isMobile ? 1 : 2),
            end: searchResultPage + (isMobile ? 1 : 2),
        }),
        [searchResultPage, isMobile]
    );

    if (searchResultTotalPages === 0) return null;

    /**
     * Renders pagination buttons based on the current page and total pages.
     * Handles different logic for mobile and desktop.
     */
    const renderTargets = () => {
        if (searchResultTotalPages <= MAX_VISIBLE_PAGES) {
            return createRange(1, searchResultTotalPages);
        }

        const currentTargets = [];

        currentTargets.push(createTargetButton(1));

        if (searchResultPage <= ELLIPSIS_BOUNDARIES.start) {
            /*
             * Current page is within the start boundaries:
             * - Mobile: Page 1-3 will show the first 4 pages, with ellipsis to the right.
             * - Desktop: Page 1-3 will show the first 5 pages, page 4 will show the first 6 pages, both with ellipsis to the right.
             */
            currentTargets.push(
                ...createRange(
                    2,
                    searchResultPage < ELLIPSIS_BOUNDARIES.start
                        ? ELLIPSIS_BOUNDARIES.start + 1
                        : PAGE_RANGE.end
                )
            );
            currentTargets.push(createTargetButton(searchResultTotalPages));
        } else if (
            searchResultPage > ELLIPSIS_BOUNDARIES.start &&
            searchResultPage < ELLIPSIS_BOUNDARIES.end
        ) {
            /**
             * Current page is between start and end boundaries:
             * - Mobile: Will show the current page ±1, with ellipses on both sides.
             * - Desktop: Will show the current page ±2, with ellipses on both sides.
             */
            currentTargets.push(...createRange(PAGE_RANGE.start, PAGE_RANGE.end));
            currentTargets.push(createTargetButton(searchResultTotalPages));
        } else if (searchResultPage >= ELLIPSIS_BOUNDARIES.end) {
            /*
             * Current page is within the end boundaries:
             * - Mobile: 3rd last page-last page will show the last 4 pages, with ellipsis to the left.
             * - Desktop: 4th last page will show the last 6 pages, 3rd last page-last page will show the last 5 pages, both with ellipsis to the left.
             */
            currentTargets.push(
                ...createRange(
                    searchResultPage > ELLIPSIS_BOUNDARIES.end
                        ? ELLIPSIS_BOUNDARIES.end - 1
                        : PAGE_RANGE.start,
                    searchResultTotalPages
                )
            );
        }

        return currentTargets;
    };

    // Creates a sequence of pagination buttons, adding ellipses only when start/end values indicate skipped pages
    const createRange = (start: number, end: number) => {
        const leftEllipsis =
            searchResultTotalPages > MAX_VISIBLE_PAGES &&
            searchResultPage > ELLIPSIS_BOUNDARIES.start;
        const rightEllipsis =
            searchResultTotalPages > MAX_VISIBLE_PAGES &&
            searchResultPage < ELLIPSIS_BOUNDARIES.end;

        const range = [];

        if (leftEllipsis) {
            range.push(
                <span key="ellipsis-left" className="w-fit md:p-1" aria-hidden="true">
                    ...
                </span>
            );
        }

        for (let i = start; i <= end; i += 1) {
            range.push(createTargetButton(i));
        }

        if (rightEllipsis) {
            range.push(
                <span key="ellipsis-right" className="w-fit md:p-1" aria-hidden="true">
                    ...
                </span>
            );
        }

        return range;
    };

    // Creates pagination button/span
    const createTargetButton = (page: number) => {
        const isCurrent = page === searchResultPage;
        const targetStyle = classNames(
            isCurrent ? 'btn--pagination__current' : 'btn--pagination',
            searchResultTotalPages <= MAX_VISIBLE_PAGES
                ? 'btn--pagination__narrow'
                : 'btn--pagination__full'
        );

        return isCurrent ? (
            <span className={targetStyle} key={page} aria-current="page">
                {page}
            </span>
        ) : (
            <button
                type="button"
                className={targetStyle}
                onClick={() => onJumpToPageClick(page)}
                key={page}
                aria-label={translateLang('globalsearch.buttons.goToPage', language, [page])}
            >
                <span>{page}</span>
            </button>
        );
    };

    return (
        <nav
            aria-label="Pagination"
            className="flex flex-row items-center justify-center gap-2 mt-6 mb-24 sm:gap-4 lg:gap-6"
        >
            <span className="sr-only" aria-live="polite">
                {translateLang('globalsearch.texts.currentPage', language, [
                    searchResultPage,
                    searchResultTotalPages,
                ])}
            </span>
            <button
                type="button"
                disabled={searchResultPage <= 1}
                className="btn p-2 flex gap-1.5 focus:bg-current no-underline items-center leading-tight md:p-2.5 btn--basic"
                onClick={() => {
                    onLoadMoreClickPrev();
                }}
                aria-label={`${translateLang('search.buttons.prev', language)} ${translateLang(
                    'globalsearch.texts.page',
                    language
                ).toLowerCase()}`}
            >
                <span className="text-base material-icons" aria-hidden="true">fast_rewind</span>
                <span className="hidden md:inline">
                    {translateLang('search.buttons.prev', language)}
                </span>
            </button>
            <div
                className={`flex gap-1 items-center ${
                    searchResultTotalPages <= MAX_VISIBLE_PAGES
                        ? 'justify-center w-auto'
                        : 'justify-between w-full'
                }`}
            >
                {renderTargets()}
            </div>
            <button
                type="button"
                disabled={searchResultPage >= searchResultTotalPages}
                className="btn p-2 flex gap-1.5 focus:bg-current no-underline leading-tight items-center md:p-2.5 btn--basic"
                onClick={() => {
                    onLoadMoreClickNext();
                }}
                aria-label={`${translateLang('search.buttons.next', language)} ${translateLang(
                    'globalsearch.texts.page',
                    language
                ).toLowerCase()}`}
            >
                <span className="hidden md:inline">
                    {translateLang('search.buttons.next', language)}
                </span>
                <span className="text-base material-icons" aria-hidden="true">fast_forward</span>
            </button>
        </nav>
    );
};
