import { AnyAction, configureStore, Store } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { supervisionReducer } from '../components/subjectSelector/supervisionReducer';

export const store = 
    configureStore(
        {
            reducer: supervisionReducer
        }
    );

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof supervisionReducer>
export type AppDispatch = typeof store.dispatch

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

// 3. Create a type for store using RootState and Thunk enabled dispatch
export type AppStore = Omit<Store<RootState, AnyAction>, "dispatch"> & {
  dispatch: AppThunkDispatch;
};

export const useAppDispatch: AppThunkDispatch = () => useDispatch<AppThunkDispatch>();
