import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { trackEvent } from '../eventTracking';
import { SearchResult } from '../generatedTypes/FT.Web.Search.Models';
import { Store } from '.';
import { Actions } from './data/actions';
import { SearchRequestState } from './data/reducer';
import { NoHits } from './noHits';
import { QueryParams } from './searchBox';
import { SearchPageSearchResults } from './searchResults';
import { SearchResultHeader } from './searchResultHeader';
import { window } from '../util/ssrBase';
import { Paginator } from '../components/search/Paginator';
import { SkeletonLoader } from '../components/SkeletonLoader';
import { LanguageContext } from '../components/LanguageContext';

interface ConnectedProps {
    searchResult?: SearchResult;
    isLoadingSearchResults: boolean;
    queryParams: QueryParams;
    currentPage: number;
}

type ConnectedDispatch = ReturnType<typeof mapDispatchToProps>;

type Props = ConnectedProps & ConnectedDispatch;

const SearchPageSearchComponent: React.FC<Props> = (props) => {
    const language = useContext(LanguageContext).code;
    const onSort = (sortByDate: boolean) => {
        if (sortByDate !== props.queryParams.sortByDate) {
            trackEvent('Sortering valgt', sortByDate ? 'Dato' : 'Relevans');
            props.queryParamsChanged({
                queryParams: { ...props.queryParams, sortByDate },
                lang: language,
                page: props.currentPage,
                isChanged: true,
            });
        }
    };

    if (!props.isLoadingSearchResults && !props.searchResult?.hasHits) {
        trackEvent('Søk - ingen treff', props.queryParams.queryString);
    }


    const canLoadMore = props.searchResult
        && (props.searchResult.total >= props.searchResult.hits.length && !props.isLoadingSearchResults);

    const currentPage = props.searchResult?.page || 1;

    if(props.isLoadingSearchResults){
        return <SkeletonLoader type='list' />
    }

    return (
        <div>
            <>
                <SearchResultHeader
                    onSort={onSort}
                    sortByDate={props.queryParams.sortByDate}
                    searchResult={props.searchResult}
                />

                {props.searchResult && props.searchResult.hits.length > 0 ? (
                    <>
                        <SearchPageSearchResults
                            searchResult={props.searchResult}                            
                        />

                        {canLoadMore && (
                            <Paginator
                                onLoadMoreClickPrev={() => {
                                    trackEvent('Forrige side søketreff', `Side ${currentPage}`);
                                    props.loadMore(props.queryParams, language, currentPage - 1);
                                    props.queryParamsChanged({
                                        queryParams: { ...props.queryParams, page: currentPage-1 },
                                        lang: language,
                                        page: props.currentPage-1,
                                        isChanged: true,
                                    });
                                    window.scrollTo(0, 0);
                                }}
                                onLoadMoreClickNext={() => {
                                    trackEvent('Neste side søketreff', `Side ${currentPage}`);
                                    props.loadMore(props.queryParams, language, currentPage + 1);
                                    props.queryParamsChanged({
                                        queryParams: { ...props.queryParams, page: currentPage+1 },
                                        lang: language,
                                        page: props.currentPage+1,
                                        isChanged: true,
                                    });
                                    window.scrollTo(0, 0);
                                }}
                                onJumpToPageClick={(page: number) => {
                                    trackEvent('Hopp til side søketreff', `Side ${page}}`);
                                    props.loadMore(props.queryParams, language, page);
                                    props.queryParamsChanged({
                                        queryParams: { ...props.queryParams, page },
                                        lang: language,
                                        page,
                                        isChanged: true,
                                    });
                                    window.scrollTo(0, 0);
                                }}
                                language={language}
                                searchResultPage={currentPage}
                                searchResultTotalPages={props.searchResult.totalPages}
                            />
                        )}
                    </>)
                    :
                    <NoHits language={language} />
                }
            </>
        </div>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadMore: (queryParams: QueryParams, lang: string, page: number) =>
        dispatch(Actions.loadMore.request({ queryParams, lang, page })),
    queryParamsChanged: (searchRequestState: SearchRequestState) => {
        dispatch(Actions.queryParamsChanged.success(searchRequestState));
    },
});

const mapStateToProps = (state: Store): ConnectedProps => ({
    searchResult: state.searchResult.searchResult,
    isLoadingSearchResults: state.searchResult.loading,
    queryParams: state.searchBox.queryParams,
    currentPage: state.searchBox.page,
});

export const GlobalSearchPageComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchPageSearchComponent);
