import { combineReducers } from 'redux';
import {
    FILTERS_LOADED,
    LOADED_MORE,
    SEARCH_LOADED,
    SELECT_DOCUMENTTYPE,    
    SET_SEARCH_QUERY,
} from './prospectusRegistryActions';
import { getLocale } from '../translate';
import { setSessionStorageResult } from '../util/persistScroll';

const initialSettingsState = {
    lang: getLocale(),
};

const initialDocumentTypeFiltersState = {
    loaded: false,
    activeFilter: -1,
    items: [],
};

const initialSearchBoxState = {
    queryString: '',
};

const initialSearchResultState = {
    loading: true,
    items: [],
};

export const initialState = {
    searchResult: initialSearchResultState,
    searchBox: initialSearchBoxState,
    settings: initialSettingsState,    
    documentTypeFilters: initialDocumentTypeFiltersState,
};

const settings = (state = initialSettingsState) => state;

const documentTypeFilters = (state = initialDocumentTypeFiltersState, action) => {
    switch (action.type) {
        case SELECT_DOCUMENTTYPE:
            return { ...state, activeFilter: action.id };
        case FILTERS_LOADED: {
            return {
                ...state,
                loaded: true,
                items: action.filters.documentTypes.map((item, index) => ({
                    ...item,
                    id: index,
                    name: item.value,
                    selected: state.activeFilter === item.key,
                })),
            };
        }
        default:
            return state;
    }
};

const searchBox = (state = initialSearchBoxState, action) => {
    switch (action.type) {
        case SET_SEARCH_QUERY:
            return { ...state, queryString: action.queryString };
        default:
            return state;
    }
};

const searchResult = (state = initialSearchResultState, action) => {
    switch (action.type) {
        case SEARCH_LOADED:
            return {
                ...state,
                loading: false,
                items: action.searchResult.hits,
                page: action.searchResult.page,
                total: action.searchResult.totalHits,
                pageSize: action.searchResult.pageSize,
                totalPages: action.searchResult.totalPages
            };
        case LOADED_MORE: {
            const obj = action.searchResult;
            if (state.items) {
                obj.hits = action.searchResult.hits;
            }
            setSessionStorageResult('prospectusResult', obj);

            return {
                ...state,
                loading: false,
                items: action.searchResult.hits,
                page: action.searchResult.page,
                total: action.searchResult.totalHits,
                pageSize: action.searchResult.pageSize,
                totalPages: action.searchResult.totalPages
            };
        }
        default:
            return state;
    }
};

const prospectusRegistryReducer = combineReducers({
    searchResult,
    searchBox,
    settings,    
    documentTypeFilters,
});

export default prospectusRegistryReducer;
