import React from 'react';
import { Provider } from 'react-redux';
import { Action, applyMiddleware, createStore } from 'redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import {
    queryParamsChanged as queryParamsChangedEpic,
    search as globalSearchEpic,
    suggest as suggestEpic,
} from '../searchpage/data/epics';
import { globalSearchReducer, initialState } from '../searchpage/data/reducer';
import { setScrollPosition } from '../util/persistScroll';
import { FrontPageSearchBox } from './searchBox';
import { getLanguage, LanguageContext } from '../components/LanguageContext';

export const NAME = 'frontpage';

export type Store = ReturnType<typeof globalSearchReducer>;


interface FrontPageSearchProps {
    lang: string;
    searchPageUrl: string;
}


/* eslint-disable */
interface FrontPageState {
    store: any // ugly but I'm not able to decipher the correct type for this
}
/* eslint-enable */

export class FrontPageSearch extends React.Component<FrontPageSearchProps, FrontPageState> {

    epicMiddleware = createEpicMiddleware<Action, Action, Store>();

    constructor(props: FrontPageSearchProps) {
        super(props);
        setScrollPosition(NAME);

        this.state = {
            store: createStore(globalSearchReducer, initialState, applyMiddleware(this.epicMiddleware))
        }
        this.epicMiddleware.run(
            combineEpics(globalSearchEpic, queryParamsChangedEpic, suggestEpic)
        );
    }

    render(): JSX.Element {
        return (
            <Provider store={this.state.store}>
                <LanguageContext.Provider value={getLanguage(this.props.lang)}>
                    <FrontPageSearchBox searchPageUrl={this.props.searchPageUrl} />
                </LanguageContext.Provider>
            </Provider>
        );
    }
} 
