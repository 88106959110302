import React, { useState } from 'react';
import { trackEvent } from '../../eventTracking';
import { translateLang } from '../../translate';
import { submitFeedback } from './feedbackApi';

interface FeedbackProps {
    language: string
}

export const FeedbackComponent: React.FC<FeedbackProps> = (props): JSX.Element => {
    
    const [expanded, setExpanded] = useState(false);
    const [message, setMessage] = useState('');
    const [feedbackSuccess, setFeedbackSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    async function submitErrorMessage() {
        setErrorMessage('');
        const result = await submitFeedback(message, props.language);
        if(result !== "success")
            setErrorMessage(result);
        else
            setFeedbackSuccess(true);
    }

    function createContactUsHtml() {
        return { __html: translateLang('feedback.texts.contactUs', props.language) };
    }

    return (
        <div
            className={`feedback-container ${expanded ? ' expanded' : ''}`}
            role="region"
            aria-labelledby="feedbackHeader"
        >
            <h2 className='text-lg lg:text-xl font-semibold mb-6 feedbackComponent' id="feedbackHeader">
                {translateLang('feedback.headers.mainHeader', props.language)}
            </h2>

            {!expanded && (
                <button
                    type='button'
                    className="btn btn--secondary"
                    onClick={() => {
                        trackEvent(
                            'Send tilbakemelding-klikk'
                        );
                        setExpanded(true);
                    }
                    }
                >
                    {translateLang('feedback.buttons.feedback', props.language)}
                </button>
            )}

            {expanded && !feedbackSuccess && (
                <form className='w-full px-6 text-left'>
                    <fieldset>
                        <legend className="sr-only">{translateLang('feedback.buttons.feedback', props.language)}</legend>
                        <label className="sr-only" htmlFor="feedbacktextarea">
                            {translateLang('feedback.texts.feedbackTextAreaLabel', props.language)}
                        </label>
                        <textarea
                            id="feedbacktextarea"
                            className={`border border-bluegreen-500 mb-4 w-full ${expanded ? '' : 'hidden'}`}
                            aria-label={translateLang('feedback.texts.label', props.language)}
                            placeholder={translateLang('feedback.texts.feedbackPlaceholder', props.language)}
                            onChange={(event) => { setMessage(event?.target.value) }}
                        />

                        <p className="text-base mb-4" dangerouslySetInnerHTML={createContactUsHtml()} />
                    </fieldset>
                    <button
                        type='button'
                        className={`btn btn--primary ${expanded ? '' : 'hidden'}`}
                        onClick={() => {
                            submitErrorMessage();
                            trackEvent(
                                'Send tilbakemelding-klikk'
                            );
                        }}
                    >
                        {translateLang('feedback.buttons.submit', props.language)}
                    </button>
                    {errorMessage.length > 0 && (
                        <p role="alert" className="mt-3 text-magenta-400 text-sm">{errorMessage}</p>
                    )}
                </form>
            )}

            {feedbackSuccess && (
                <h3 className='feedbackComponent'>{translateLang('feedback.texts.responseText', props.language)}</h3>
            )}

        </div>
    );

}
