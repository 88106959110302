import { ActionType, createAsyncAction } from 'typesafe-actions';
import { SearchResult, SearchResultSuggestion } from '../../generatedTypes/FT.Web.Search.Models';
import { GlobalSearchFilter } from '../../generatedTypes/FT.Web.Search.Models/globalSearchFilter';
import { SearchRequestState, SuggestSearchRequestState } from './reducer';

export const Actions = {
    loadMore: createAsyncAction('LOAD_MORE_REQUESTED', 'LOAD_MORE_SUCCEDED', 'LOAD_MORE_FAILED')<
        SearchRequestState,
        SearchResult,
        undefined
    >(),
    fetchSearchResults: createAsyncAction(
        'FETCH_SEARCH_RESULT_REQUESTED',
        'FETCH_SEARCH_RESULT_SUCCEEDED',
        'FETCH_SEARCH_RESULT_FAILED'
    )<SearchRequestState, SearchResult, undefined>(),
    fetchFilters: createAsyncAction(
        'FETCH_FILTERS_REQUESTED',
        'FETCH_FILTERS_SUCCEEDED',
        'FETCH_FILTERS_FAILED'
    )<string, GlobalSearchFilter[], undefined>(),
    queryParamsChanged: createAsyncAction(
        'QUERY_PARAMS_CHANGED_REQUESTED',
        'QUERY_PARAMS_CHANGED_SUCCEEDED',
        'QUERY_PARAMS_CHANGED_FAILED'
    )<undefined, SearchRequestState, undefined>(),
    fetchSuggestedResult: createAsyncAction(
        'FETCH_SUGGESTED_RESULT_REQUESTED',
        'FETCH_SUGGESTED_RESULT_SUCCEEDED',
        'FETCH_SUGGESTED_RESULT_FAILED'
    )<SuggestSearchRequestState, SearchResultSuggestion[], undefined>()
};

export type Actions = ActionType<typeof Actions>;
