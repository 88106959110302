import React, { FC, useContext, useState } from 'react';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { translateLang } from '../../../translate';
import { LanguageContext } from '../../../components/LanguageContext';
import { VregRootState } from '../../Search/vregSearchStore';
import { ServiceGroupDto } from '../../../generatedTypes/FT.Web.Controllers.Api.Models.Virkereg';
import { CheckedChanged, MultiCheckedChanged } from '../../Services/CheckedChanged';


interface CheckboxListProps {
    serviceGroup: ServiceGroupDto;
    onClickAction: ActionCreatorWithPayload<CheckedChanged, string>;
    onSelectAllAction: ActionCreatorWithPayload<MultiCheckedChanged, string>
}

export const GroupedCheckboxList: FC<CheckboxListProps> = (
    props: CheckboxListProps
) => {
    const [open, setOpen] = useState(true);
    const languageContext = useContext(LanguageContext);
    const dispatch = useDispatch();
    const selectedTypes = useSelector(
         (state: VregRootState) => state.vregUserReportState.services
    );

    return (
        <section className="py-2.5"> 
            <button
                type="button"
                onClick={() => setOpen(!open)}
                className="flex justify-between w-full text-sm font-semibold text-left hover:text-bluegreen-500"
            >
                {props.serviceGroup.groupName}
                {open && (
                    <span className="icon icon--close font-normal" aria-hidden="false">
                        {translateLang('vreg.buttons.close', languageContext.code)}
                    </span>
                )}
                {!open && (
                    <span className="icon icon--open font-normal" aria-hidden="true" />
                )}                
            </button>
            {open &&                      
                <div className="flex flex-col border-t border-black-200 mt-2 pt-2">
                    <div className="checkbox--custom checkbox--custom__small">
                        <input
                            type="checkbox"
                            id={`all_${props.serviceGroup.id}`}
                            onClick={(e) => dispatch(
                                props.onSelectAllAction({
                                    ids: props.serviceGroup.services.map(f => ({ id: f.id.toString(), name: f.name} as CheckedChanged) ),
                                    checked: e.currentTarget.checked
                                })
                            )}
                        />
                        <label htmlFor={`all_${props.serviceGroup.id}`}>
                            {translateLang('vreg.buttons.selectAll', languageContext.code)}
                        </label>
                    </div>
                    {props.serviceGroup.services.map((service) => (
                        <div
                            className="checkbox--custom checkbox--custom__small"
                            key={`${props.serviceGroup.id}_${service.id}`}
                        >
                            <input
                                type="checkbox"                                
                                id={`${props.serviceGroup.id}_${service.id}`}
                                onChange={() =>                                    
                                    dispatch(
                                        props.onClickAction({
                                            id: service.id.toString(),
                                            name: service.name,
                                        } as CheckedChanged)
                                    )
                                }
                                checked={selectedTypes.findIndex((t) => t === service.id.toString()) > -1}
                            />
                            <label htmlFor={`${props.serviceGroup.id}_${service.id}`}>
                                {service.name}
                            </label>
                        </div>
                    ))}
                </div>
            }
        </section>
    );
};
