export const trackPageView = () => {
    // GA4 automatically tracks page views

    if (location.pathname.toLowerCase().match('episerver')) {
        return;
    }

    const varslingRegexp = new RegExp('^/om-finanstilsynet/varsling-til-finanstilsynet/?$', 'i');
    if (varslingRegexp.test(location.pathname)) {
        return;
    }

    try {
        // SiteImprove
        _sz?.push([
            'trackdynamic',
            {
                url: location.href,
                ref: document.referrer,
                title: document.title,
            },
        ]);
    } catch (e) {
        // Can i.e be tracker blocking. Do nothing.
    }
};
