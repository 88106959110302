import { SCROLLSPY_CLASSNAME } from './scrollspy';

export function registerCollapsibleClickEvents() {
    const containersObject = document.getElementsByClassName('js-collapsible-content-container');

    const containers = Object.keys(containersObject).map((key) => containersObject[key]);

    containers.forEach((node) => {
        const heading = node.querySelector('.collapsible-content-header');
        const collapsableElement = node.querySelector('.js-collapsible-content');

        const container = collapsableElement.closest(`.${SCROLLSPY_CLASSNAME}`);
        const hasScrollSpy = container && container.id;
        const isCollapsed = collapsableElement.classList.contains('hidden');

        if (isCollapsed && hasScrollSpy && location.hash === `#${container.id}`) {
            collapsableElement.classList.toggle('hidden');
        }

        heading.onclick = () => {
            collapsableElement.classList.toggle('hidden');
            const isCollapsed = collapsableElement.classList.contains('hidden');
            if (hasScrollSpy && isCollapsed) {
                history.replaceState(null, null, `#${container.id}`);
            }

            if (isCollapsed) {
                heading.classList.replace('icon--expand__less', 'icon--expand__more');
            } else {
                heading.classList.replace('icon--expand__more', 'icon--expand__less');
            }
        };
    });
}
