import PropTypes from 'prop-types';
import React, {useContext } from 'react';
import { connect } from 'react-redux';
import { LanguageContext } from '../components/LanguageContext';
import SearchBox from '../components/search/searchBox';
import { translateLang } from '../translate';
import { handleSearchQueryChange } from './actions';

const NewsArchiveSearchBox = ({ dispatch, searchBox }) =>{    
    const language = useContext(LanguageContext);    

    return (
    <SearchBox
        initialQuery={searchBox.queryString}
        onSearch={(query) => dispatch(handleSearchQueryChange(query))}
        placeholderText={translateLang('newsarchive.texts.inputPlaceholder', language.code)}
        buttonText={translateLang('newsarchive.buttons.search', language.code)}
        language={language.code}
        searchOnMount
    />

)};

function mapStateToProps(state) {
    return {
        searchBox: state.searchBox
    };
}

NewsArchiveSearchBox.propTypes = {
    searchBox: PropTypes.object
};

export default connect(mapStateToProps)(NewsArchiveSearchBox);
