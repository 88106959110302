import React, { FC } from "react";
import { Provider } from "react-redux";
import { LanguageContext, getLanguage } from "../../components/LanguageContext";
import { store } from "./vregSearchStore";
import { FilterWrapper } from "./Filters/FilterWrapper";

interface VregSearchFiltersProps {
    languageCode: string
}
export const VregSearchFilters: FC<VregSearchFiltersProps> = (props: VregSearchFiltersProps) => 
        <Provider store={store}  >
            <LanguageContext.Provider value={getLanguage(props.languageCode)}>
                <React.StrictMode>                                            
                            <FilterWrapper />
                </React.StrictMode>
            </LanguageContext.Provider>
        </Provider>
