import React, { FC, useState } from 'react';
import { translateLang } from '../../translate';
import { window } from '../../util/ssrBase';
import { CookieBannerProps } from '../../generatedTypes/FT.Web.ViewModels.ReactProps';

export const CookieBanner : FC<CookieBannerProps> = (props): JSX.Element|null => {

    const storageReference = 'cookieBannerAccepted';
    const [isExpanded, setExpanded] = useState(false);
    const userHasAccepted = window.localStorage.getItem(storageReference);

    if(userHasAccepted)
        return null;

    return (
        <div role="region" aria-labelledby="cookieBannerButton" className='print:hidden'>
            {!isExpanded &&
                <div className="z-1000 text-right" aria-hidden="false" role="alertdialog" aria-modal="true">
                    <button
                        id="cookieBannerButton"
                        type="button"
                        onClick={() => setExpanded(true)}                                 
                        className="text-white-400 text-base hover:underline icon icon--expand__less bg-black-400 p-3"                                                               
                    >                                
                        {props.heading ?? translateLang('cookieBanner.heading', props.languageCode)}
                    </button>
                </div>
            }
            {isExpanded &&
                <>
                    <div className="fixed top-0 h-screen w-full inset-0 bg-black-400 bg-opacity-50 md:hidden z-[999]" aria-hidden="true">
                        <span className="sr-only">{translateLang('cookieBanner.close', props.languageCode)}</span>
                    </div>
                    <div className="
                            w-[calc(100%-48px)]
                            fixed
                            z-[1000]

                            top-[50%]
                            -translate-y-[50%]
                            left-[50%]
                            -translate-x-[50%]

                            md:bottom-0
                            md:top-auto
                            md:left-auto
                            md:-translate-y-0
                            md:-translate-x-[50%]

                            lg:w-1/2
                        "
                        aria-hidden="false"
                        role="alertdialog"
                        aria-modal="true"
                    >

                            <div className="w-full md:w-1/2 ">
                                <div className="p-6 bg-black-400 text-white-400">
                                    <div className="text-right -mt-2">
                                        <button
                                            id="cookieBannerButton"
                                            type="button"
                                            className="text-white-400 text-2xl hover:text-bluegreen-300"
                                            onClick={() =>setExpanded(false)}
                                        >
                                            <span className="sr-only">
                                                {translateLang('cookieBanner.close', props.languageCode)}
                                            </span>
                                            <span className="material-icons">close</span>
                                        </button>
                                    </div>
                                    <h2 className="text-base font-semibold mb-6">
                                        {props.heading ?? translateLang('cookieBanner.heading', props.languageCode)}
                                    </h2>
                                    <p className="mb-6">
                                        {props.text ?? translateLang('cookieBanner.fallbackText', props.languageCode)}
                                    </p>

                                    <button type="button" className="btn btn--primary mr-6"
                                        onClick={() => {
                                                window.localStorage.setItem(storageReference, 'yes');
                                                setExpanded(false);
                                            }}
                                    >
                                        {translateLang('cookieBanner.acceptButton', props.languageCode)}
                                    </button>
                                    {props.readMoreUrl &&
                                        <a href={props.readMoreUrl ?? '#' } title={translateLang('cookieBanner.readMore', props.languageCode)} className="underline decoration-solid decoration-1 underline-offset-2 hover:no-underline">
                                            {translateLang('cookieBanner.readMore', props.languageCode)}
                                        </a>
                                    }
                                </div>
                            </div>

                    </div>
                </>
            }
        </div>
    );
}
