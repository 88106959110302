import { isNumeric } from '../../util/utilityFunctions';

const reducer = (accumulator, currentValue) => {
    if (isNumeric(parseFloat(currentValue))) {
        return parseFloat(accumulator) + parseFloat(currentValue);
    }

    return parseFloat(accumulator);
};

/**
  * @desc maps table data for populating tables with fixed left column, making columns to rows, and rows to columns
  * @param array $array - the array to be transformed
  * @param addSumRow - bool if a row with column sum should be rendered
  * @param sumHeading - heading of sum row
  * @return array - { name, x, y }
*/



export const FixedLeftColumnDataMapper = (props) => {
	const {
        array,
        addSumRow,
        sumHeading
    } = props;

    if(array === null || array === undefined){
        console.warn('Array is null or undefined')
                return;
    }
    
	const out = array.map((row, index) => {
		const x = row?.heading ?? "";
        const y = array.map(elem => elem.values)[index];

        if (addSumRow) {
            if (index === 0) {
                y.push(sumHeading);
            } else {                
                const reduced = parseFloat(array.map(elem => elem.Values)[index].reduce(reducer));
                if (reduced !== undefined) {
                    y.push(reduced.toFixed(1).toString());
                } else {
                    y.push(parseFloat(array.map(elem => elem.Values)[index]));
                }
            }
        }
		const name = array[index].heading;
		return { name, x, y };
    });

    // eslint-disable-next-line consistent-return
    return out;
};

export default {
	FixedLeftColumnDataMapper
};
