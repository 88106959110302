import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Actions } from './actions';
import { getFilters, globalSearch, suggestSearch } from './endpoints';

export const search = (action$: ActionsObservable<Action>): Observable<Action> =>
    action$.pipe(
        filter(isActionOf(Actions.fetchSearchResults.request)),
        switchMap((action) => 
        
            from(globalSearch(action.payload)).pipe(map(Actions.fetchSearchResults.success))
        )
    );

export const loadMore = (action$: ActionsObservable<Action>): Observable<Action> =>
    action$.pipe(
        filter(isActionOf(Actions.loadMore.request)),
        switchMap((action) =>
            from(globalSearch(action.payload)).pipe(map(Actions.loadMore.success))
        )
    );

export const fetchFilters = (action$: ActionsObservable<Action>): Observable<Action> =>
    action$.pipe(
        filter(isActionOf(Actions.fetchFilters.request)),
        switchMap((action) =>
            from(getFilters(action.payload)).pipe(map(Actions.fetchFilters.success))
        )
    );

export const queryParamsChanged = (action$: ActionsObservable<Action>): Observable<Action> =>
    action$.pipe(
        filter(isActionOf(Actions.queryParamsChanged.success)),
        switchMap((action) => of(Actions.fetchSearchResults.request(action.payload)))
    );

export const suggest = (action$: ActionsObservable<Action>): Observable<Action> =>
    action$.pipe(
        filter(isActionOf(Actions.fetchSuggestedResult.request)),
        switchMap((action) =>
            from(suggestSearch(action.payload)).pipe(map(Actions.fetchSuggestedResult.success))
        )
    );
