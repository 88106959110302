import React, { useState } from "react";
import { translateLang } from "../../translate";

interface LanguageSelectorProps {
    IsStartPage: boolean,
    PageInOtherLanguage: string,
    StartPageInOtherLanguage: string,
    Language: string,
    QueryString: string
}

export const LanguageSelector: React.FC<LanguageSelectorProps> = (props): JSX.Element => {
    const [languageOpen, setLanguageOpen] = useState(false);

    return (
        <>
            {props.IsStartPage &&
                <a href={`${props.StartPageInOtherLanguage}`} className="flex items-center underline decoration-solid decoration-1 hover:no-underline" lang="en" hrefLang={props.Language === 'en' ? 'no' :'en'}>
                    {translateLang('menu.language.otherLanguage', props.Language)}</a>
            ||
                <button
                    className="flex items-center justify-between group"
                    aria-label={translateLang('menu.language.otherLanguage', props.Language)}
                    type="button"
                    onClick={() => setLanguageOpen(!languageOpen)}>
                    <span className="underline decoration-solid decoration-1 group-hover:no-underline" lang="en">{translateLang('menu.language.otherLanguage', props.Language)}</span>
                    <span className="ml-1 text-base material-icons" aria-hidden="true">expand_more</span>
                </button>
            }

            {languageOpen &&
                <div className="absolute right-0 text-left border w-56 bg-white-400 border-lightgray-400 prose-li:border prose-li:border-lightgray-400 prose-li:p-2 hover:prose-a:underline">
                    <ul>
                        {props.PageInOtherLanguage &&
                            <li>
                                <a href={`${props.PageInOtherLanguage}${props.QueryString ? `?id=${props.QueryString}` : ''}`} lang="en" hrefLang={props.Language === 'en' ? 'no' :'en'} title={translateLang('menu.language.showInOtherLanguage', props.Language)}>
                                    {translateLang('menu.language.showInOtherLanguage', props.Language)}
                                </a>
                            </li>
                            ||
                            <li>
                                <span className="text-black-300" lang="en" >{translateLang('menu.language.doesNotExistInOtherLanguage', props.Language)}</span>
                            </li>
                        }
                    </ul>
                    <ul>
                        <li>
                            <a href={`${props.StartPageInOtherLanguage}`} lang="en" hrefLang={props.Language === 'en' ? 'no' :'en'} title={translateLang('menu.language.otherHomePage', props.Language)}>{translateLang('menu.language.otherHomePage', props.Language)}</a>
                        </li>
                    </ul>
                </div>
            }
        </>);
}