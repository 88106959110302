import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { investorWarningsApi } from './investorWarningsApi'
import { investorWarningsSlice } from './investorWarningsReducer'

export const investorWarningsStore = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [investorWarningsApi.reducerPath]: investorWarningsApi.reducer,
    investorWarningState: investorWarningsSlice.reducer
  },  
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(investorWarningsApi.middleware),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(investorWarningsStore.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type InvestorWarningRootState = ReturnType<typeof investorWarningsStore.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof investorWarningsStore.dispatch