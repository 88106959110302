import React from 'react';
import { Provider } from 'react-redux';
import { globalSearchReducer } from './data/reducer';
import { GlobalSearchPageComponent } from './searchComponent';
import { store } from './theStore';
import { getLanguage, LanguageContext } from '../components/LanguageContext';

export const NAME = 'searchpageresults';

interface Props {
    language: string;
}

export type Store = ReturnType<typeof globalSearchReducer>;

export const SearchResultsComponent: React.FC<Props> = (props) =>

    <Provider store={store}>
        <LanguageContext.Provider value={ getLanguage(props.language)}>
            <GlobalSearchPageComponent />
        </LanguageContext.Provider>
    </Provider>


