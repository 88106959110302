import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { LanguageContext } from '../components/LanguageContext';
import { SubjectContainer } from '../components/subjectSelector/subjectSelector2';
import NewsArchiveSearchFilters from './searchFilters';

const mapStateToProps = (state) => ({    
    overrideSubjectSelector: state.supervisionCategories.override
});


const filters = (props) => {
    /* eslint-disable react-hooks/rules-of-hooks */
    const languageContext = useContext(LanguageContext);
    return (
        <>
            <div className="w-full mb-6">
                <NewsArchiveSearchFilters />
            </div>

            {!props.overrideSubjectSelector && 
                <div className="w-full mb-6">
                    <SubjectContainer
                        dropdownText={props.subjectSelectorDropdownText}
                        language={languageContext.code}
                    />
                </div>
            }
        </>
    );
};

filters.propTypes = {
    subjectSelectorDropdownText: PropTypes.string,
    overrideSubjectSelector: PropTypes.bool.isRequired
}


export const NewsArchiveFilters = connect(
    mapStateToProps
)(filters);
