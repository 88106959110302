import React from "react";

interface CheckboxProps {
    id: string,
    label: string,
    checked: boolean,
/* eslint-disable @typescript-eslint/no-explicit-any */
    onChange: (id: any) => void
/* eslint-enable @typescript-eslint/no-explicit-any */
}
export class Checkbox extends React.PureComponent<CheckboxProps> {
    render(): JSX.Element {
        return (
            <>
                <input type="checkbox"
                 id={this.props.id}
                 aria-checked={this.props.checked}
                 checked={this.props.checked}
                 onChange={() => this.props.onChange(this.props.id)}
                 />
                <label htmlFor={this.props.id}>{this.props.label}</label>
            </>
        )
    }
}