import React from "react";
import { ResponsiveMenuViewModel } from "../../generatedTypes/FT.Web.ViewModels/responsiveMenuViewModel";
import { LanguageSelector } from "./LanguageSelector";
import { translateLang } from '../../translate';

interface MegaMenuProps {
    MenuVm: ResponsiveMenuViewModel;
    Language: string;
}

export const MegaMenu: React.FC<MegaMenuProps> = (props): JSX.Element =>
    <nav
        className="w-full pt-2 pb-6 overflow-hidden bg-lightgray-400 lg:flex-row mainmenu"
        role="navigation"
        aria-label="Hovedmeny"
    >
        <div className="lg:container">
            <div className="flex flex-col px-2 py-4 divide-y-4 md:divide-y-0 md:flex-wrap lg:flex-nowrap divide-lightgray-400 md:flex-row bg-white-400 lg:divide-x-4">

                {props.MenuVm?.menuColumns &&
                    props.MenuVm.menuColumns.map((column, columnIndex) =>
                        <div className="w-full px-4 py-3 md:w-1/2 lg:py-0 lg:w-full" key={column.header}>
                            <h2 className="mb-4 text-xl font-semibold text-black-400">{column.header}</h2>

                            <ul key={column.header}>
                                {column.links.map((link, index) =>
                                    /* eslint-disable react/no-array-index-key */
                                    <li className="mb-3" key={`${columnIndex}_${index}`}>
                                        <a href={link.url} title={link.title}
                                            className="font-semibold text-black hover:bg-bluegreen-100 hover:underline decoration-solid decoration-1">
                                            {link.title}
                                        </a>
                                    </li>
                                    /* eslint-enable react/no-array-index-key */
                                )}

                            </ul>
                        </div>
                    )}


                <div className="w-full md:w-1/2 lg:w-full px-4 py-3 lg:py-0 lg:pt-[49px]">

                    {props.MenuVm?.staticMenuEntries &&
                        props.MenuVm.staticMenuEntries.map((staticItem) =>
                            <a href={staticItem.url}
                                title="Alle tema"
                                aria-label={staticItem.title}
                                className="flex items-center justify-between w-full px-4 py-3 mb-6 text-sm font-semibold bg-lightgray-400 text-black-400 hover:text-bluegreen-500 group"
                                key={staticItem.title}>
                                <span className="group-hover:underline decoration-solid decoration-1">{staticItem.title}</span>
                                <span className="text-lg text-bluegreen-500 material-icons" aria-hidden="true">
                                    arrow_forward
                                </span>
                            </a>
                        )}

                    <ul className="mb-6">

                        {props.MenuVm?.hygieneMenuLinks &&
                            props.MenuVm.hygieneMenuLinks.map(link =>
                                <li className="mb-3" key={link.title.replace(' ', '_')}>
                                    <a href={link.url} className="p-1 text-black underline hover:bg-bluegreen-100 hover:no-underline decoration-solid decoration-1">
                                        {link.title}
                                    </a>
                                </li>
                            )
                        }

                        {props.MenuVm?.searchPage &&
                            <li className="block mb-3 lg:hidden" key="search">
                                <a href={props.MenuVm.searchPage} title={translateLang('menu.labels.search', props.Language)}
                                    className="p-1 text-black underline hover:bg-bluegreen-100 hover:no-underline decoration-solid decoration-1"
                                    aria-label={translateLang('menu.labels.search', props.Language)}>
                                    {translateLang('menu.labels.search', props.Language)}
                                </a>
                            </li>
                        }

                    </ul>

                    {props.MenuVm?.newsletterSignup &&
                        <a href={props.MenuVm.newsletterSignup} title={translateLang('menu.labels.subscribe', props.Language)} className="inline-flex items-center space-x-2 text-bluegreen-500 group">
                            <span className="material-icons">mail</span>
                            <span className="underline group-hover:bg-bluegreen-100 decoration-solid decoration-1 group-hover:no-underline">
                                {translateLang('menu.labels.subscribe', props.Language)}
                            </span>
                        </a>
                    }

                    <div className="block mt-2 text-base text-right lg:hidden">
                        <LanguageSelector
                            IsStartPage={props.MenuVm?.isStartPage ?? false}
                            PageInOtherLanguage={props.MenuVm?.pageInOtherLanguage}
                            StartPageInOtherLanguage={props.MenuVm?.startPageInOtherLanguage}
                            Language={props.Language}
                            QueryString={props.MenuVm?.queryStringId}
                        />
                    </div>
                </div>
            </div>
        </div>
    </nav>
