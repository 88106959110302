import{ document, window } from './ssrBase';

// If user tabs - add class which adds button focus outline
const handleFirstTab = (e: KeyboardEvent) => {
    if (e.keyCode === 9) {
        document.body.classList.add('user-is-tabbing');
        window.removeEventListener('keydown', handleFirstTab);
        window.addEventListener('mousedown', handleMouseDownOnce);
    }
};

// If user clicks mouse - remove class which adds button focus outline
const handleMouseDownOnce = () => {
    document.body.classList.remove('user-is-tabbing');
    window.removeEventListener('mousedown', handleMouseDownOnce);
    window.addEventListener('keydown', handleFirstTab);
};

export const addTabbingEventListener = (): void =>
    window.addEventListener('keydown', handleFirstTab);
