import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { InvestorWarningsSearchViewModel, PageTypeViewModel } from "../../generatedTypes/FT.Web.ViewModels";

export interface ApiRequest {
    language: string    
}


export const investorWarningsApi = createApi({
    reducerPath: 'investorWarningsApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/search/investorwarnings/' }),
    endpoints: (builder) => ({
        getMetadata: builder.query<PageTypeViewModel[], ApiRequest>({
            query: (searchArgs) => `/metadata?l=${searchArgs.language}`,
        }),
        searchInvestorWarnings: builder.query<InvestorWarningsSearchViewModel, string>({
            query: (queryString) => `${queryString}`,
        }),
    })    
})

export const { useSearchInvestorWarningsQuery } = investorWarningsApi;
export const { useGetMetadataQuery } = investorWarningsApi;