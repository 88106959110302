import React, { FC, useContext } from "react"
import { useDispatch, useSelector } from "react-redux";
import { LanguageContext } from "../../../components/LanguageContext";
import { ExpandBox } from "../../../components/expandBox/ExpandBox";
import { Checkbox } from "../../../components/forms/checkbox";
import { VregRootState } from "../../Search/vregSearchStore";
import { translateLang } from "../../../translate";
import { vregUserReportStateActions } from "../../Services/VregUserReportState";

export const ProviderTypeFilter: FC = () => {

    const providerTypes = ['Licensed', 'Agent', 'Branch']
    const languageContext = useContext(LanguageContext);
    const includedTypes = useSelector((state: VregRootState) => state.vregUserReportState.includedProviderTypes);
    const dispatch = useDispatch();

    return (
        <ExpandBox ariaLabelKey="vreg.filters.providerTypes.title" titleKey="vreg.filters.providerTypes.title">
            <div className='p-normal flex flex-col space-y-2'>
            {providerTypes.map(p =>
                <div className="checkbox--custom checkbox--custom__small" key={p}>
                    <Checkbox                        
                        checked={includedTypes.indexOf(p) > -1}
                        id={p}
                        label={translateLang(`vreg.serviceProvider.types.${p}`, languageContext.code)}
                        onChange={() => dispatch(vregUserReportStateActions.setIncludedProviderTypes(p))}
                    />
                </div>
            )}
            </div>
        </ExpandBox>
    )

}