import { translateLang } from "../../translate";

const API_ENDPOINT = '/api/PostFeedback';

export async function submitFeedback(message: string, language: string): Promise<string> {
    const formData = new FormData();
    formData.append('message', message);
    formData.append('url', window.location.href);

    let result : string = "";

    const response = await fetch(API_ENDPOINT, {
        method: 'post',
        body: formData,
    });
    const data  = await response.json();
    
    if (data.success === true) {
        result = "success";
        return result;
    }

    return translateLang('feedback.texts.errorTryAgain', language);

}