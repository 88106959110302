import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { trackEvent } from '../eventTracking';
import { SearchResult, SearchResultSuggestion } from '../generatedTypes/FT.Web.Search.Models';
import { GlobalSearchFilter } from '../generatedTypes/FT.Web.Search.Models/globalSearchFilter';
import { Store } from '.';
import { translateLang } from '../translate';
import { Actions } from './data/actions';
import { SearchRequestState, SuggestSearchRequestState } from './data/reducer';
import { SearchFilters } from './searchFilters';
import { SearchInput } from './searchInput';
import { SearchResultsComponent } from './searchResultsComponent';
import { LanguageContext } from '../components/LanguageContext';

interface InputProps {
    title: string;
}

interface ConnectedProps {
    queryParams: QueryParams;
    filterList?: GlobalSearchFilter[];
    searchResult?: SearchResult;
    suggestedResult?: SearchResultSuggestion[];
}

export interface QueryParams {
    queryString: string;
    activeFilters: string[];
    sortByDate: boolean;
    page: number;
}

type ConnectedDispatch = ReturnType<typeof mapDispatchToProps>;

type Props = InputProps & ConnectedProps & ConnectedDispatch;

const SearchPageSearchBoxContainer: React.FC<Props> = (props) => {
    const {
        queryParams,
        title,
        fetchFilters,
        filterList,
        queryParamsChanged,
        searchResult,
    } = props;

    const languageContext = useContext(LanguageContext);
    const language = languageContext.code;

    const [hideFilters, setHideFilters] = useState(true); // hide filters in accordion on mobile
    const [currentQuery, setCurrentQuery] = useState(queryParams.queryString);
    useEffect(() => {
        fetchFilters(language);
        // immediately load empty search...

        queryParamsChanged({
            queryParams: { 
                ...queryParams,
                queryString: currentQuery },
            lang: language,
            page: queryParams.page,
            isChanged: false,
        });
        
    }, []);

    const handleSearchButtonClick = (newQuery: string) => {
        trackEvent('Søk', newQuery);        
        setCurrentQuery(newQuery);
        queryParamsChanged({
            queryParams: { ...queryParams, queryString: newQuery, page: 1 },
            lang: language,
            page: 1,
            isChanged: true,
        });
    };

    const handleFilterClick = (filter: GlobalSearchFilter) => {
        if (!queryParams.activeFilters.find((x) => x === filter.key)) {
            trackEvent('Filter valgt', filter.friendlyName);
            queryParamsChanged({
                queryParams: {
                    ...queryParams,
                    page: 1,
                    activeFilters: [...queryParams.activeFilters, filter.key],
                },
                lang: language,
                page: 1,
                isChanged: true,
            });
        } else {
            // fjerne filter
            trackEvent('Filter fjernet', filter.friendlyName);
            const newList: string[] = queryParams.activeFilters.filter((x) => x !== filter.key);

            queryParamsChanged({
                queryParams: {
                    ...queryParams,
                    activeFilters: newList,
                    page: 1
                },
                lang: language,
                page: 1,
                isChanged: true,
            });
        }
    };

    const resetFilters = () => {        
        queryParamsChanged({
            queryParams: {
                ...queryParams,
                activeFilters: [],
            },
            lang: language,
            page: queryParams.page,
            isChanged: true,
        });
    }

    return (
        <>
        <div className="container">

            <div className="flex justify-start mb-6 lg:justify-center">
                <div className="w-full lg:w-1/2 js-header-container lg:pl-[20px]" role="region" aria-label={translateLang('globalsearch.texts.inputLabel', language)}>
                    <h1 className="mb-3 text-xl font-normal md:text-2xl lg:text-3xl text-black-400 leading-h1">
                        {title || translateLang('globalsearch.texts.inputLabel', language)}
                    </h1>                
                    <div className="relative w-full"> 
                        <SearchInput
                            initialQueryParams={queryParams}
                            onSearchButtonClick={handleSearchButtonClick}
                            isSearchPage
                            inputId="searchPage"
                            autoFocus
                            {...props}
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-col space-y-6 lg:space-y-0 lg:flex-row lg:space-x-6">
                <div className="w-full mb-6 lg:w-1/4" role="region" aria-label={translateLang('globalsearch.texts.filtersLabel', language)}>
                    {filterList && (
                        <SearchFilters
                            filterList={filterList}
                            activeFilters={queryParams.activeFilters}
                            onFilterClick={handleFilterClick}
                            hideFilters={hideFilters}
                            onAvgrensSokClick={(hide: boolean) => {
                                trackEvent('Vis/skjul filtre', hide ? 'Skjul' : 'Vis');
                                setHideFilters(hide);
                            }}
                            searchResult={searchResult}                            
                            reset={resetFilters}
                        />
                    )}
                </div>

                <div className="w-full lg:w-3/4">
                    <SearchResultsComponent language={language} />
                </div>
            </div>
         </div>
        </>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchFilters: (lang: string) => dispatch(Actions.fetchFilters.request(lang)),
    queryParamsChanged: (searchRequestState: SearchRequestState) => {
        dispatch(Actions.queryParamsChanged.success(searchRequestState));
    },
    requestSuggestedResult: (state: SuggestSearchRequestState) =>
        dispatch(Actions.fetchSuggestedResult.request(state)),
});

function mapStateToProps(state: Store): ConnectedProps {
    return {
        queryParams: state.searchBox.queryParams,
        filterList: state.searchFilters.filterList,
        searchResult: state.searchResult.searchResult,
        suggestedResult: state.suggestedResult.suggestedResult,
    };
}

export const SearchPageSearchBox = connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchPageSearchBoxContainer);
