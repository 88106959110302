import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LegalEntityType } from '../../generatedTypes/FT.Web.Controllers.Api.Models.Virkereg';
import { CheckedChanged, MultiCheckedChanged } from './CheckedChanged';
import { mapToUrlParams, replaceUrlParams } from '../../util/urlHelpers';
import { window } from '../../util/ssrBase';

const queryParams = new URLSearchParams(window.location.search);

function getUrlParams(state: VregSearchState) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return mapToUrlParams((addIfNotNull: any) => {
        addIfNotNull('p', state.pageNumber);
        addIfNotNull('group', queryParams.get('group'));
        addIfNotNull('country', state.country);
        addIfNotNull('q', state.query);
        if (state.registerType !== null && state.registerType !== undefined) {
            addIfNotNull('rt', state.registerType.toString());
        }
        state.selectedLicenceTypes.map((filter) => addIfNotNull('licenceType', filter));
        state.selectedCounties.map((filter) => addIfNotNull('county', filter));
    });
}

function setQuerystring(state: string) {
    replaceUrlParams(state)
}

function getRegisterType(type: string | null) {
    if (type === "0")
        return LegalEntityType.Company
    if (type === "1")
        return LegalEntityType.Person

    return null;
}


export interface VregSearchState {
    selectedLicenceTypes: string[];
    query: string;
    registerType: LegalEntityType | null;
    pageNumber: number;
    totalPages: number;
    country: string | null;
    selectedCounties: string[];
    mobileFilterOpen: boolean
}

const initialState: VregSearchState = {
    selectedLicenceTypes: queryParams.getAll('licenceType') || [],
    query: queryParams.get('q') || '',
    registerType: getRegisterType(queryParams.get('rt') || null),
    pageNumber: parseInt(queryParams.get('p') || '1', 10),
    totalPages: 10,
    country: queryParams.get('country') || null,
    selectedCounties: queryParams.getAll('county') || [],
    mobileFilterOpen: false
};

export const vregSearchStateSlice = createSlice({
    name: 'vregSearchState',
    initialState,
    reducers: {
        resetLicenceTypes: (state) => {
            state.selectedLicenceTypes = [];
        },
        selectLicenceType: (state, action: PayloadAction<CheckedChanged>) => {
            const existingIndex = state.selectedLicenceTypes.findIndex(a => a === action.payload.id);
            if (existingIndex > -1) {
                state.selectedLicenceTypes.splice(existingIndex, 1);
            }
            else {
                state.selectedLicenceTypes.push(action.payload.id);
            }

            setQuerystring(getUrlParams(state));
        },
        setQuery: (state, action: PayloadAction<string>) => {
            state.query = action.payload;
            setQuerystring(getUrlParams(state));
        },
        setTotalPages: (state, action: PayloadAction<number>) => {
            state.totalPages = action.payload;
        },
        goToFirstPage: (state) => {
            state.pageNumber = 1;
            setQuerystring(getUrlParams(state));
        },
        incrementPage: (state) => {
            state.pageNumber += 1;
            setQuerystring(getUrlParams(state));
        },
        decrementPage: (state) => {
            state.pageNumber -= 1;
            setQuerystring(getUrlParams(state));
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.pageNumber = action.payload;
            setQuerystring(getUrlParams(state));
        },
        setRegisterType: (state, action: PayloadAction<LegalEntityType | null>) => {
            state.registerType = action.payload;
            setQuerystring(getUrlParams(state));
        },
        setCountry: (state, action: PayloadAction<string | null>) => {
            state.country = action.payload;
            setQuerystring(getUrlParams(state));
        },
        selectAllOrNoCounty: (state) => {
            state.selectedCounties = [];
            setQuerystring(getUrlParams(state));
        },
        toggleAllGroup: (state, action: PayloadAction<MultiCheckedChanged>) => {
            if (action.payload.checked) {
                action.payload.ids.map((type) => {
                    const existingIndex = state.selectedLicenceTypes.indexOf(type.id);
                    if (existingIndex === -1) state.selectedLicenceTypes.push(type.id);
                    return type;
                });
            } else {
                const unwantedIds = action.payload.ids.map(a => a.id);
                state.selectedLicenceTypes = state.selectedLicenceTypes.filter(f => unwantedIds.indexOf(f) === -1);
            }
            setQuerystring(getUrlParams(state));
        },
        selectCounty: (state, action: PayloadAction<CheckedChanged>) => {
            const existingIndex = state.selectedCounties.findIndex(a => a === action.payload.id);
            if (existingIndex > -1) {
                state.selectedCounties.splice(existingIndex, 1);
            }
            else {
                state.selectedCounties.push(action.payload.id);
            }
            setQuerystring(getUrlParams(state));
        },
        toggleMobileFilter: (state) => {
            state.mobileFilterOpen = !state.mobileFilterOpen;
        }
    },
});

export const vregSearchReducer = vregSearchStateSlice.reducer;
export const vregSearchActions = vregSearchStateSlice.actions;
