import React, { useContext } from 'react';
import { SearchResult } from '../generatedTypes/FT.Web.Search.Models';
import { translateLang } from '../translate';
import { SearchPageSearchResultItem } from './searchResultItem';
import { VregSearchHit } from '../vreg/Search/VregSearchHit';
import { LanguageContext } from '../components/LanguageContext';

interface Props {
    searchResult: SearchResult;
}

export const SearchPageSearchResults: React.FC<Props> = ({
    searchResult
}) => 
{
    const languageContext = useContext(LanguageContext);
    const language = languageContext.code;
   return (
    <main className="mb-6" role="region" aria-label={translateLang('globalsearch.texts.searchResults', language)}>

        <div className="bg-white-400 w-full p-6 flex flex-col divide-y-4 divide-black-100 pt-0 ">
            {searchResult.hits.map((item) => (
                item.typeName !== "Virkereg" &&
                (<SearchPageSearchResultItem
                    key={item.id}
                    name={item.title}
                    preamble={item.preamble}
                    published={item.publishedDate}
                    type={item.typeName}
                    url={item.url}
                />)
                ||
                item.typeName === "Virkereg" &&
                (
                    <div className='-mx-5' key={item.url}>
                        <VregSearchHit
                            
                            id={parseInt(item.preamble.split('|')[6], 10)}
                            name={item.title}
                            type={item.preamble.split('|')[7] ?? ''}
                            country={item.preamble.split('|')[3]}
                            county={item.preamble.split('|')[4]}
                            licences={`${item.preamble.split('|')[5]} ${translateLang('vreg.licences', language)}`}
                            typeDescription='fromVirkereg'
                            url={item.url}
                        />
                    </div>
                )

            ))}
        </div>
    </main>
);
}