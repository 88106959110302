import React from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { setScrollPosition, scrollToPrevPosition } from '../util/persistScroll';
import NewsArchiveSearchResults from './searchResults';
import { getLanguage, LanguageContext } from '../components/LanguageContext';
import newsarchiveReducer, { initialState } from './reducer';
import { NewsArchiveHeader } from './header';
import { NewsArchiveFilters } from './NewsArchiveFilters';
import * as supervision from '../components/subjectSelector/supervision';
import { isMobile, window } from '../util/ssrBase';
import { TextBlock } from './textblock';
import { ExpandBox } from '../components/expandBox/ExpandBox';
import { overrideSubjectSelectorKey } from './selectors';

export const NAME = 'newsarchive';

function populateInitialState(language, typeFilter) {
    let overrideFagomradevelger = false;
    let supervisionCategory = null;
    if (window.location.search) {
        const params = new URLSearchParams(window.location.search.substring(1));
        initialState.page = params.get('page') || 1;
        initialState.searchBox.queryString = params.get('query') || '';
        if (typeFilter) {
            params.set('t', typeFilter.id);
            initialState.typeFilters.activeFilters = [typeFilter.id];
        } else {
            initialState.typeFilters.activeFilters =
                params.getAll('t').map((t) => parseInt(t, 10)) || [];
        }
        initialState.metaDataFilters.activeFilter = params.get('metaData') || -1;
        
        initialState.dateFilters.items = 
            params.getAll('years').map((y) => parseInt(y, 10)) || [];

        overrideFagomradevelger = params.get(overrideSubjectSelectorKey) || false;
        if (overrideFagomradevelger) {
            initialState.overrideSubjectSelector = true;
            supervisionCategory = params.get('supervisionCategories');
        } else {
            initialState.overrideSubjectSelector = false;
        }
    } else {
        initialState.typeFilters.activeFilters = [];
        if (typeFilter) initialState.typeFilters.activeFilters.push(typeFilter.id);
        initialState.overrideSubjectSelector = true;
    }

    if (!overrideFagomradevelger) {
        initialState.supervisionCategories.activeItems =
            supervision.getSavedSupervisionCategories();
    } else {
        initialState.supervisionCategories.activeItems = [supervisionCategory];
        initialState.supervisionCategories.override = true;
    }
    initialState.settings.lang = language;
}

function getHeaderSuffix() {
    if (window.location.search) {
        const params = new URLSearchParams(window.location.search.substring(1));
        const overrideFagomradevelger = params.get(overrideSubjectSelectorKey) || false;
        if (overrideFagomradevelger) {
            return params.get('scName') || '';
        }
    }

    return '';
}

export class NewsArchive extends React.Component {
    store = null;

    constructor(props) {
        super(props);
        setScrollPosition(NAME);
        this.store = createStore(
            newsarchiveReducer,
            populateInitialState(this.props.language, props.initialTypeFilter),
            compose(applyMiddleware(thunk))
        );
    }

    componentDidMount() {
        scrollToPrevPosition(NAME);
    }

    render() {
        return (
            <LanguageContext.Provider value={getLanguage(this.props.language)}>
                <Provider store={this.store}>
                    <NewsArchiveHeader
                        pageName={this.props.pageName}
                        headerSuffix={getHeaderSuffix()}
                    />

                    <div className="flex flex-col lg:flex-row lg:space-x-6">
                        <aside className="w-full lg:w-4/12">
                            <NewsArchiveFilters
                                language={this.props.language}
                                subjectSelectorDropdownText={this.props.subjectSelectorDropdownText}
                            />

                            {this.props.archiveContentsHeader && this.props.archiveContentsBody && (
                                <ExpandBox
                                    ariaLabelString={this.props.archiveContentsHeader}
                                    titleString={this.props.archiveContentsHeader}
                                    expanded={!isMobile}                                    
                                >
                                    <TextBlock
                                        header={this.props.archiveContentsHeader}
                                        mainBody={this.props.archiveContentsBody}
                                    />
                                </ExpandBox>
                            )}
                        </aside>

                        <div className="w-full lg:w-8/12">
                            <NewsArchiveSearchResults />
                        </div>
                    </div>
                </Provider>
            </LanguageContext.Provider>
        );
    }
}
