import React, { FC } from 'react';
import { Address } from '../generatedTypes/FT.Web.ViewModels.Vreg';

interface AddressProps {
    heading: string,
    address: Address
}

export const AddressView: FC<AddressProps> = (props: AddressProps) =>
    <div className="py-3 lg:px-6">
        <h3 className="text-base font-semibold mb-2">
            {props.heading}
        </h3>
        <p>
            {props.address.street &&
                <>
                    {props.address.street}
                    <br />
                </>
            }
            {props.address.postalCode &&
                <>{props.address.postalCode},</>
            }
            {props.address.city &&
                <>{props.address.city},</>
            }
            {props.address.country}
        </p>
    </div>