import React, { useContext } from 'react';
import { AnyAction } from 'redux';
import { useDispatch } from 'react-redux';
import { translateLang } from "../../../translate";
import { LanguageContext } from '../../../components/LanguageContext';
import { CheckedChanged } from '../../Services/CheckedChanged';

interface SelectedItemsProps {
    checkedItems: CheckedChanged[],
    checkItem(cc:CheckedChanged): AnyAction,
    helptextKey: string|null
}

export const SelectedItems: React.FC<SelectedItemsProps> = (props): JSX.Element | null => {
    const dispatch = useDispatch();
    const languageContext = useContext(LanguageContext);
    const itemsToShow = 3;
    
    if(props.checkedItems.length === 0)
        return null;

    return (           
        <section className="border-t border-black-200 mb-4 pt-2">
            {props.helptextKey &&
                <p className="text-sm mb-1">{translateLang(`${props.helptextKey}`, languageContext.code)}</p>
            }
            <ul className="-mx-1 flex flex-wrap">
                {props.checkedItems.slice(0, itemsToShow).map(o => 
                            <li className='p-1' key={o.id}>
                                <button
                                    type="button"
                                    className="text-sm bg-bluegreen-100 p-1 icon icon--close after:text-bluegreen-500 after:content-['close'] after:ml-0 hover:text-bluegreen-500 hover:bg-bluegreen-100"
                                    onClick={() => {
                                         dispatch(props.checkItem(o));
                                        }
                                    }
                                >
                                    {o.name}
                                </button>
                            </li>
                )}
                
                {(props.checkedItems.length) > itemsToShow &&                
                    (<li className='px-1 py-2 text-sm'>
                        &#43;{ (props.checkedItems.length)  - itemsToShow }
                    </li>)}
            </ul>
        </section>);
}
