import React from 'react';

interface Props {
    name: string;
    preamble: string;
    published: string;
    type: string;
    url: string;
}

export const SearchPageSearchResultItem: React.FC<Props> = ({
    name,
    preamble,
    published,
    type,
    url,
}) => (
    <div className="w-full lg:w-11/12 py-6">
        <div className="flex justify-between items-start">
            <div className="text-xs text-yellow-600">{type}</div>
            <div className="text-xs text-yellow-600">{published}</div>
        </div>
        <a className="" href={url}>
            <h2 className="mb-4 text-lg text-bluegreen-500 font-semibold hover:underline decoration-1 underline-offset-4">{name}</h2>
        </a>
        <p className="search-result-item-preamble search-result-item-markup" dangerouslySetInnerHTML={{__html: preamble}} />
    </div>
);
