import React, { useContext } from 'react';
import { SearchResult } from '../generatedTypes/FT.Web.Search.Models';
import { GlobalSearchFilter } from '../generatedTypes/FT.Web.Search.Models/globalSearchFilter';
import { translateLang } from '../translate';
import { Checkbox } from '../components/forms/checkbox';
import { LanguageContext } from '../components/LanguageContext';

interface Props {
    filterList: GlobalSearchFilter[];
    activeFilters: string[];
    onFilterClick: (id: GlobalSearchFilter) => void;
    hideFilters: boolean;
    onAvgrensSokClick: (hide: boolean) => void;
    searchResult?: SearchResult;
    reset: () => void
}

export const SearchFilters: React.FC<Props> = ({
    filterList,
    activeFilters,
    onFilterClick,
    hideFilters,
    onAvgrensSokClick,
    searchResult,
    reset
}) => {

    const languageContext = useContext(LanguageContext);
    const language = languageContext.code;

    return (
        <div className="z-100 top-0 left-0 border-t border-b border-l-4 border-r border-bluegreen-300 bg-white-400 w-full">
            <button title={translateLang('globalsearch.filters.filterResults', language)}
                type="button"
                className="flex items-center justify-between p-4 cursor-pointer hover:text-bluegreen-500 text-black-400 text-base w-full"
                aria-expanded={!hideFilters}
                aria-controls="searchFiltersContainer"
                onClick={() => onAvgrensSokClick(!hideFilters)}
            >
                <span>{translateLang('globalsearch.filters.filterResults', language)}</span>
                <span className="material-icons" aria-hidden="true">{hideFilters === false ? "close" : "expand_more"}</span>
            </button>

            {hideFilters === false &&
                <div className={`${hideFilters ? 'hidden' : 'px-3'}`} id="searchFiltersContainer">
                    <ul
                        key="filters"
                        className="py-1 border-b border-gray-400"
                        id="content2"
                        aria-hidden={hideFilters}>
                        {filterList.map((filter) => {
                            const active = activeFilters.includes(filter.key);
                            const facet =
                                searchResult &&
                                searchResult.facets &&
                                searchResult.facets.find((a) => a.key === filter.key);
                            const count = facet ? facet.count : 0;

                            return (
                                <li className="checkbox--custom prose-label:text-sm" key={`filter_${filter.key}`} >
                                    <Checkbox
                                        onChange={() => onFilterClick(filter)}
                                        key={filter.key}
                                        id={filter.key}
                                        label={`${filter.friendlyName} (${count})`}
                                        checked={active}
                                    />
                                </li>
                            );
                        })}
                    </ul>

                    <div className="flex justify-between py-4">
                        <button
                            type="button"
                            title={translateLang('newsarchive.buttons.resetFilter', language)}
                            className="flex items-center justify-between text-black-400 hover:text-bluegreen-500 group"
                            onClick={() => reset()}
                        >
                            <span className="underline group-hover:no-underline">{translateLang('newsarchive.buttons.resetFilter', language)}</span>
                        </button>
                        <button
                            type="button" title={translateLang('newsarchive.buttons.closeFilter', language)} className="flex items-center justify-between text-black-400 hover:text-bluegreen-500 group"
                            onClick={() => onAvgrensSokClick(!hideFilters)}
                        >
                            <span className="underline group-hover:no-underline">
                                {translateLang('newsarchive.buttons.closeFilter', language)}
                            </span>
                            <span className="ml-2 material-icons" aria-hidden="false">close</span>
                        </button>
                    </div>
                </div>
            }

            {hideFilters === true && activeFilters.length > 0 &&
                <section className="border-t border-black-200 mb-4 mx-4 pt-2">
                    {activeFilters.length > 0 &&
                        <>
                            <p className="text-sm mb-1">
                                {translateLang('newsarchive.texts.selectedNewsTypes', language)}
                            </p>
                            <ul className="-mx-1 flex flex-wrap mb-1">
                                {filterList.filter(f => activeFilters.includes(f.key)).map(filter =>
                                    <li className="p-1" key={filter.key}>
                                        <button
                                            type="button"
                                            onClick={() => onFilterClick(filter)}
                                            className="text-sm bg-bluegreen-100 p-1 icon icon--close after:text-bluegreen-500 after:content-['close'] after:ml-0 hover:text-bluegreen-500 hover:bg-bluegreen-100">
                                            {filter.friendlyName}
                                        </button>
                                    </li>
                                )}
                            </ul>
                        </>}
                </section>
            }
        </div >
    );
}