import React, { useContext, useState } from 'react';
import { ContainerProps, InputProps } from 'react-autosuggest';
import { SearchResultSuggestion } from '../generatedTypes/FT.Web.Search.Models';
import { translateLang } from '../translate';
import { SuggestSearchInput } from './autoSuggest';
import { SuggestSearchRequestState, updateUrl } from './data/reducer';
import { QueryParams } from './searchBox';
import { LanguageContext } from '../components/LanguageContext';

interface Props {
    initialQueryParams?: QueryParams;
    suggestedResult?: SearchResultSuggestion[];
    onSearchButtonClick: (q: string, b: boolean) => void;
    requestSuggestedResult: (state: SuggestSearchRequestState) => void;
    isSearchPage: boolean;
    inputId: string;
    autoFocus: boolean;
}

export const SearchInput: React.FC<Props> = (props) => {
    const { initialQueryParams: initialQuery, onSearchButtonClick, isSearchPage } = props;
    const [searchQuery, setSearchQuery] = useState(
        (initialQuery && initialQuery.queryString) || ''
    );

    const languageContext = useContext(LanguageContext);

    const inputProps: InputProps<SearchResultSuggestion> = {
        onChange: (e, changeEvent) => {
           setSearchQuery(changeEvent.newValue);
        },
        autoFocus: props.autoFocus,
        value: searchQuery,
        id: props.inputId,
        "aria-label": translateLang('search.searchFieldLabel', languageContext.code),
        type: "search",
    };
    
    const containerProps: ContainerProps = {
        "aria-label": translateLang("search.searchFieldLabel", languageContext.code),
        "aria-controls": props.inputId
    };
    
    return (
        <>
            <div className="flex flex-row" role="search">
                <SuggestSearchInput
                    inputProps={inputProps}
                    containerProps={containerProps}
                    suggestedResult={props.suggestedResult}
                    requestSuggestedResult={props.requestSuggestedResult}
                    language={languageContext.code}
                    search={() => onSearchButtonClick(searchQuery, true)}
                    onSuggestionSelected={() =>
                        isSearchPage &&
                        updateUrl({
                            ...initialQuery,
                            queryString: searchQuery,
                            activeFilters: [],
                            sortByDate: false,
                            page: 0
                        })
                    }
                />
                <button
                    className="-ml-[1px] btn btn--primary"
                    onClick={() => props.onSearchButtonClick(searchQuery, true)}
                    onKeyDown={(k) => k.keyCode === 13 && onSearchButtonClick(searchQuery, true)}
                    type="button"
                    aria-label={translateLang('globalsearch.buttons.search', languageContext.code)}
                >
                    {translateLang('globalsearch.buttons.search', languageContext.code)}
                </button>
            </div>
        </>
    );
};
