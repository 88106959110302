import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { LanguageContext } from '../components/LanguageContext';
import { translateLang } from '../translate';
import ProspectusRegistrySearchResultItem from './prospectusRegistrySearchResultItem';

export default class ProspectusRegistrySearchResultItems extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { searchResult } = this.props;
        const nowShowingFrom = ((searchResult.page - 1) * searchResult.pageSize) + 1;
        const nowShowingTo = searchResult.items.length < searchResult.pageSize ? ((searchResult.page - 1) * searchResult.pageSize) + searchResult.items.length : (searchResult.page * searchResult.pageSize);

        const language = this.context.code;

        return (
            <>
                <div className="bg-white-400 p-6 mb-6">
                    <div className='w-full lg:w-11/12 flex flex-col lg:flex-row lg:justify-between space-y-3 lg:space-y-0'>
                        <span className="search-results-count">
                            {searchResult && searchResult.items.length > 0 ? (
                                translateLang('newsarchive.texts.totalHitsTemplate', language, [nowShowingFrom, nowShowingTo, searchResult.total])
                            )
                                :
                                translateLang('newsarchive.texts.noHits', language)
                            }
                        </span>
                    </div>
                </div>
                <div className="bg-white-400 p-6 mb-6">
                    <div className='w-full lg:w-11/12 -mt-6'>
                        {
                            !searchResult.loading &&
                            searchResult.items.map((item) => (
                                <div className='py-6 border-b-4 border-black-100' key={`result_${item.id}`}>
                                    <ProspectusRegistrySearchResultItem
                                        name={item.name}
                                        published={item.published}
                                        type={item.prospectusType}
                                        url={item.url}
                                        metadata={item.approvedDateString}
                                    />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </>
        );
    }
}

ProspectusRegistrySearchResultItems.propTypes = {
    searchResult: PropTypes.object.isRequired
};

ProspectusRegistrySearchResultItems.contextType = LanguageContext;
