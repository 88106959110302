import React, { FC, useContext, useState } from 'react'
import { LanguageContext } from '../LanguageContext';
import { translateLang } from '../../translate';

interface ExpandProps {
    ariaLabelKey?: string,
    titleKey?: string | null,
    titleString?: string | null,
    children: React.ReactNode | React.ReactNode[];
    expanded?: boolean | null
}

export const ExpandBox: FC<ExpandProps> = (props) => {
    const languageContext = useContext(LanguageContext);
    const [expanded, setExpanded] = useState(props.expanded || false);

    return (
        <div className="w-full bg-white-400 border border-l-4 p-4 mb-6 border-bluegreen-200" >
            <button type="button"
                className={`flex items-center w-full text-base icon hover:text-bluegreen-500 ${expanded ? 'icon--close' : 'icon--expand__more'} icon--position__farright`}
                aria-label={props.ariaLabelKey ? translateLang(props.ariaLabelKey, languageContext.code) : ''}
                onClick={() => setExpanded(!expanded)}
            >
                {props.titleKey && translateLang(props.titleKey, languageContext.code)}
                {props.titleString && props.titleString}
            </button>
            {expanded &&
                props.children
            }
        </div>

    );
}