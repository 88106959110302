export const SCROLLSPY_CLASSNAME = 'scrollspy';

function isInViewPort(element) {
    const position = element.getBoundingClientRect();
    return position.top < 200 && position.bottom > 50;
}

function setNewHash(activeId) {
    if (activeId !== window.location.hash) {
        if (history.replaceState) {            
                history.replaceState(null, null, activeId);
        } else {
            window.location.hash = activeId;
        }
    }
}

export default () => {
    const sectionNodeList = document.querySelectorAll(`.${SCROLLSPY_CLASSNAME}`);

    const sections = [...sectionNodeList].filter((s) => s.id);

    window.addEventListener('scroll', () => {
        const activeSections = sections.filter(isInViewPort);

        if (activeSections.length > 0) {
            const activeId = `#${activeSections[0].id}`;
            if(activeId !== '' || activeId !== undefined || activeId !== ' '){
                setNewHash(activeId);
            }
        } else {
            setNewHash(' ');
        }
    });
};
