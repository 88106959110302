import { Action, applyMiddleware, createStore } from 'redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import {
    fetchFilters as fetchFiltersEpic,
    loadMore as loadMoreEpic,
    queryParamsChanged as queryParamsChangedEpic,
    search as globalSearchEpic,
    suggest as suggestEpic,
} from './data/epics';
import { globalSearchReducer, initialState } from './data/reducer';

export type Store = ReturnType<typeof globalSearchReducer>;
const epicMiddleware = createEpicMiddleware<Action, Action, Store>();
const theStore = createStore(globalSearchReducer, initialState, applyMiddleware(epicMiddleware))
epicMiddleware.run(
    combineEpics(
        globalSearchEpic,
        loadMoreEpic,
        fetchFiltersEpic,
        queryParamsChangedEpic,
        suggestEpic
    )
);

export const store = theStore


