import React, { FC, useState } from "react";
import { DecimalInput } from "./DecimalInput";

interface FinSecReportProps {
    formElementGuid: string,
    rowCount: number,
    countLabel: string,
    priceLabel: string,
    nameLabel: string
}
export const FinSecReport: FC<FinSecReportProps> = (props): JSX.Element | null => {
    const [myRowCount, setRowCount] = useState(props.rowCount);
    let rows = new Array<number>();
    let i = 0;
    while (i < myRowCount) {
        rows.push(i);
        i += 1;
    }
    return (
        <>
            {rows.map((x: number, index: number) =>
            (
                <div className="flex flex-col w-full items-end justify-start space-y-6 mb-6 md:flex-row md:w-full xl:w-10/12 md:space-x-6 md:justify-between md:space-y-0 print:flex-row print:w-full print:justify-between print:space-x-6 print:space-y-0 Form__Element__FinSecReportRow" key={`formRow${x}`}>
                    <div className="w-full flex-none md:w-1/12 print:w-1/12">
                        <label className="font-semibold" htmlFor={`${props.formElementGuid}_count_${index}`}>{props.countLabel}</label>
                        <br/>
                        <DecimalInput
                            id={`${props.formElementGuid}_count_${index}`}
                            rangepart="count"
                        />
                    </div>
                    <div className="w-full flex-none md:w-1/12 print:w-1/12">
                        <label className="font-semibold" htmlFor={`${props.formElementGuid}_price_${index}`}>{props.priceLabel}</label>
                        <br/>
                        <DecimalInput
                            id={`${props.formElementGuid}_price_${index}`}
                            rangepart="price"
                        />
                    </div>
                    <div className="w-full md:w-8/12 print:w-8/12">
                        <label className="font-semibold" htmlFor={`${props.formElementGuid}_name_${index}`}>{props.nameLabel}</label>
                        <br/>
                        <input id={`${props.formElementGuid}_name_${index}`} type="text" data-rangepart="name" name={`${props.formElementGuid}_name_${index}`} className="w-full" />
                        
                    </div>
                    <div className="w-full md:w-2/12">
                    {x === myRowCount - 1 &&
                            <button type="button" className="lg:ml-3 btn btn--secondary font-semibold flex items-center" onClick={() => { rows = rows.splice(index, 1); setRowCount(myRowCount - 1) }}>
                                <span className="icon icon--custom pr-1">cancel</span>                                
                                Fjern linje                                
                            </button>
                    }
                    </div>
                </div>)
            )}


            <div className="my-6">
                <button type="button" className="btn btn--secondary flex items-center font-semibold" onClick={() => setRowCount(myRowCount + 1)}>
                    <span className="icon icon--custom pr-1">add_circle_outline</span>
                    Legg til flere linjer
                </button>
            </div>
        </>
    );

}