import { Component } from 'react';
import { connect } from 'react-redux';
import { initSearch } from './prospectusRegistryActions';
import { prospectusRegistrySearchStateSelector } from './prospectusRegistrySearchStateSelector';

class ProspectusRegistrySearchState extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { dispatch } = this.props;
        dispatch(initSearch(this.props));
        return null;
    }
}

export default connect(prospectusRegistrySearchStateSelector)(ProspectusRegistrySearchState);
