/* eslint-disable no-restricted-globals */
import { document } from "../util/ssrBase";

export const NAME = 'annualReportSubPage';
const subMenuElements: HTMLLIElement[] = [];
const anchorHeadings = Array.from(document.getElementsByTagName('h2')) as HTMLElement[];
const menuUl = document.getElementById("page-menu-list");
const menuUlDesktop = document.getElementById("page-menu-list-desktop");
const wrapper = document.getElementById("hash-list-wrapper");

export function init(): void {
    injectH2Ids();
    createPageMenu();
    scrollToHash();
    registerEventListener();
}

const injectH2Ids = ()  => {    
    anchorHeadings.forEach((item) => {
        item.setAttribute("id", `${item.innerText}`);
    })    
}

const createPageMenu = () => {
    if (!menuUl || !menuUlDesktop) return;

    anchorHeadings.forEach((item) => {
        item.setAttribute("id", `${item.innerText}`);

        const menuItem = document.createElement("li");
        const menuItemLink = document.createElement("a");
        menuItemLink.setAttribute("href", `#${item.innerText}`);
        menuItem.classList.add('pt-2','pb-2');
        menuItemLink.innerText = item.innerText;
        menuItem.appendChild(menuItemLink);
        menuUlDesktop.appendChild(menuItem);
        subMenuElements.push(menuItem);
        menuUl.appendChild(menuItem.cloneNode(true));  
    })
}

const scrollToHash = () => {
    const urlHash = decodeURI(window.location.hash);

    if (urlHash) {
        const h2Element = document.getElementById(urlHash.substring(1, urlHash.length));
        if (h2Element) {
            h2Element.scrollIntoView();
        }
    }
}

const registerEventListener = () => {
    addActiveClassToSubMenuItem();
    window.addEventListener('scroll', () => {
        if (subMenuElements.length > 0) updateHash();
        addActiveClassToSubMenuItem();
    });
    wrapper?.addEventListener('click', () => {
        wrapper?.classList.toggle('--open');
        menuUl?.classList.toggle("--open");
    })
};

const addActiveClassToSubMenuItem = () => {
    // Set timeout to give page time to figure out hrefs
    setTimeout(() => {
        const locationHref = window.location.href;

        subMenuElements.forEach((el) => {
            const childAnchorElement = el.getElementsByTagName('a')[0];
            if (childAnchorElement) {
                if (childAnchorElement.href === locationHref) {
                    el.classList.add('active');
                } else {
                    el.classList.remove('active');
                }
            }
        });
    }, 300);
};

const updateHash = () => {
    anchorHeadings.forEach((heading) => {
        if (heading.getBoundingClientRect().top < heading.clientHeight) {
            if (history.pushState) {
                history.pushState(null, '', `#${heading.id}`);
            } else {
                location.hash = `#${heading.id}`;
            }
        }
    });
};
