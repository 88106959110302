import React, { FC, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchResults from '../components/search/searchResults';
import { trackEvent } from '../eventTracking';
import { Paginator } from '../components/search/Paginator';
import { investorWarningsSlice } from './state/investorWarningsReducer';
import { InvestorWarningRootState } from './state/store';
import { useSearchInvestorWarningsQuery } from './state/investorWarningsApi';
import { LanguageContext } from '../components/LanguageContext';
import { SkeletonLoader } from '../components/SkeletonLoader';

export const InvestorWarningsSearchResults : FC = () => {

    const state = useSelector((s: InvestorWarningRootState) => s.investorWarningState);

    const languageContext = useContext(LanguageContext);
    const { data, isLoading, isFetching } = useSearchInvestorWarningsQuery(state.queryString);
    const dispatch = useDispatch();

    if(data === undefined || isFetching || isLoading)
        return <SkeletonLoader type="list"  />

    const canLoadMore = data !== undefined
        && (data.totalPages > 1)
        && (data.totalPages >= data.page);

    const {actions} = investorWarningsSlice;
    
    return (
        <div>
            {data && 
                <SearchResults
                    searchResult={data}
                    hasSortingOptions
                    sortResults={state.sortAlphabetically}
                    sortAction={() => dispatch(actions.sortResults())}
                />
            }
            {canLoadMore && (
                <Paginator 
                    onLoadMoreClickPrev={
                        () => {
                            trackEvent('Forrige side søketreff markedsadvarsler', `Side ${data?.page}`);
                            dispatch(actions.decrementPage);
                            window.scrollTo(0, 0);
                        }
                    }
                    onLoadMoreClickNext={() => {
                        trackEvent('Neste side søketreff markedsadvarsler', `Side ${data?.page}`);
                        dispatch(actions.incrementPage());
                        window.scrollTo(0, 0);
                    }}
                    onJumpToPageClick={(page) => {
                        trackEvent('Hopp til side markedsadvarsler', `Side ${page}`);
                        dispatch(actions.setPage(page));
                        window.scrollTo(0, 0);
                    }}
                    language={languageContext.code}
                    searchResultPage={data.page}
                    searchResultTotalPages={data.totalPages}
                />                
            )}
        </div>

    );
}
