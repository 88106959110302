import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { trackEvent } from '../../eventTracking';
import { SupervisionCategoryGroup, SupervisionCategoryViewModel } from '../../generatedTypes/FT.Web.ViewModels';

interface SupervisionState {
    expandedSectionId: number;
    checkedSupervisionCategories: number[];
    loaded: boolean;
    supervisionCategories: SupervisionCategoryViewModel,
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string | null
}

const initialState: SupervisionState = {
    expandedSectionId: -1,
    checkedSupervisionCategories: [],
    status: 'idle',
    error: null,
    loaded: false,
    supervisionCategories: {} as SupervisionCategoryViewModel
};

export const fetchCategories = createAsyncThunk('supervision/fetchCategories', async (lang: string) => {
    const filterUrl = `/api/supervision/list?l=${lang}`;
    const response = await fetch(filterUrl);
    return response.json();
  })

const supervisionReduxSlice = createSlice({
    name: 'supervision',
    initialState,
    reducers: {
        fetchSupervisionCategories: (state, action: PayloadAction<string>) => {
            const filterUrl = `/api/supervision/list?l=${action.payload}`;
            fetch(filterUrl).then((response) =>
                response.json().then((result : SupervisionCategoryViewModel) => {
                    state.supervisionCategories = result;
                    state.loaded = true;
                }))
        },
        toggleAll: (state, action: PayloadAction<{ supervisionCategoryGroup: SupervisionCategoryGroup, allSelected: boolean }>) => {
            if (!action.payload.allSelected) {
                trackEvent('Alle områder valgt i kategori', action.payload.supervisionCategoryGroup.name);

                state.checkedSupervisionCategories
                .filter(item => action.payload.supervisionCategoryGroup.supervisionCategories
                    .map((mappedItem) => mappedItem.id)
                    .indexOf(item) < 0);

                state.checkedSupervisionCategories.push(...action.payload.supervisionCategoryGroup.supervisionCategories.map((item) => item.id));
            }
            else {
                trackEvent('Alle områder u-valgt i kategori', action.payload.supervisionCategoryGroup.name);
                const items = action.payload.supervisionCategoryGroup.supervisionCategories.map((item) => item.id);
                state.checkedSupervisionCategories = state.checkedSupervisionCategories.filter(
                (item) => items.indexOf(item) < 0
                );
            }

        },
        toggleExpandedSection(state, action: PayloadAction<number>) {
            if (action.payload === state.expandedSectionId) {
                state.expandedSectionId = -1;
            }
            else {
                state.expandedSectionId = action.payload;
            }
        },
        checkSupervisionCategory(state: SupervisionState, action: PayloadAction<number>) {
            if (state.checkedSupervisionCategories.includes(action.payload)) {

                state.checkedSupervisionCategories = state.checkedSupervisionCategories.filter(
                    (item: number) => item !== action.payload
                );

            }
            else {
                state.checkedSupervisionCategories.push(action.payload);
            }
        },

        filtersLoaded(state: SupervisionState, action: PayloadAction<SupervisionCategoryViewModel>) {
            state.supervisionCategories = action.payload;
            state.loaded = true;
        },
        resetSupervisionCategories(state: SupervisionState) {
            state.checkedSupervisionCategories = [];
        }            
    },
    extraReducers(builder) {
        builder
          .addCase(fetchCategories.pending, (state) => {
            state.status = 'loading'
          })
          .addCase(fetchCategories.fulfilled, (state, action) => {
            state.status = 'succeeded'
            // Add any fetched posts to the array
            state.supervisionCategories = action.payload;
            
            const savedItemsString = localStorage.getItem('supervisionCategories');
            
            const savedItems = savedItemsString !== null ? JSON.parse(savedItemsString) as number[] : [] as number[];
            state.checkedSupervisionCategories = savedItems || [];
          })
          .addCase(fetchCategories.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message === undefined ? null : action.error.message
          })
      }
}
);

export const { 
    checkSupervisionCategory,
    fetchSupervisionCategories,
    filtersLoaded,
    resetSupervisionCategories,
    toggleAll,
    toggleExpandedSection } = supervisionReduxSlice.actions;
export const supervisionReducer = supervisionReduxSlice.reducer;
