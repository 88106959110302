import { window } from './util/ssrBase';

export const trackEvent = (actionName, actionValue) => {
    if (!window.location.href.toLowerCase().match('episerver')) {
        try {
            _paq.push([
                'trackEvent',
                'ft.no', // event category
                actionName, // event action name
                actionValue, // event name
            ]);            
        } catch (e) {
            // Can i.e be tracker blocking. Do nothing.
        }
    }
};
