import zenscroll from 'zenscroll';
import scrollspy from '../scrollspy';
import { document, window } from '../util/ssrBase';

export const NAME = 'calendarPage';
const pastEventsArray = Array.from(document.getElementsByClassName('past-event-container'));
const lastPastEvent = pastEventsArray[pastEventsArray.length - 1];
const url = new URLSearchParams(window.location.search);

export const init = (): void => {
    setFuturePastSeparator();
    registerCalendarClickEvents();
    scrollspy();

    const duplicateMonth = getDuplicateMonth();
    if (url.get('showPastEvents')) {
        duplicateMonth?.classList.toggle('hidden');
    }
};

const setFuturePastSeparator = () => {
    if (lastPastEvent) {
        const separator = Array.from(lastPastEvent.getElementsByClassName('future-past-separator'));
        const separatorContainer = Array.from(
            lastPastEvent.getElementsByClassName('future-past-separator-container')
        );

        if (separator[0]) {
            separator[0].classList.add('show');
        }

        if (separatorContainer[0]) {
            separatorContainer[0].classList.add('show');
        }
    }
};

const getDuplicateMonth = () => {
    // Need to find the duplicate month title to hide it when past events are expanded
    const months = Array.from(document.getElementsByClassName('calendar-month'));
    const monthsHTMLElements = months.map((m) => m as HTMLElement);
    let month = '';
    let duplicateMonth: HTMLElement | undefined;
    monthsHTMLElements.forEach((m) => {
        if (m.classList[1] === month) {
            duplicateMonth = m;
        }

        // eslint-disable-next-line prefer-destructuring
        month = m.classList[1];
    });

    return duplicateMonth;
};

export const scrollToThirdLastHistoricalEvent = (): void => {
    const thirdToLastElement = pastEventsArray[pastEventsArray.length - 3] as HTMLElement;

    if (!url.get('showPastEvents')) {
        zenscroll.toY(0, 400);
    } else {
        thirdToLastElement && zenscroll.to(thirdToLastElement, 400);
    }
};

export const registerCalendarClickEvents = (): void => {
    const container = Array.from(document.getElementsByClassName('calendar-row'));

    container.forEach((node) => {
        const pastYearsButtons = node.querySelector('.past-years-buttons');
        const showPastEventsButton = document.getElementById('js-show-past-events-button');
        const separator = node.querySelector('.future-past-separator.show');
        const firstMonthFutureYear = document.getElementById('first-month-future-year');
        const pastEvents = node.querySelector('.past-events');
        const grayBackground = node.querySelector('.gray-background');
        const pastEventsButtonText = node.querySelector('.past-events-button-text');
        const pastEventButtonIcon = node.querySelector('.past-event-button-icon');

        const duplicateMonth = getDuplicateMonth();

        if (showPastEventsButton) {
            showPastEventsButton.onclick = () => {
                pastYearsButtons?.classList.toggle('hidden');
                pastEvents?.classList.toggle('hidden');

                if (url.get('showPastEvents')) {
                    url.delete('showPastEvents');
                    window.history.pushState({}, '', url.toString());
                } else {
                    url.set('showPastEvents', 'true');
                    window.history.pushState({}, '', `?${url.toString()}`);
                }

                if (!url.get('showPastEvents') && pastEventsButtonText && pastEventButtonIcon) {
                    pastEventsButtonText.innerHTML = 'Vis tidligere hendelser';
                    pastEventButtonIcon.innerHTML = 'expand_more';
                } else if (pastEventsButtonText && pastEventButtonIcon) {
                    pastEventsButtonText.innerHTML = 'Skjul tidligere hendelser';
                    pastEventButtonIcon.innerHTML = 'expand_less';
                }

                grayBackground?.classList.toggle('expanded');
                separator?.classList.toggle('visible');
                firstMonthFutureYear?.classList.toggle('no-top-margin');
                duplicateMonth?.classList.toggle('hidden');

                scrollToThirdLastHistoricalEvent();
            };
        }
    });
};
