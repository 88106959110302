import React, { FC, useState } from "react";
import { AgentOrBranchVm } from "../generatedTypes/FT.Web.ViewModels.Vreg";
import { translateLang } from "../translate";

interface Props {
    Items: AgentOrBranchVm[],
    languageCode: string,
    isBranch: boolean
}

export const ToggleList: FC<Props> = (props: Props) => {
    const [expanded, setExpanded] = useState(false);
    const instanceType: string = props.isBranch ? 'branch' : 'agents';

    return (
        <>
            <h3 className=" text-xl font-semibold mb-2">
                {translateLang(`vreg.${instanceType}.heading`, props.languageCode, [props.Items[0].country])}
            </h3>

            {/* <!-- Switch --> */}
            {props.Items.length > 5 &&
                <>
                    <label className="checkbox--switch cursor-pointer mb-2 inline-flex space-x-3" htmlFor="slider">
                        <div>
                            <span className="material-icons text-md">remove_red_eye</span>
                            {translateLang(`vreg.${instanceType}.showList`, props.languageCode, [props.Items.length])}
                        </div>
                        <div className="relative">
                            <input type="checkbox" id="slider" checked={expanded} onChange={() => setExpanded(!expanded)} />
                            <span className="checkbox--switch__slider" />
                        </div>
                    </label>
                    <p className=" text-sm text-yellow-600 italic mb-2">
                        {translateLang(`vreg.${instanceType}.showListDescription`, props.languageCode)}
                    </p>
                </>
            }
            {/* <!-- // Switch --> */}

            {/* <!-- List of agents --> */}
            <ul className="mb-6 list-disc list-inside text-bluegreen-500 flex flex-col space-y-1">
                {props.Items.map((item, index) =>
                    <li className={`${expanded || index <= 4 ? '' : 'hidden'}`} key={`${instanceType}_${item.agentOrBrancReference.id}`}>
                        <a href={`?id=${item.agentOrBrancReference.id}`} title={item.agentOrBrancReference.name} className="text-base text-bluegreen-500 uppercase hover:underline hover:decration-1 hover:underline-offset-4">
                            {item.agentOrBrancReference.name}
                        </a>
                        <span  className=" text-sm text-black-600">
                             &nbsp;({item.country})
                        </span>
                    </li>
                )}

                {/* <!-- When expanded list, add class hidden to LI --> */}
                {props.Items.length > 5 &&
                    <li className={`pl-6 list-none ${expanded ? 'hidden' : ''}`} key={`${instanceType}_button`}>
                        <button
                            aria-label={translateLang(`vreg.${instanceType}.showList`, props.languageCode)} title={translateLang(`vreg.${instanceType}.showList`, props.languageCode)}
                            onClick={() => setExpanded(!expanded)}
                            type="button" className="text-bluegreen-500 hover:underline hover:decration-1 hover:underline-offset-4">
                            <span className="sr-only">{translateLang(`vreg.${instanceType}.showList`, props.languageCode)}</span>
                            ...
                        </button>
                    </li>
                }
            </ul>
        </>);
}