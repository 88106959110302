import { window } from "../util/ssrBase";
import { replaceUrlParams } from "../util/urlHelpers";

export const overrideSubjectSelectorKey = 'overrideSubjectSelector';

function getActiveMetaFilters(metaDataFilterItems) {    
    return metaDataFilterItems?.activeFilter === -1 ? [] : [metaDataFilterItems?.activeFilter];
}

function createDateUrl(years) {
    if(years === undefined || years.items.length === 0)
        return '';

    return years.items.map((id) => `&years=${id}`).join('') || '';
}

function createTypeFilterUrl(typefilters) {
    if(typefilters === undefined || typefilters.length === 0)
        return '';

    return typefilters.map((id) => `&t=${id}`).join('') || '';
}

function createMetadataUrl(metaDataFilterItems) {
    if(metaDataFilterItems.length === 0)
        return '';

    let queryString = '';
    for (const item of metaDataFilterItems) {
        queryString += `&metaData=${item}`;
    }
    return queryString;
}

function createSupervisionFilterUrl(supervisionCategories){
    if(supervisionCategories.length === 0)
        return '';

    let queryString = '';
    for (const item of supervisionCategories) {
        queryString += `&supervisionCategories=${item}`;
    }
    return queryString;
}

function getPageFromQueryString(){
    const params = new URLSearchParams(window.location.search.substring(1));
    return params.get('page');
}

function getOverrideSubjectSelectorFromQueryString(){
    const params = new URLSearchParams(window.location.search.substring(1));
    const override = params.get(overrideSubjectSelectorKey) || '';
    if(override)
        return `&${overrideSubjectSelectorKey}=yes&scName=${params.get('scName')}`
    return ''
}

function newsArchiveUrlBuilder(state, newPage) {
    
    const activeFilters = state.typeFilters?.activeFilters;
    const metaDataFilterItems = state.metaDataFilters;
    const years = state.dateFilters ?? [];
    const query = state.searchBox?.queryString ?? '';    
    const page = newPage ?? getPageFromQueryString();

    return `?query=${query}${createTypeFilterUrl(activeFilters)}${createDateUrl(years)}${createMetadataUrl(getActiveMetaFilters(metaDataFilterItems))}&page=${page}&language=${state.settings.lang}${createSupervisionFilterUrl(state.supervisionCategories?.activeItems)}${getOverrideSubjectSelectorFromQueryString()}`;
}

function newsarchiveSearchStateSelector(state, currentPage) {    
    const activeFilters = state.typeFilters?.activeFilters;
    const metaDataFilterItems = state.metaDataFilters;
    const years = state.dateFilters;
    const query = state.searchBox.queryString || '';
        
    const supervision = state.supervisionCategories.override ? [] : state.supervisionCategories;
    const { settings } = state;
    const page = currentPage ?? getPageFromQueryString();
    const url = newsArchiveUrlBuilder(state, currentPage);
    replaceUrlParams(url);

    return {
        isValid:
            activeFilters.length > 0 || metaDataFilterItems.activeFilter !== -1 || query !== '',
        queryString: query,
        activeFilters,
        activeMetaFilters: getActiveMetaFilters(metaDataFilterItems),
        dateFilters: years,
        supervisionCategories: supervision,
        lang: settings.lang,
        page,
        url,
    };
}

export { newsarchiveSearchStateSelector, newsArchiveUrlBuilder };
