import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Checkbox } from '../forms/checkbox';
import { SupervisionCategoryViewModel, SupervisionCategory } from '../../generatedTypes/FT.Web.ViewModels';
import { translateLang } from '../../translate';
import { LanguageContext } from '../LanguageContext';
import { SelectedSubjects } from './selectedSubjects';
import { checkSupervisionCategory, fetchCategories, resetSupervisionCategories, toggleAll, toggleExpandedSection } from './supervisionReducer';
import { AppDispatch } from '../../redux/supervisionStore';
import { isMobile } from '../../util/ssrBase';

interface InputProps {
    dropdownText: string;
}

interface SubjectSelectorProps {
    supervisionCategories: SupervisionCategoryViewModel,
    checkedSupervisionCategories: number[],
    expandedSectionId: number,
    status: string
}

type AllProps = SubjectSelectorProps & InputProps;

const SubjectSelectorComponent: React.FC<AllProps> = (props): JSX.Element => {
    const dispatch = useDispatch<AppDispatch>();
    const languageContext = useContext(LanguageContext);

    const [expanded, setExpanded] = useState(!isMobile);

    useEffect(() => {
        if(props.status === 'idle'){
            dispatch(fetchCategories(languageContext.code));
        }
    }, [dispatch]);

    const toggleExpand = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setExpanded(!expanded);
    };

    const areAllSelected = (items: SupervisionCategory[]): boolean => {
        let allSelected = true;

        items.map((item) => {
            if (props.checkedSupervisionCategories.indexOf(item.id) === -1) {
                allSelected = false;
            }
            return allSelected;
        }
        );
        return allSelected;

    };

    const isExpanded = (id: number): boolean => props.expandedSectionId === id;

    const isSelected = (id: number): boolean => props.checkedSupervisionCategories && props.checkedSupervisionCategories.indexOf(id) !== -1;

    const categorySelectedCount = (cats: SupervisionCategory[]): number => 
        cats.filter(category => isSelected(category.id)).length;
    

    return (
        <div className='w-full mb-6'>
            <div className="w-full border-t border-b border-l-4 border-r border-bluegreen-300 bg-white-400 relative">
                <button title={translateLang('subjectSelector.buttons.open', languageContext.code)}
                    type="button"
                    className="flex items-center justify-between w-full p-4 cursor-pointer hover:text-bluegreen-500 text-black-400 text-md"
                    aria-expanded={expanded}
                    onClick={toggleExpand}
                >
                    <span>{props.dropdownText ? props.dropdownText : translateLang('subjectSelector.dropdownTextFallback', languageContext.code)}</span>
                    <span className="material-icons" aria-hidden="true">{expanded ? "close" : "expand_more"}</span>
                </button>

                
                {props.checkedSupervisionCategories?.length > 0 && (
                    <SelectedSubjects
                        checkedSupervisionCategories={props.checkedSupervisionCategories}
                        checkSupervisionCategory={checkSupervisionCategory}
                        supervisionCategories={props.supervisionCategories} />
                )}                 

                {expanded &&
                    <div className="w-full lg:left-0 lg:z-50">
                        <div className="p-4 border-b border-l-4 border-r border-bluegreen-300 bg-white-400 -ml-[4px] -mr-[1px]" id="subjectareaselector" aria-hidden="false">

                            {props.supervisionCategories?.supervisionCategories?.map((item) => (
                                <section key={item.id}>
                                    <button title={`${translateLang('subjectSelector.subjectAreaTitle', languageContext.code)} ${item.name}`}
                                        type="button"
                                        className=
                                        {`flex items-center justify-between w-full py-2 pr-3 text-sm font-semibold
                                            border-b border-gray-400 cursor-pointer ${isExpanded(item.id) ? "text-bluegreen-500" : "text-black-400"}
                                            hover:text-bluegreen-500`}
                                        aria-expanded={isExpanded(item.id)}
                                        aria-controls="content1"
                                        onClick={() => dispatch(toggleExpandedSection(item.id))}
                                    >
                                        <span className="text-left">
                                            {item.name}
                                            &nbsp;
                                            {categorySelectedCount(item.supervisionCategories) > 0 ?
                                                `(${categorySelectedCount(item.supervisionCategories)})` : ""}
                                        </span>
                                        {isExpanded(item.id) ?
                                            (
                                                <span className="flex items-center font-normal">{translateLang('subjectSelector.buttons.close', languageContext.code)}
                                                    <span className="ml-1 material-icons" aria-hidden="true">close</span>
                                                </span>)
                                            :
                                            (<span className="material-icons" aria-hidden="true">
                                                add
                                            </span>)
                                        }
                                    </button>

                                    {isExpanded(item.id) &&
                                        <ul
                                            key={`categories_${item.id}`}
                                            className="py-1 border-b border-gray-400"
                                            id="content2"
                                            aria-hidden="false">

                                            {item.supervisionCategories.length > 1 && (
                                                <li className="checkbox--custom" key={`${item.id}_all`} >
                                                    <Checkbox
                                                        id={item.id.toString()}
                                                        label={translateLang('subjectSelector.buttons.selectAll', languageContext.code)}
                                                        checked={areAllSelected(item.supervisionCategories)}
                                                        onChange={() => {dispatch(toggleAll({ supervisionCategoryGroup: item, allSelected: areAllSelected(item.supervisionCategories)})) }} />
                                                </li>
                                            )}
                                            {item.supervisionCategories.map((cat) => (
                                                <li className="checkbox--custom" key={`category_${cat.id}`} >
                                                    <Checkbox
                                                        id={cat.id.toString()}
                                                        label={cat.name}
                                                        checked={isSelected(cat.id)}
                                                        onChange={() => { dispatch(checkSupervisionCategory(cat.id))}} />
                                                </li>
                                            ))
                                            }
                                        </ul>
                                    }

                                </section>
                            ))}

                            <div className={`flex ${props.checkedSupervisionCategories?.length > 0 ? "justify-between" : "justify-end"} mt-6`}>
                                {props.checkedSupervisionCategories?.length > 0 && (<button
                                    type="button"
                                    title={translateLang('subjectSelector.buttons.reset', languageContext.code)}
                                    className="flex items-center justify-between text-black-400 hover:text-bluegreen-500 group"
                                    onClick={() => dispatch(resetSupervisionCategories())}
                                >
                                    <span className="underline group-hover:no-underline">{translateLang('subjectSelector.buttons.reset', languageContext.code)}</span>
                                </button>
                                )}

                                <button
                                    type="button"
                                    title={translateLang('subjectSelector.buttons.close', languageContext.code)}
                                    className="flex items-center justify-between text-black-400 hover:text-bluegreen-500 group"
                                    onClick={toggleExpand}
                                >
                                    <span className="underline group-hover:no-underline">{translateLang('subjectSelector.buttons.closeSelector', languageContext.code)}</span>
                                    <span className="ml-2 material-icons" aria-hidden={!expanded}>close</span>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>

    );
};


const mapStateToProps = (state: SubjectSelectorProps) => ({
    supervisionCategories: state.supervisionCategories,
    checkedSupervisionCategories: state.checkedSupervisionCategories,
    expandedSectionId: state.expandedSectionId,
    status: state.status
});

export const SubjectSelector = React.memo( connect(mapStateToProps)(SubjectSelectorComponent));