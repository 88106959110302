import React, { ReactNode } from 'react';
import { TableCell } from '../../generatedTypes/FT.EpiServer.Models.Table';
import { b64, isNumeric, numberFormat } from '../../util/utilityFunctions';

interface Props {
    array?: TableCell[][] | null,
    fixedArray?: Array<{ name: string, x: string[], y: TableCell[] }> | null,
    tableIsFixed: boolean,
    sorting?: {
        index: number,
        setIndex: (index: number) => void,
        reverse: boolean
    } | null,
    bodyRef: string,
    noFirstCol: boolean,
    rightAlignContent: boolean
}

export class Tbody extends React.Component<Props, unknown> {

    handleSortingOnClick(index: number): void | null {
        if (this.props.sorting && this.props.sorting.setIndex)
            return this.props.sorting.setIndex(index);
        return null;
    }

    getItemContent = (item: TableCell): ReactNode => {
        if (item && item.value) {
            if (item.url && item.url !== "") {
                return <a href={item.url}>{item.value}</a>
            }
            if (isNumeric(parseFloat(item.value))) {
                return numberFormat({ num: item.value, format: 'nb' })
            }
            return item.value;
        }
        if (!item)
            return null;
        return isNumeric(parseFloat(item.value)) ? numberFormat({ num: item.value, format: 'nb' }) : item.value;
    }

    rowEmpty = (row: TableCell[]): boolean => {
        let isEmptyRow = true;
        row.forEach(cell => { if (cell.value) { isEmptyRow = false; } });
        return isEmptyRow;
    }

    getHeadingContent = (item: string): string => (item)

    render(): JSX.Element {
        const {
            array,
            fixedArray,
            tableIsFixed
        } = this.props;

        return (
            <tbody ref={this.props.bodyRef}>
                {!tableIsFixed && array &&
                    array.map((row, index) => {
                        if ((index === 0 || index === array.length - 1) && this.rowEmpty(row)) {
                            return null;
                        }
                        return (
                            <tr key={b64.encode(`tr-key__${row}-${index}`)}>
                                {row.map((item, i) => {
                                    if (i === 0 && !this.props.noFirstCol) {
                                        return (
                                            <th
                                                scope="row"
                                                key={b64.encode(`th-key__${item}-${index}-${i}`)}
                                                className={`${this.props.rightAlignContent ? 'text-right' : ''}`}
                                            >
                                                {this.getItemContent(item)}
                                            </th>
                                        );
                                    }
                                    return (
                                        <td key={b64.encode(`td-key__${item}-${index}-${i}`)}>
                                            <div className={`${this.props.rightAlignContent ? 'text-right' : ''}`}>
                                                {this.getItemContent(item)}
                                            </div>
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })
                }
                {tableIsFixed && fixedArray &&
                    fixedArray.map((row, index) => {
                        if (index === 0) {
                            return null;
                        }
                        return (
                            <tr key={b64.encode(`tr-key--tableIsFixed-${index}`)}>
                                <th scope="row" className='sticky left-0 w-[200px] border border-b-white-400'>
                                    {row.x}
                                </th>
                                {row.y &&
                                    row.y.map((item: TableCell, i) => (
                                        <td key={b64.encode(`td-key--tableIsFixed ${item}-${index}-${i}`)}>
                                            {this.getItemContent(item)}
                                        </td>
                                    ))}
                            </tr>
                        );
                    })
                }
            </tbody>
        );
    }
}