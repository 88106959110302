import React from "react"
import ICalendarLink from 'react-icalendar-link'

interface AddToCalendarProps {
    description: string,
    label: string,
    title: string,
    startTime: string,
    endTime: string
}

export const AddToCalendarButton: React.FC<AddToCalendarProps> = (props): JSX.Element | null => {
    const event = {
        title: props.title,
        description: props.description,
        startTime: props.startTime,
        endTime: props.endTime,
        location: "",
        attendees: []
    }

    if (Date.parse(event.endTime) > new Date().getTime()) {
        return (
            <ICalendarLink event={event} className="btn btn--secondary inline-flex items-center" aria-label={props.label}>
                <span>{props.label}</span>
                <span className="material-icons ml-3 w-4" aria-hidden="true">calendar_today</span>
            </ICalendarLink>
        );
    }

    return null;
}
