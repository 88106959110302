import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { trackEvent } from '../eventTracking';
import { SearchResultSuggestion } from '../generatedTypes/FT.Web.Search.Models';
import { Actions } from '../searchpage/data/actions';
import { SuggestSearchRequestState } from '../searchpage/data/reducer';
import { SearchInput } from '../searchpage/searchInput';
import { window } from '../util/ssrBase';
import { Store } from '.';

interface PassedProps {
    searchPageUrl: string;
}
interface ConnectedProps {
    suggestedResult?: SearchResultSuggestion[];
}

type ConnectedDispatch = ReturnType<typeof mapDispatchToProps>;

type Props = PassedProps & ConnectedProps & ConnectedDispatch;

const FrontPageSearchBoxContainer: React.FC<Props> = (props): JSX.Element => {
    const handleSearchButtonClick = (newQuery: string) => {
        trackEvent('Søk forside', newQuery);
        window.location.assign(`${props.searchPageUrl}?q=${newQuery}`);
    };

    return (
        <div className="py-6 mb-6 bg-gradient-to-r from-bluegreen-600 to-bluegreen-300">
            <div className="js-header-container">
                <div className="container flex justify-center">
                    <div className="relative w-full xl:w-1/2">
                        <SearchInput
                            onSearchButtonClick={handleSearchButtonClick}
                            isSearchPage={false}
                            inputId="search"
                            autoFocus={false}
                            {...props}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    requestSuggestedResult: (state: SuggestSearchRequestState) =>
        dispatch(Actions.fetchSuggestedResult.request(state))
});

function mapStateToProps(state: Store): ConnectedProps {
    return {
        suggestedResult: state.suggestedResult.suggestedResult
    };
}

export const FrontPageSearchBox = connect(
    mapStateToProps,
    mapDispatchToProps
)(FrontPageSearchBoxContainer);
