import React from "react";

interface Language {
    code: string
}

export const languages = {
    Norwegian: {
        code: 'no'
    },
    English: {
        code: 'en'
    }
}

export const getLanguage = (languageCode : string) : Language => {
    if(languageCode === languages.Norwegian.code || languageCode === 'nb') // fallback for episerver norwegian code 'nb'
        return languages.Norwegian;

    return languages.English;
        
}

export const LanguageContext = React.createContext(languages.Norwegian);