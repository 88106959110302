import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadMore } from './prospectusRegistryActions';
import ProspectusRegistrySearchResultItems from './prospectusRegistrySearchResultItems';
import { prospectusRegistrySearchStateSelector } from './prospectusRegistrySearchStateSelector';
import { trackEvent } from '../eventTracking';
import { LanguageContext } from '../components/LanguageContext';
import { Paginator } from '../components/search/Paginator';

class ProspectusRegistrySearchResults extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { searchState, searchResult, dispatch } = this.props;

        const canLoadMore = searchResult
            && (searchResult.totalPages > 1)
            && (searchResult.totalPages >= searchResult.page);

        const language = this.context;

        return (
            <div>
                {!searchResult.loading && (
                    <ProspectusRegistrySearchResultItems searchResult={searchResult} />
                )}

                {canLoadMore && (
                    <Paginator
                        onLoadMoreClickPrev={() => {
                            trackEvent('Forrige side søketreff prospekter', `Side ${searchResult.page}`);
                            dispatch(loadMore(searchState, searchResult.page - 1));
                            window.scrollTo(0, 0);
                        }}
                        onLoadMoreClickNext={() => {
                            trackEvent('Neste side søketreff prospekter', `Side ${searchResult.page}`);
                            dispatch(loadMore(searchState, searchResult.page + 1));
                            window.scrollTo(0, 0);
                        }}
                        onJumpToPageClick={(page) => {
                            trackEvent('Hopp til side prospekter', `Side ${page}`);
                            dispatch(loadMore(searchState, page));
                            window.scrollTo(0, 0);
                        }}
                        language={language.code}
                        searchResultPage={searchResult.page}
                        searchResultTotalPages={searchResult.totalPages}
                    />
                )}
            </div>
        );
    }
}

ProspectusRegistrySearchResults.propTypes = {
    searchResult: PropTypes.object.isRequired
};

ProspectusRegistrySearchResults.contextType = LanguageContext;

function mapStateToProps(state) {
    return {
        searchResult: state.searchResult,
        searchState: prospectusRegistrySearchStateSelector(state)
    };
}

export default connect(mapStateToProps)(ProspectusRegistrySearchResults);
