/* Renders a list of checkboxes and a toggle-checkbox */
import React, { useState } from 'react';
import { translateLang } from '../../translate';
import { CheckBox } from './CheckBox'

interface CheckBoxListProps {
    areas: Area[],
    areaName: string,
    language: string,
}
export interface Area {
    name: string,
    selected: boolean,
    instanceId: string,
    supervisionCategory: boolean
}

export const CheckBoxList: React.FC<CheckBoxListProps> = (props): JSX.Element => {

    const [isChecked, setChecked] = useState(props.areas.map(m => m.selected));

    return (
        <fieldset className="py-6 border-t-4 border-black-100">
            {props.areaName &&
                <legend className="font-semibold text-xl mb-6 float-left">
                    {props.areaName}
                </legend>
            }

            {props.areas.length > 1 &&
                <ul className="flex flex-col space-y-3 lg:space-y-0 lg:flex-row lg:flex-wrap mt-6 lg:-m-3 lg:mt-3 clear-left">
                    <li className="checkbox--custom lg:p-3 newsletter-checkallcontainer">
                        <input
                            type="checkbox"
                            id={`${props.areaName ?? 'news_all'}`}
                            checked={isChecked.every(t => t === true)}
                            className="newsletter-checkbox"
                            onChange={() => {
                                // set selected to false if any selected
                                const anySelectedAreas = isChecked.find(f => f === true) !== true;
                                /* eslint-disable no-return-assign */
                                const toggledBoxes = props.areas.map(m => m.selected = anySelectedAreas);

                                setChecked(toggledBoxes);
                            }}
                        />
                        <label
                            htmlFor={props.areaName ?? 'news_all'}>
                            {translateLang('newsletter.toggleSelection', props.language)}
                        </label>
                    </li>
                </ul>
            }

            <ul className="flex flex-col space-y-3 lg:space-y-0 lg:flex-row lg:flex-wrap mt-6 lg:-m-3 lg:mt-3 newsletter-supervisioncategorygroup clear-left">
                {props.areas.map((area, index) =>
                (
                    <li className="checkbox--custom lg:p-3" key={area.instanceId}>
                        <CheckBox
                            {...area}
                            selected={isChecked[index]}
                            onChange={() => {
                                const updated = isChecked.map((m, i) => i === index ? m = !m : m);
                                setChecked(updated)
                            }}
                        />
                    </li>
                ))}

            </ul>
        </fieldset>
    );

}
