export const tableStyles = (): void => {
    const tables = Array.from(document.getElementsByClassName('table-from-word')) as HTMLElement[];

    tables.forEach((table) => {
        const th = Array.from(table.getElementsByTagName('th')) as HTMLElement[];
        const td = Array.from(table.getElementsByTagName('td')) as HTMLElement[];

        [...th, ...td].forEach((cell) => {
            const innerTextSpaceRemoved = cell.innerText.replace(/\s/g, '');
            const innerTextToNumber = Number(innerTextSpaceRemoved);

            // Prevent numbers breaking into multiple lines and align right
            if (Number.isInteger(innerTextToNumber)) {
                cell.style.whiteSpace = 'nowrap';
                cell.style.textAlign = 'right';
            }
        });
    });
};
