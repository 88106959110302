export function init() {
	function wrap(el, wrapper) {
        el.parentNode.insertBefore(wrapper, el);
        wrapper.appendChild(el);
    }

    document.querySelectorAll('.article--bodytext table').forEach( (table) => {
        const wrapper = document.createElement('div');
        wrapper.classList.add('article--bodytext__responsivetable');
        wrap(table, wrapper);
    });
}