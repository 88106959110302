import React from 'react';
import { ThemeList } from '../generatedTypes/FT.Web.ViewModels.Theme';

interface Props {
   list: ThemeList;   
}

export const ThemesGroupList: React.FC<Props> = (props)  => (            
    <div className="bg-white-400 p-6 mb-6">
        <div className=" ">
            <h2 className=" text-2xl font-semibold mb-6">{props.list?.title}</h2>
        </div>
        <ul className="lg:columns-2 lg:gap-16 xl:gap-24">
            {props.list?.listItems?.map((item, index) => (
                /* eslint-disable react/no-array-index-key */
                <li className="border-b-2 border-lightgray-400 break-inside-avoid-column flex justify-between items-center py-2 pr-2" key={`theme-group_${item.name}_${index}`}>
                   <a className="hover:underline no-underline decoration-1 underline-offset-4 hover:bg-bluegreen-100" href={item.url}>
                        <span className="theme-group-list__item__text" key={`${props.list.themeId}_${item.name}`} >{item.name}</span>
                   </a>
                    <span className="material-icons text-xl">chevron_right</span>
                </li>
                /* eslint-enable */
            ))}
        </ul>
    </div>            
);