import { window } from "./ssrBase";

const serverSide = false;

export const b64 = {
    encode: serverSide || ('btoa' in window) ? param => btoa(param) : param => param,
    decode: serverSide || ('atob' in window) ? param => atob(param) : param => param
};

export const isNumeric = (value) => Number(parseFloat(value)) === value;

export const numberFormat = (props) => {
    const {
        num,
        format = 'no-NO',
        minimumFractionDigits
    } = props;
    if (num === null) {
        return '';
    }
    try {
        if ('Intl' in window && 'NumberFormat' in window.Intl) {
            const number = new Intl.NumberFormat(format, { maximumFractionDigits: 3, minimumFractionDigits: minimumFractionDigits || 0 }).format(num);
            if (!Number.isNaN(number) && number !== 'NaN') {
                // workaround for strange number formats in excel inputs
                return number;
            }
        }
        if (num % 1) {
            return Number.parseFloat(num).toFixed(2).replace('.', ',');
        }
        return num;
    } catch (e) {
        return num;
    }
};

export const deepClone = arr => JSON.parse(JSON.stringify(arr));