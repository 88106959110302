import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import SearchResults from '../components/search/searchResults';
import { loadMore } from './actions';
import { newsarchiveSearchStateSelector } from './selectors';
import { trackEvent } from '../eventTracking';
import { LanguageContext } from '../components/LanguageContext';
import { Paginator } from '../components/search/Paginator';
import { window } from '../util/ssrBase';

const NewsArchiveSearchResults = ({ searchResult, searchState, dispatch }) => {
    const language = useContext(LanguageContext);
    const canLoadMore = searchResult
        && (searchResult.totalPages > 1)
        && (searchResult.totalPages >= searchResult.page);

    return (
        <div>
            <SearchResults searchResult={searchResult} />

            {canLoadMore && (
                <Paginator
                    onLoadMoreClickPrev={() => {
                        trackEvent('Forrige side søketreff nyhetsarkiv', `Side ${searchResult.page}`);
                        dispatch(loadMore(searchState, searchResult.page - 1));
                        window.scrollTo(0, 0);
                    }}
                    language={language.code}
                    onLoadMoreClickNext={() => {
                        trackEvent('Neste side søketreff nyhetsarkiv', `Side ${searchResult.page}`);
                        dispatch(loadMore(searchState, searchResult.page + 1));
                        window.scrollTo(0, 0);
                    }}
                    onJumpToPageClick={(page) =>{
                        trackEvent('Hopp til side søketreff nyhetsarkiv', `Side ${page}`);
                        dispatch(loadMore(searchState, page));
                        window.scrollTo(0, 0);
                    }}
                    searchResultPage={searchResult.page}
                    searchResultTotalPages={searchResult.totalPages}

                />
            )}
        </div>
    );
}

NewsArchiveSearchResults.propTypes = {
    searchResult: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        searchResult: state.searchResult,
        searchState: newsarchiveSearchStateSelector(state),
    };
}

export default connect(mapStateToProps)(NewsArchiveSearchResults);
