import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { InvestorWarningsSearchBox } from './investorWarningsSearchBox';
import { InvestorWarningsSearchFilters } from './InvestorWarningsSearchFilters';
import { InvestorWarningsSearchResults } from './InvestorWarningsSearchResults';
import { setScrollPosition } from '../util/persistScroll';
import { LanguageContext, getLanguage } from '../components/LanguageContext';
import { investorWarningsStore } from './state/store';
import { ExpandBox } from '../components/expandBox/ExpandBox';
import { TextBlock } from '../newsarchive/textblock';
import { isMobile } from '../util/ssrBase';

export const NAME = 'investorwarnings';

export interface InvestorWarningProps {
    language: string,
    pageName: string,
    aboutInvestmentFraudHeader: string,
    aboutInvestmentFraudBody: string,
    otherRegistriesHeader: string,
    otherRegistriesBody: string

}

export const InvestorWarnings: FC<InvestorWarningProps> = (props: InvestorWarningProps) => {
    setScrollPosition(NAME);

    return (
        <LanguageContext.Provider value={getLanguage(props.language)}>
            <Provider store={investorWarningsStore}>
                <div className="flex justify-end mb-6">
                    <div className="w-full lg:w-8/12 lg:pl-5">
                        <h1 className="text-2xl lg:text-3xl font-normal mb-6">{props.pageName}</h1>
                        <div className="flex w-full ">
                            <InvestorWarningsSearchBox />
                        </div>
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row lg:space-x-6">
                    <aside className="w-full lg:w-4/12 relative">
                        <div className="mb-6">
                            <InvestorWarningsSearchFilters />
                        </div>

                        {props.aboutInvestmentFraudHeader && props.aboutInvestmentFraudBody &&
                            <div className="mb-6">
                                <ExpandBox 
                                    titleString={props.aboutInvestmentFraudHeader} 
                                    expanded={!isMobile} >
                                    <TextBlock
                                        header={props.aboutInvestmentFraudHeader}
                                        mainBody={props.aboutInvestmentFraudBody}
                                    />
                                </ExpandBox>
                            </div>
                        }

                        {props.otherRegistriesHeader && props.otherRegistriesBody &&
                            <div className="mb-6">
                                <ExpandBox
                                    titleString={props.otherRegistriesHeader}
                                    expanded={!isMobile} >
                                    <TextBlock
                                        header={props.otherRegistriesHeader}
                                        mainBody={props.otherRegistriesBody}
                                    />
                                </ExpandBox>
                            </div>
                        }
                    </aside>

                    <div className="w-full lg:w-8/12">
                        <InvestorWarningsSearchResults />
                    </div>
                </div>
            </Provider>
        </LanguageContext.Provider >
    )

}

