import { trackEvent } from '../eventTracking';
import { clearSessionStorage, getSessionStorageResult } from '../util/persistScroll';
import { mapToUrlParams, replaceUrlParams } from '../util/urlHelpers';

export const SELECT_COUNTYFILTER = 'SELECT_COUNTYFILTER';
export const SELECT_CLASSFILTER = 'SELECT_CLASSFILTER';
export const FILTERS_LOADED = 'FILTERS_LOADED';
export const SEARCH_LOADED = 'SEARCH_LOADED';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const SELECT_DOCUMENTTYPE = 'SELECT_DOCUMENTTYPE';
export const LOADED_MORE = 'LOADED_MORE';

export function selectDocumentType(id, name, isChanged) {
    trackEvent('Filtrer dokumenttype', name);

    if (isChanged) {
        clearSessionStorage();
    }

    return {
        type: SELECT_DOCUMENTTYPE,
        id,
    };
}

export function selectClassFilter(id) {
    return {
        type: SELECT_CLASSFILTER,
        id,
    };
}

export function selectCountyFilter(id) {
    return {
        type: SELECT_COUNTYFILTER,
        id,
    };
}

export function searchLoaded(searchResult) {
    return {
        type: SEARCH_LOADED,
        searchResult,
    };
}

export function filtersLoaded(filters) {
    return {
        type: FILTERS_LOADED,
        filters,
    };
}

export function setSearchQuery(queryString, isChanged) {
    // Clear sessionStorage hvis query er endret for å få nye resultater
    if (isChanged) {
        clearSessionStorage();
    }
    trackEvent('Søk', queryString);
    return {
        type: SET_SEARCH_QUERY,
        queryString,
    };
}

export function loadedMore(searchResult) {
    trackEvent('Last flere', `Side ${searchResult.Page}`);
    return {
        type: LOADED_MORE,
        searchResult,
    };
}

export function fetchFilters(lang) {
    return (dispatch) =>
        fetch(`/api/search/prospectus/filters?language=${lang}`).then((response) =>
            response.json().then((filters) => dispatch(filtersLoaded(filters)))
        );
}

function getUrlParams(searchState, page) {
    const { queryString, lang, activeDocumentTypeFilterId } = searchState;
    return mapToUrlParams((addIfNotNull) => {
        addIfNotNull('q', queryString);
        addIfNotNull('l', lang);
        addIfNotNull('d', activeDocumentTypeFilterId, (id) => id !== -1);        
        addIfNotNull('p', page);
    });
}

function fetchSearch(searchState) {
    const searchApi = '/api/search/prospectus';
    replaceUrlParams(getUrlParams(searchState));

    const prospectusResult = getSessionStorageResult('prospectusResult');

    if (prospectusResult) {
        return (dispatch) => {
            dispatch(searchLoaded(prospectusResult));
        };
    }

    return (dispatch) =>
        fetch(`${searchApi}${searchState.url}`).then((response) =>
            response.json().then((result) => dispatch(searchLoaded(result)))
        );
}

export function initSearch(searchState) {
    return (dispatch) => dispatch(fetchSearch(searchState));
}

export function loadMore(searchState, currentPage) {
    const searchApi = '/api/search/prospectus';

    let q = searchState.url;
    q += `&p=${currentPage}`;

    return (dispatch) =>
        fetch(searchApi + q).then((response) =>
            response.json().then((result) => dispatch(loadedMore(result)))
        );
}
