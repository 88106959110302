import React from "react";

interface ArticleProps {
    PageName: string
}



export class ArticlePage extends React.PureComponent<ArticleProps> {

    render(): JSX.Element {
        return (
            <main>
                <h1>{this.props.PageName}</h1>
            </main>
        );
    }
}