import React, { useState } from "react";
import { MegaMenu } from "./MegaMenu";
import { ResponsiveMenuViewModel } from '../../generatedTypes/FT.Web.ViewModels/responsiveMenuViewModel';
import { translateLang } from "../../translate";
import { LanguageSelector } from "./LanguageSelector";

interface HeaderProps {
    rootUrl: string;
    menuVm: ResponsiveMenuViewModel;
    language: string;
}

export const Header: React.FC<HeaderProps> = (props): JSX.Element => {
    const [menuOpen, setMenuOpen] = useState(false);

    return (<>
        <header className="relative bg-white-400">
            <div className="container flex items-end py-4">
                <div className="w-1/2 lg:w-1/4 logo">
                    <a href={props.rootUrl} title="Tilbake til forsiden">
                        <img src="/Content/Images/logo.svg" className="globalheader-logo" alt="Finanstilsynet" width="231" height="76" />
                    </a>
                </div>

                <div className="flex items-center justify-end w-1/2 space-x-6 lg:w-3/4">
                    <div>
                        {menuOpen === false &&
                            <button
                                className="flex flex-row items-center px-4 py-2 space-x-2 text-sm font-semibold transition-all border-2 bg-lightgray-400 text-black-400 group border-white-400"
                                type="button"
                                onClick={() => setMenuOpen(true)}>
                                <span className="group-hover:underline">{translateLang('menu.buttons.expand', props.language)}</span>
                                <span className="text-lg material-icons text-bluegreen-500">menu</span>
                            </button>
                        }
                        {menuOpen === true &&
                            <button
                                className="flex flex-row items-center px-4 py-2 space-x-2 text-sm font-semibold transition-all border-2 bg-bluegreen-100 border-bluegreen-400 text-black-400 group"
                                type="button"
                                onClick={() => setMenuOpen(false)}>
                                <span className="group-hover:underline">{translateLang('menu.buttons.close', props.language)}</span>
                                <span className="text-lg material-icons text-bluegreen-500">close</span>
                            </button>
                        }
                    </div>
                    {props.menuVm?.searchPage &&
                        <div className="hidden lg:block">
                            <a href={props.menuVm.searchPage} title={translateLang('menu.labels.search', props.language)}
                                className="text-sm underline icon icon--search decoration-solid decoration-1 hover:no-underline"
                                aria-label={translateLang('menu.labels.search', props.language)}>
                                {translateLang('menu.labels.search', props.language)}
                            </a>
                        </div>
                    }
                    <div className="relative hidden text-sm lg:block">
                        <LanguageSelector
                            IsStartPage={props.menuVm?.isStartPage ?? false}
                            PageInOtherLanguage={props.menuVm?.pageInOtherLanguage}
                            StartPageInOtherLanguage={props.menuVm?.startPageInOtherLanguage}
                            Language={props.language}
                            QueryString={props.menuVm?.queryStringId}
                        />
                    </div>
                </div>
            </div>
        </header>

        {menuOpen &&
            <MegaMenu MenuVm={props.menuVm} Language={props.language} />
        }
    </>);
}