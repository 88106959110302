import React, { FC } from "react";
import { Provider } from "react-redux";
import { store } from "../Search/vregSearchStore";
import { Header } from "./Header";
import { Filters } from "./Filters/Filters";
import { LanguageContext, getLanguage } from "../../components/LanguageContext";
import { ResultsWrapper } from "./FilterWrapper";

interface Props {
    language: string
}

export const VregReportsMain: FC<Props> = (props:Props) =>     
    
        <Provider store={store}>
            <LanguageContext.Provider value={getLanguage(props.language)}>
            <Header />

            <div className="flex flex-col lg:flex-row justify-end lg:space-x-6">
                <Filters />

                <ResultsWrapper />
            </div>
            </LanguageContext.Provider>
        </Provider>
 