import React, { FC } from "react";
import { VregDetailViewModel } from "../../generatedTypes/FT.Web.ViewModels.Vreg";
import { translateLang } from "../../translate";
import { AddressView } from "../Address";
import { RelationList } from "../RelationList";

export const Info: FC<VregDetailViewModel> = (props: VregDetailViewModel) =>
    <div className="flex flex-col lg:flex-row lg:flex-wrap lg:-mx-3 -mt-3" >
        {props.orgNumber && props.orgNumber !== '' && (
            <div className="py-3 lg:px-6">
                <h2 className="text-base font-semibold mb-2">
                    {translateLang('vreg.orgnumber', props.languageCode)}
                </h2>
                <p>{props.orgNumber}</p>
            </div>
        )}

        {props.leiCode &&
            <div className="py-3 lg:px-6">
                <h2 className="text-base font-semibold mb-2">
                    {translateLang('vreg.leiCode', props.languageCode)}
                </h2>
                <p>{props.leiCode}</p>
            </div>
        }

        {!props.orgNumber && props.entityType !== "Person" && props.finanstilsynetId &&
            <div className="py-3 lg:px-6">
                <h2 className="text-base font-semibold mb-2">
                    Finanstilsynet Id
                </h2>
                <p>{props.finanstilsynetId}</p>
            </div>
        }

        {props.auditorNumber && (
            <div className="py-3 lg:px-6">
                <h2 className="text-base font-semibold mb-2">
                    {translateLang('vreg.auditorNumber', props.languageCode)}
                </h2>
                <p>{props.auditorNumber}</p>
            </div>
        )}

        {props.businessAddress &&
            <AddressView
                address={props.businessAddress}
                heading={translateLang('vreg.businessAddress', props.languageCode)}
            />
        }

        {!props.businessAddress && props.postalAddress &&
            <AddressView
                heading={translateLang('vreg.postalAddress', props.languageCode)}
                address={props.postalAddress}
            />
        }

        {props.links &&
            <div className="py-3 lg:px-6">
                <h2 className="text-base font-semibold mb-2">
                    {translateLang('vreg.links', props.languageCode)}
                </h2>
                {props.links.map(link =>
                    <p key={`${link.url}`}>
                        <a href={link.url} title={link.title} className="text-bluegreen-500 underline hover:no-underline icon icon--keyboardarrow__right" target="_blank" rel="noreferrer">
                            {link.title}
                        </a>
                    </p>)}
            </div>
        }

        {props.orgNumber &&
            <div className="py-3 lg:px-6">
                <h2 className="text-base font-semibold mb-2">
                    {translateLang('vreg.board', props.languageCode)}
                </h2>
                <p>
                    <a href={`https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=${props.orgNumber}`} title="" className="text-bluegreen-500 underline hover:no-underline icon icon--chevron__right" target="_blank" rel="noreferrer">
                        brreg.no
                    </a>
                </p>
            </div>
        }

        {props.professionalDirectorFor?.length > 0 &&
            <div className="py-3 lg:px-6">
                <RelationList
                    Relations={props.professionalDirectorFor}
                    languageCode={props.languageCode}
                    heading={translateLang('vreg.professionalDirector.heading', props.languageCode, [props.professionalDirectorFor.length])}
                    id="professionalDirectorFor"
                    headerSize=""
                />
            </div>
        }

        {props.remarks &&
            <div className="py-3 lg:px-6">
                <h2 className="text-base font-semibold mb-2">
                    {translateLang('vreg.remarks', props.languageCode)}
                </h2>

                <p className="mb-6">
                    {props.remarks}
                </p>
            </div>
        }
    </div>