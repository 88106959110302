import React, { FC, useState } from 'react'
import { MainLinkListBlockInspectionReportViewModel } from '../../generatedTypes/FT.Web.ViewModels/mainLinkListBlockInspectionReportViewModel'
import { CollapsiblePanel } from '../../components/collapsiblePanel/CollapsiblePanel'
import { ImageViewModel } from '../../generatedTypes/FT.Web.ViewModels.Blocks'

export const InspectionReportYearListing: FC<MainLinkListBlockInspectionReportViewModel> = (props) => {
    const [onlyDecisions] = useState(false)

    return (
        <>
            <h2 className="text-xl font-semibold">{props.heading}</h2>

            {props.reports.map(year =>
                <CollapsiblePanel
                    key={year.yearHeader}
                    title={year.yearHeader}
                    relatedLinks={year.linkItems}
                    contentId={parseInt(year.yearHeader, 10)}
                    openByDefault={year.isFirst}
                    descriptionText=''
                    coverImage={{} as ImageViewModel}
                    useFilter={onlyDecisions}
                />
            )}
        </>
    )
}
