import React, { FC, useState } from "react";
import { LicenceViewModel, VregServiceProviderType } from "../../generatedTypes/FT.Web.ViewModels.Vreg";
import { translateLang } from "../../translate";
import { ToggleList } from "../ToggleList";
import { CrossBorder } from "./CrossBorder";
import { RelationList } from "../RelationList";

interface Props {
    licence: LicenceViewModel,
    currentLicenceIndex: number,
    totalLicences: number,
    languageCode: string,
    defaultExpanded: boolean
}

export const Licence: FC<Props> = (props: Props) => {
    const [expanded, setExpanded] = useState(props.defaultExpanded);

    return (
        <section
            className="bg-white-400 p-6 lg:px-12 lg:py-9 border border-l-8 border-bluegreen-200 mb-6 relative"
        >

            {props.licence.serviceProviderType !== VregServiceProviderType.Agent &&
                props.licence.serviceProviderType !== VregServiceProviderType.Branch &&
                <>
                    <div className="flex flex-col lg:flex-row">
                        <div className="w-full lg:w-8/12">
                            <p className=" text-md text-yellow-600 mb-2">
                                {translateLang('vreg.currentAndTotalLicences', props.languageCode, [props.currentLicenceIndex, props.totalLicences])}
                            </p>

                            <h2 className="text-1.75xl lg:text-2xl font-semibold leading-9 mb-2">
                                {props.licence.licenceType}
                            </h2>

                            <p className=" text-md text-yellow-600 mb-6">
                                {props.licence.description}
                            </p>
                        </div>

                        <div className={`w-full lg:w-4/12 text-left lg:text-right lg:pt-12 ${expanded ? 'hidden' : ''}`}>
                            {/* <!-- Toggle between btn--registry__expand and btn--registry__collapse --> */}
                            <button
                                type="button"
                                className=" btn--registry btn--registry__expand"
                                onClick={() => setExpanded(!expanded)}
                            >
                                <span className="sr-only">
                                    {translateLang('vreg.ShowMoreInformation', props.languageCode)}
                                </span>
                                <span className="material-icons text-3xl">keyboard_arrow_down</span>
                            </button>
                        </div>
                    </div>

                    {/* Tilsynsmyndighet */}
                    <div className="flex flex-col space-y-4 lg:space-y-0 lg:space-x-6 lg:flex-row">
                        <div className="w-full lg:w-1/4">
                            <h3 className=" text-xl font-semibold mb-2">
                                {translateLang('vreg.supervisoryAuthority', props.languageCode)}
                            </h3>
                            <p className="text-base">
                                {props.licence.supervisoryAuthority}, {props.licence.supervisoryAuthorityCountry}
                            </p>
                        </div>
                        <div className="w-full lg:w-1/4">
                            <h3 className=" text-xl font-semibold mb-2">
                                {translateLang('vreg.registrationDate', props.languageCode)}
                            </h3>
                            <p className="text-base">
                                {props.licence.registrationDate}
                            </p>
                        </div>
                        {props.licence.hasSecurity &&
                            <div>
                                <h3 className=" text-xl font-semibold mb-2">
                                    {translateLang('vreg.hasSecurity', props.languageCode)}
                                </h3>

                                <p>{translateLang('common.yes', props.languageCode)}</p>
                            </div>
                        }
                    </div>
                    {/* Tilsynsmyndighet */}
                </>
            }

            {props.licence.serviceProviderType === VregServiceProviderType.Agent &&
                <>
                    <div className="flex flex-col lg:flex-row">
                        <div className="w-full lg:w-8/12">
                            <p className="text-md text-yellow-600 mb-2">
                                <span className="material-icons text-bluegreen-500 mr-1" aria-hidden="true">home_work</span>
                                {translateLang('vreg.agent.isAgentFor', props.languageCode)}
                            </p>

                            <h2 className=" text-2xl font-semibold uppercase mb-2">
                                <a href={`?id=${props.licence.licencedEntity.id}`} title={props.licence.licencedEntity.name} className=" text-bluegreen-500 icon icon--arrow__right hover:underline hover:underline-offset-8 hover:decoration-1">
                                    {props.licence.licencedEntity.name}
                                </a>
                            </h2>
                            <p className="text-md text-yellow-600 mb-6">{translateLang('vreg.agent.relatedToLicence', props.languageCode, [props.licence.licenceType])} </p>

                        </div>
                        <div className={`w-full lg:w-4/12 text-left lg:text-right lg:pt-12 ${expanded ? 'hidden' : ''}`}>
                            {/* <!-- Toggle between btn--registry__expand and btn--registry__collapse --> */}
                            <button
                                type="button"
                                className=" btn--registry btn--registry__expand"
                                onClick={() => setExpanded(!expanded)}
                            >
                                <span className="sr-only">
                                    {translateLang('vreg.ShowMoreInformation', props.languageCode)}
                                </span>
                                <span className="material-icons text-3xl">keyboard_arrow_down</span>
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col space-y-4 lg:space-y-0 lg:space-x-6 lg:flex-row">
                        <div>
                            <p className=" text-xl font-semibold mb-2">
                                {translateLang('vreg.agent.supervisoryAuthorityFor', props.languageCode, [props.licence.licencedEntity.name])}
                            </p>
                            <p className="text-base">
                                {props.licence.supervisoryAuthority}, {props.licence.supervisoryAuthorityCountry}
                            </p>
                        </div>
                        <div>
                            <p className=" text-xl font-semibold mb-2">
                                {translateLang('vreg.agent.registeredDate', props.languageCode)}
                            </p>
                            <p className="text-base">
                                {props.licence.registrationDate}
                            </p>
                        </div>
                    </div>
                </>
            }

            {props.licence.serviceProviderType === VregServiceProviderType.Branch &&
                <>
                    <div className="flex flex-col lg:flex-row">
                        <div className="w-full lg:w-8/12">

                            <p className="text-md text-yellow-600 mb-2">
                                <span className="material-icons text-bluegreen-500 mr-1" aria-hidden="true">home_work</span>
                                {translateLang('vreg.branch.isBranchOf', props.languageCode)}
                            </p>

                            <h2 className=" text-2xl font-semibold uppercase mb-2">
                                <a href={`?id=${props.licence.licencedEntity.id}`} title={props.licence.licencedEntity.name} className=" text-bluegreen-500 icon icon--arrow__right hover:underline hover:underline-offset-8 hover:decoration-1">
                                    {props.licence.licencedEntity.name}
                                </a>
                            </h2>
                            <p className="text-md text-yellow-600 mb-6">{translateLang('vreg.branch.relatedToLicence', props.languageCode, [props.licence.licenceType])} </p>

                        </div>

                        <div className={`w-full lg:w-4/12 text-left lg:text-right lg:pt-12 ${expanded ? 'hidden' : ''}`}>
                            {/* <!-- Toggle between btn--registry__expand and btn--registry__collapse --> */}
                            <button
                                type="button"
                                className=" btn--registry btn--registry__expand"
                                onClick={() => setExpanded(!expanded)}
                            >
                                <span className="sr-only">
                                    {translateLang('vreg.ShowMoreInformation', props.languageCode)}
                                </span>
                                <span className="material-icons text-3xl">keyboard_arrow_down</span>
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col space-y-4 lg:space-y-0 lg:space-x-6 lg:flex-row">
                        <div>
                            <p className=" text-xl font-semibold mb-2">
                                {translateLang('vreg.branch.supervisoryAuthorityFor', props.languageCode, [props.licence.licencedEntity.name])}
                            </p>
                            <p className="text-base">
                                {props.licence.supervisoryAuthority}, {props.licence.supervisoryAuthorityCountry}
                            </p>
                        </div>
                        <div>
                            <p className=" text-xl font-semibold mb-2">
                                {translateLang('vreg.branch.registeredDate', props.languageCode)}
                            </p>
                            <p className="text-base">
                                {props.licence.registrationDate}
                            </p>
                        </div>
                    </div>
                </>
            }

            {/* <!-- Toggle this DIV with class hidden --> */}
            <div className={`mt-6 ${expanded ? '' : 'hidden'}`}>
                {props.licence.licenceClassification && (
                    <>
                        <h3 className=" text-xl font-semibold mb-2">
                            {translateLang('vreg.typeOfAuditor', props.languageCode)}
                        </h3>
                        <p className="mb-6">
                            {props.licence.licenceClassification}
                        </p>
                    </>
                )}

                {props.licence.remarks &&
                    <>
                        <h3 className=" text-xl font-semibold mb-2">
                            {translateLang('vreg.remarks', props.languageCode)}
                        </h3>

                        <p className="mb-6">
                            {props.licence.remarks}
                        </p>
                    </>
                }

                {props.licence.auditorFor?.length > 0 && (
                    <h3 className=" text-xl font-semibold mb-2">
                        {translateLang('vreg.auditorFor', props.languageCode)}
                    </h3>
                )}
                {props.licence.auditorFor?.map((auditor) =>
                    <p className="mb-6" key={`auditor${auditor.id}`}>
                        <a href={`?id=${auditor.id}`} className="uppercase text-bluegreen-500 underline hover:no-underline icon icon--chevron__right">
                            {auditor.name}
                        </a>
                    </p>)}

                {props.licence.groupedServices?.length > 0 && (
                    <>
                        <h3 className=" text-xl font-semibold mb-2">
                            {translateLang('vreg.servicesAndClasses', props.languageCode)}
                        </h3>

                        {props.licence.groupedServices.map(group =>
                            <div key={`${group.groupName?.replace(' ', '')}`}>
                                <p>
                                    <strong>
                                        {group.groupName}
                                    </strong>
                                </p>
                                <ul className=" list-inside list-disc mb-6">
                                    {group.services.map(service =>
                                        <li key={`service${service.id}`}>{service.name}</li>
                                    )}
                                </ul>
                            </div>
                        )}
                    </>
                )}

                {props.licence.roles?.length > 0 &&
                    <div className="mb-6">                        
                        {props.licence.roles.map(role =>
                            <div key={`role_${role.description?.replace(' ', '')}`}>
                                <h3 className=" text-lg font-semibold mb-2">
                                    {role.description}
                                </h3>

                                <ul>
                                    {role.legalEntities.map(related =>
                                        <li className="mb-3" key={`related${related.id}`}>
                                            <a href={`?id=${related.id}`} className="uppercase text-bluegreen-500 underline hover:no-underline icon icon--chevron__right">
                                                {related.name}
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                }

                {props.licence.agents?.length > 0 &&
                    <ToggleList languageCode={props.languageCode} Items={props.licence.agents} isBranch={false} />
                }

                {props.licence.branches?.length > 0 &&
                    <ToggleList languageCode={props.languageCode} Items={props.licence.branches} isBranch />
                }
                {props.licence.borderCrossingActivity &&
                    <CrossBorder languageCode={props.languageCode} crossBorder={props.licence.borderCrossingActivity}
                        isBranch={props.licence.serviceProviderType === VregServiceProviderType.Branch} />
                }

                {props.licence.affiliates?.length > 0 &&
                    <>
                        <h3 className=" text-xl font-semibold mb-2">
                            {translateLang('vreg.networkAndAffiliates', props.languageCode)}
                        </h3>
                        <p className="mb-3">
                            {translateLang('vreg.networkAndAffiliatesDescription', props.languageCode)}
                        </p>
                        <ul className="mb-3">
                            {props.licence.affiliates.map(affiliate =>
                                <li key={affiliate.id}>
                                    <a href={affiliate.url} rel="noreferrer noopener" className="uppercase text-bluegreen-500 underline hover:no-underline icon icon--chevron__right">
                                        {affiliate.url.replace('https://', '').replace('mailto:', '')}
                                    </a>
                                </li>
                            )}
                        </ul>
                    </>
                }

                {props.licence.accreditedAuditors?.length > 0 &&
                    <RelationList
                        Relations={props.licence.accreditedAuditors}
                        languageCode={props.languageCode}
                        id="accreditedAuditors"
                        heading={translateLang('vreg.accreditedAuditors', props.languageCode, [props.licence.accreditedAuditors.length])}
                    />
                }

                {/* <!-- Close button for desktop --> */}
                {props.defaultExpanded === false &&
                    <button
                        type="button"
                        className="hidden lg:inline-block mt-6 icon icon--close icon--size__md underline hover:no-underline text-black-400 hover:text-bluegreen-500"
                        onClick={() => setExpanded(!expanded)}
                    >
                        {translateLang('vreg.buttons.close', props.languageCode)}
                    </button>
                }
            </div>

            {/* <!-- Open/close for mobile and desktop --> */}
            {props.defaultExpanded === false &&
                <div className={`relative lg:absolute lg:top-[90px] lg:right-[50px] ${expanded ? '' : 'hidden'}`}>
                    {/* <!-- Toggle between btn--registry__collapse and btn--registry__expand  --> */}
                    <button
                        type="button"
                        className="flex space-x-2 items-center mt-6 btn--registry btn--registry__collapse"
                        onClick={() => setExpanded(!expanded)}
                        aria-label={expanded ? translateLang('vreg.buttons.close', props.languageCode) : translateLang('vreg.buttons.expand', props.languageCode)}
                    >
                        <span className="inline-block btn--registry__icon" aria-hidden="true">
                            <span className="material-icons text-2xl lg:text-3xl">keyboard_arrow_down</span>
                        </span>
                        {/* <!-- Toggle between Close and Read more (remember translate)--> */}
                        <span className="btn--registry__text">
                            {translateLang('vreg.buttons.close', props.languageCode)}
                        </span>
                    </button>
                </div>
            }
        </section>);
}