import React from 'react';
import PropTypes from 'prop-types';
import { translateLang } from '../translate';

export default function Loader() {
    return <div>{translateLang('common.loading', )}</div>;
}

Loader.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    languageCode: PropTypes.string.isRequired
};
