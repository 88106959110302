import React, { FC, useState } from "react";
import { Relation } from "../generatedTypes/FT.Web.ViewModels.Vreg";
import { translateLang } from "../translate";

interface Props {
    Relations: Relation[],
    languageCode: string,
    id: string,
    heading: string,
    headerSize?: string
}

export const RelationList: FC<Props> = (props: Props) => {
    const [expanded, setExpanded] = useState(false);
    const sliderId = `slider_${props.id}`;
    const ToggleThreshold = 5;
    
    return (
        <>
            <React.StrictMode>
                <h2 className={`${props.headerSize} font-semibold mb-2`}>
                    {props.heading}
                </h2>

                {(props.Relations.length > ToggleThreshold) &&
                    <>
                        {/* <!-- Switch --> */}
                        <label className="checkbox--switch cursor-pointer mb-2 inline-flex space-x-3" htmlFor={sliderId}>
                            <div>
                                <span className="material-icons text-md">remove_red_eye</span>
                                {translateLang('vreg.list.showList', props.languageCode, [props.Relations.length])}
                            </div>
                            <div className="relative">
                                <input type="checkbox" id={sliderId} checked={expanded} onChange={() => setExpanded(!expanded)} />
                                <span className="checkbox--switch__slider" />
                            </div>
                        </label>
                        <p className=" text-sm text-yellow-600 italic mb-2">
                            {translateLang('vreg.list.showListDescription', props.languageCode)}
                        </p>
                        {/* <!-- // Switch --> */}
                    </>
                }

                {/* <!-- List of agents --> */}
                <ul className="mb-6 list-disc list-inside flex flex-col space-y-1">
                    {props.Relations.map((relation, index) =>
                        <li className={`${expanded || index <= ToggleThreshold ? '' : 'hidden'}`}
                            key={`${props.id}_${relation.id}`}>
                            <a href={relation.url}
                                title={relation.name}
                                className="text-base text-bluegreen-500 uppercase hover:underline hover:decration-1 hover:underline-offset-4">
                                {relation.name}
                            </a>
                            {relation.country &&
                                ` (${relation.country})`
                            }
                        </li>
                    )}

                    {props.Relations.length > ToggleThreshold &&
                        <li className={`pl-6 list-none ${expanded ? 'hidden' : ''}`} key={`${props.id}_button`}>
                            <button
                                aria-label={translateLang('vreg.list.showAllInList')}
                                onClick={() => setExpanded(!expanded)}
                                type="button" className="text-bluegreen-500 hover:underline hover:decration-1 hover:underline-offset-4">
                                <span className="sr-only">{translateLang('vreg.list.showAllInList')}</span>
                                ...
                            </button>
                        </li>
                    }
                </ul>
            </React.StrictMode>
        </>);
}

RelationList.defaultProps ={
    headerSize: "text-xl"
}