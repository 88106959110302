import React, { useState } from 'react';
import { translateLang } from '../../translate';

interface ShortcutsListProps {
    language: string;
    shortcuts: Shortcut[];
}

interface Shortcut {
    title: string;
    url: string;
}

const Shortcuts = ({ listElements, lang }: { listElements: Shortcut[]; lang: string }) => (
    <ul className="flex flex-wrap lg:mb-2 gap-y-3 gap-x-3 lg:gap-x-6">
        {listElements.map((el) => (
            <li key={el.url}>
                <a
                    href={el.url}
                    title={`${translateLang('common.goTo', lang)} ${el.title}`}
                    aria-label={`${translateLang('common.goTo', lang)} ${el.title}`}
                    className="btn btn--shortcut"
                >
                    <span>{el.title}</span>
                    <span className="material-icons" aria-hidden="true">
                        arrow_forward
                    </span>
                </a>
            </li>
        ))}
    </ul>
);

export const ShortcutsList: React.FC<ShortcutsListProps> = ({ language, shortcuts }) => {
    const [showShortcuts, setShowShortcuts] = useState<boolean>(false);

    return (
        <>
            {/* Small screens: Show shortcuts in a collapsible accordion, initially collapsed */}
            <div className="flex flex-col sm:-mx-3 lg:hidden">
                <button
                    type="button"
                    aria-label={`${translateLang(
                        showShortcuts ? 'common.hide' : 'common.show',
                        language
                    )} ${translateLang('publications.shortcuts', language).toLowerCase()}`}
                    aria-expanded={showShortcuts}
                    aria-controls="shortcutsNavigation"
                    onClick={() => setShowShortcuts(!showShortcuts)}
                    className="z-10 flex items-center justify-between w-full p-3 text-base font-semibold leading-5 cursor-pointer bg-white-400 text-black-400 focus-visible:outline-2 focus-visible:outline-bluegreen-400 focus-visible:outline-dashed focus-visible:outline-offset-0 group"
                >
                    <h5 className="group-hover:underline group-focus:underline">
                        {translateLang('publications.shortcuts', language)}
                    </h5>
                    <span className="text-lg material-icons text-bluegreen-500" aria-hidden="true">
                        {showShortcuts ? 'expand_less' : 'expand_more'}
                    </span>
                </button>
                <nav
                    id="shortcutsNavigation"
                    aria-label={translateLang('publications.shortcuts', language)}
                    className={`mb-3 bg-white-400 pt-2 px-3 pb-8 ${showShortcuts ? '' : 'hidden'}`}
                >
                    <Shortcuts listElements={shortcuts} lang={language} />
                </nav>
            </div>

            {/* Larger screens: Show the full list of shortcuts */}
            <nav
                aria-label={translateLang('publications.shortcuts', language)}
                className="flex-col hidden gap-6 lg:flex bg-white-400 p-normal"
            >
                <h2 className="text-2xl font-semibold leading-9 text-black-400">
                    {translateLang('publications.shortcuts', language)}
                </h2>
                <Shortcuts listElements={shortcuts} lang={language} />
            </nav>
        </>
    );
};
