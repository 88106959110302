import { combineReducers } from 'redux';
import { LOADED_MORE, SEARCH_LOADED, SET_SEARCH_QUERY } from './pillar2Actions';
import { setSessionStorageResult } from '../util/persistScroll';
import { getLocale } from '../translate';

const initialSearchResultState = {
    loading: false,
    items: [],
};

const initialSearchBoxState = {
    queryString: '',
};

const initialSettingsState = {
    lang: getLocale(),
};

export const initialState = {
    searchResult: initialSearchResultState,
    searchBox: initialSearchBoxState,
    settings: initialSettingsState,
};

const settings = (state = initialSettingsState) => state;

const searchResult = (state = initialSearchResultState, action) => {
    switch (action.type) {
        case SEARCH_LOADED:
            return {
                ...state,
                loading: false,
                items: action.searchResult.hits,
                page: action.searchResult.page,
                total: action.searchResult.totalHits,
                totalPages: action.searchResult.totalPages,
                pageSize: action.searchResult.pageSize,
            };
        case LOADED_MORE: {
            const obj = action.searchResult;
            if (state.items) {
                obj.Hits = action.searchResult.hits;
            }
            setSessionStorageResult('pillar2Result', obj);

            return {
                ...state,
                loading: false,
                items: action.searchResult.hits,
                page: action.searchResult.page,
                total: action.searchResult.totalHits,
                totalPages: action.searchResult.totalPages,
                pageSize: action.searchResult.pageSize,
            };
        }
        default:
            return state;
    }
};

const searchBox = (state = initialSearchBoxState, action) => {
    switch (action.type) {
        case SET_SEARCH_QUERY:
            return { ...state, queryString: action.queryString };
        default:
            return state;
    }
};

const pillar2Reducer = combineReducers({
    searchResult,
    searchBox,
    settings
});

export default pillar2Reducer;
