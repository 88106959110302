import React, { FC, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LanguageContext } from '../../components/LanguageContext';
import { Paginator } from '../../components/search/Paginator';
import { VregRootState } from './vregSearchStore';
import { vregSearchActions } from '../Services/VregSearchState';
import { window } from '../../util/ssrBase';

export const VregPaginator : FC = () => {
    const languageContext = useContext(LanguageContext);
    const dispatch = useDispatch();
    const pageNumber = useSelector((state: VregRootState) => state.vregSearchState.pageNumber);
    const totalPages = useSelector((state: VregRootState) => state.vregSearchState.totalPages); 
    
    return(
        <Paginator
        language={languageContext.code}
        onLoadMoreClickNext={()  => {
            dispatch(vregSearchActions.incrementPage());
            window.scrollTo(0, 0);
        }}
        onLoadMoreClickPrev={()  => {
            dispatch(vregSearchActions.decrementPage());
            window.scrollTo(0, 0);
        }}
        onJumpToPageClick={(page:number) => {
            dispatch(vregSearchActions.setPage(page));
            window.scrollTo(0,0);
        }}
        searchResultPage={pageNumber}
        searchResultTotalPages={totalPages}
    />   
    )
}