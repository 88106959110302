import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VregSearchInput } from "./SearchInput";
import { VregRootState } from "./vregSearchStore";
import { VregSearchResults } from "./VregSearchResult";
import { VregPaginator } from "./VregPaginator";
import { vregSearchActions } from "../Services/VregSearchState";
import { Popup } from "../../usersurvey/Popup";

interface VregWrapperProps{
    showPopup: boolean
}

export const VregMainWrapper: FC<VregWrapperProps> = (props) => {
    const dispatch = useDispatch();
    const mobileFilterOpen = useSelector((state: VregRootState) => state.vregSearchState.mobileFilterOpen);

    return (
        <>
            <VregSearchInput />

            {/* <!-- Toggle filter for mobile --> */}
            {mobileFilterOpen === false &&
                <div className="lg:hidden bg-white-400 px-6 py-2 mb-6">
                    <button type="button" className="btn btn--secondary" aria-label="Filter" onClick={() => dispatch(vregSearchActions.toggleMobileFilter())}>
                        <span className="material-icons" aria-hidden="true">filter_alt</span>
                        Filter
                    </button>
                </div>
            }
            {/* <!-- // Toggle filter for mobile --> */}

            <VregSearchResults />
            <VregPaginator />
            {props.showPopup &&
                <Popup />
            }
        </>
    )
}