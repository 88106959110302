import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadMore } from './pillar2Actions';
import SearchResults from '../components/search/searchResults';
import { trackEvent } from '../eventTracking';
import { LanguageContext } from '../components/LanguageContext';
import { Paginator } from '../components/search/Paginator';

class Pillar2SearchResults extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { searchResult, searchState, dispatch } = this.props;
        const canLoadMore = searchResult
            && (searchResult.totalPages > 1)
            && (searchResult.totalPages >= searchResult.page);
        const language = this.context;
        return (
            <div>
                <SearchResults searchResult={searchResult} />
                {canLoadMore && (
                    <Paginator
                        onLoadMoreClickPrev={() => {
                            trackEvent('Forrige side søketreff pilar2', `Side ${searchResult.page}`);
                            dispatch(loadMore(searchState, searchResult.page - 1));
                            window.scrollTo(0, 0);
                        }}
                        language={language.code}
                        onLoadMoreClickNext={() => {
                            trackEvent('Neste side søketreff pilar2', `Side ${searchResult.page}`);
                            dispatch(loadMore(searchState, searchResult.page + 1));
                            window.scrollTo(0, 0);
                        }}
                        searchResultPage={searchResult.page}
                        searchResultTotalPages={searchResult.totalPages}
                    />
                )}
            </div>
        );
    }
}

Pillar2SearchResults.propTypes = {
    searchResult: PropTypes.object.isRequired
};

Pillar2SearchResults.contextType = LanguageContext;

function mapStateToProps(state) {
    return {
        searchResult: state.searchResult,
        searchState: state.searchBox
    };
}

export default connect(mapStateToProps)(Pillar2SearchResults);
