import React, { FC } from "react"
import { useSelector } from "react-redux";
import { VregRootState } from "../Search/vregSearchStore";
import { SkeletonLoader } from "../../components/SkeletonLoader";
import { FilterResults } from "./FilterResults";

export const ResultsWrapper: FC = () => {

    const state = useSelector((s: VregRootState) => s.vregUserReportState);

    if (state.licenceGroupLicenceTypes.length === 0) {
        return (
            <div className="w-full lg:w-8/12">
                <SkeletonLoader type="list" />
            </div>
        )
    }
    
    return <FilterResults />
}