import React, { FC } from "react";
import { LicenceViewModel, VregDetailViewModel, VregServiceProviderType } from "../../generatedTypes/FT.Web.ViewModels.Vreg";
import { translateLang } from "../../translate";
import { window } from "../../util/ssrBase";
import { Header } from "../Header";
import { Licence } from "./Licence";
import { Relations } from "./Relations";

export const VregDetails: FC<VregDetailViewModel> = (props: VregDetailViewModel) =>
    <div className="-mx-6 lg:mx-0">
        <React.StrictMode>
            {/* Buttons - hidden until the functionality is declared */}
            <div className="mb-6 flex justify-end space-x-6 hidden">
                <a href="#" className="btn btn--secondary icon icon--file__download">
                    {translateLang('vreg.buttons.download', props.languageCode)}
                </a>
                <button type="button" onClick={() => { window.print(); return false; }} className="btn btn--secondary icon icon--print">
                    {translateLang('vreg.buttons.print', props.languageCode)}
                </button>
            </div>

            <Header {...props} />

            {props.licences?.length > 0 && (
                props.licences.map((licence: LicenceViewModel, index: number) =>
                    <Licence
                        licence={licence}
                        totalLicences={props.licences.filter(f => f.serviceProviderType === VregServiceProviderType.Licensed).length}
                        currentLicenceIndex={index + 1}
                        key={`${licence.licenceCode}_${licence.licencedEntity.id}`}
                        languageCode={props.languageCode}
                        defaultExpanded={props.licences.length === 1}
                    />
                )
            )}

            {/* after licences , agents and branches - list relations */}
            <Relations languageCode={props.languageCode} model={props} />

            
        </React.StrictMode>
    </div>
