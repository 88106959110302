import React, { ChangeEventHandler } from 'react';
import { Area } from './CheckBoxList'

export interface onChange {
    onChange: ChangeEventHandler
}

export const CheckBox: React.FC<Area & onChange> = (props): JSX.Element =>

    <><input type="checkbox"
        checked={props.selected}
        id={props.instanceId}
        value={props.instanceId}
        name={props.supervisionCategory ? "SupervisionCategory" : "PageType"}
        className="newsletter-checkbox"
        onChange={props.onChange} />
        <label htmlFor={props.instanceId}>
            <span>{props.name}</span>
        </label>
    </>