export function init() {
    const placeholderButton = document.getElementById('totopnavigation');

    const toggleClass = () => {
        // At top or header is visible, then hide button. Else show button
        if(window.scrollY === 0 || window.scrollY < 110){
            placeholderButton.classList.remove('lg:block');
        } else {
            placeholderButton.classList.add('lg:block');
        }
    };

    // Initial run
    toggleClass();

    // Run on scroll
    setTimeout(() => {
        window.addEventListener("scroll",() => {
            toggleClass();
        });
    }, 1000)    
}