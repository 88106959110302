import React, { useEffect, useState } from 'react';
import Autosuggest, { ContainerProps, InputProps } from 'react-autosuggest';
import { trackEvent } from '../eventTracking';
import { SearchResultSuggestion } from '../generatedTypes/FT.Web.Search.Models';
import { translateLang } from '../translate';
import { window } from '../util/ssrBase';
import { SuggestSearchRequestState } from './data/reducer';

interface Props {
    inputProps: InputProps<SearchResultSuggestion>;
    suggestedResult?: SearchResultSuggestion[];
    language: string;
    search: () => void;
    requestSuggestedResult: (state: SuggestSearchRequestState) => void;
    onSuggestionSelected: () => void;
    containerProps: ContainerProps
}

export const SuggestSearchInput: React.FC<Props> = (props) => {
    const initialSuggestions: SearchResultSuggestion[] = [];
    const [suggestions, setSuggestions] = useState(initialSuggestions);
    let shouldSearchOnEnter = true;

    // Not a useState because we don't want to re-render on every change.
    let suggestionUrl = '';
    const setSuggestionUrl = (url: string) => {
        suggestionUrl = url;
    };

    useEffect(() => {
        if (props.suggestedResult) {
            setSuggestions(props.suggestedResult);
        }
    }, [props.suggestedResult]);

    const renderSuggestion = (
        suggestion: SearchResultSuggestion,

    ) =>
        <a href={suggestion.url}>
            <div className="mb-4 text-base bold">{suggestion.name}</div>
            <div className="mb-4 text-xs">{suggestion.typeName}</div>
        </a>;


    const inputProps: InputProps<SearchResultSuggestion> = {
        placeholder: translateLang('globalsearch.texts.inputPlaceholder', props.language),

        onKeyDown: (e) => {
            switch (e.key) { // keyCode has been deprecated, key is where it's at: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
                case 'Enter': // Enter - Do global search onEnter when shouldSearchOnEnter is true
                    return shouldSearchOnEnter && props.search();
                case 'ArrowUp': // ArrowUp - On first arrow up, shouldSearchOnEnter is still true, thus navigating to the detail page "onEnter" won't work
                    shouldSearchOnEnter = false;
                    break;
                case 'ArrowDown': // ArrowDown - On first arrow down, shouldSearchOnEnter is still true, thus navigating to the detail page "onEnter" won't work
                    shouldSearchOnEnter = false;
                    break;
                case 'Esc': // Esc - In some cases, closing the suggestions box (pressing esc) will preserve shouldSearchOnEnter as false, therby preventing the following global search to be executed. Set shouldSearchOnEnter to true when pressing Esc
                    shouldSearchOnEnter = true;
                    break;
                default:
                    return null;
            }
            return null;
        },
        "aria-label": translateLang("search.searchFieldLabel", props.language),
        ...props.inputProps,
    };

    return (
        <>
            <label htmlFor={inputProps.id} className="sr-only">
                {translateLang('globalsearch.texts.inputPlaceholder', props.language)}
            </label>
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={(input) =>
                    props.requestSuggestedResult({ query: input.value, lang: props.language })
                }
                onSuggestionsClearRequested={() => setSuggestions([])}
                getSuggestionValue={() => inputProps.value}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}                
                containerProps={props.containerProps}
                onSuggestionHighlighted={(suggestion) => {
                    setSuggestionUrl(suggestion.suggestion?.url);
                }}
                onSuggestionSelected={(e, s) => {
                    window.location.assign(suggestionUrl);
                    props.onSuggestionSelected();
                    trackEvent('Søkeforslag valgt', s.suggestion.name);
                }}
                theme={{
                    container: "w-full flex relative",
                    input: "w-full",
                    suggestionsContainer: suggestions.length > 0 ? "" : "hidden",               
                    suggestionsContainerOpen: "absolute top-[46px] w-full border-bluegreen-200 border-2 bg-white-400 z-50",
                    suggestionHighlighted: "bg-lightgray-400 font-semibold",
                    suggestion: "pl-4 pr-4"

                }}
            />
        </>
    );
};
