import React, { useContext } from 'react';
import { RadioButton } from "../components/RadioButton";
import { SearchResult } from "../generatedTypes/FT.Web.Search.Models";
import { translateLang } from "../translate";
import { LanguageContext } from '../components/LanguageContext';

interface Props {
    searchResult: SearchResult | undefined;
    sortByDate: boolean;
    onSort: (sortByDate: boolean) => void;
}

const pageSize = 20;

const getHitsInfoString = (searchResult: SearchResult | undefined, language: string): string => {
    if (searchResult && searchResult.hits.length > 0) {
        if (searchResult.hits.length < pageSize)
            return translateLang('newsarchive.texts.totalHitsTemplate', language, [((searchResult.page - 1) * pageSize) + 1, ((searchResult.page - 1) * pageSize) + (searchResult.hits.length), searchResult.total]);

        return translateLang('newsarchive.texts.totalHitsTemplate', language, [((searchResult.page - 1) * pageSize) + 1, searchResult.page * pageSize, searchResult.total]);
    }

    return translateLang('newsarchive.texts.noHits', language);
};

export const SearchResultHeader: React.FC<Props> = (props) =>
{
  const language = useContext(LanguageContext).code;

  return (<div className="bg-white-400 mb-6 px-6 py-3 w-full flex justify-between flex-col lg:flex-row" >
        <div role="region" aria-label={translateLang('globalsearch.texts.hits')}>
            {getHitsInfoString(props.searchResult, language)}
        </div>

        <div className="flex flex-row space-x-4" role="region" aria-label={translateLang('globalsearch.texts.sorting')}>
            <div>
                {translateLang('globalsearch.sorting.sortby', language)}:
            </div>
            <RadioButton
                group="searchSorts"
                value="byRelevance"
                checked={!props.sortByDate}
                onChange={() => props.onSort(false)}>
                {translateLang('globalsearch.sorting.relevance', language)}
            </RadioButton>
            <RadioButton
                group="searchSorts"
                value="byDate"
                checked={props.sortByDate}
                onChange={() => props.onSort(true)}>
                {translateLang('globalsearch.sorting.date', language)}
            </RadioButton>
        </div>
    </div>);
}