import qs from 'qs';
import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import { SearchResult, SearchResultSuggestion } from '../../generatedTypes/FT.Web.Search.Models';
import { GlobalSearchFilter } from '../../generatedTypes/FT.Web.Search.Models/globalSearchFilter';
import { getLocale } from '../../translate';
import { clearSessionStorage, setSessionStorageResult } from '../../util/persistScroll';
import { window } from '../../util/ssrBase';
import { QueryParams } from '../searchBox';
import { Actions } from './actions';

export const updateUrl = (queryParams: QueryParams): void => {
    const queryString = qs.stringify(
        {
            q: queryParams.queryString,
            filter: queryParams.activeFilters,
            sortbydate: queryParams.sortByDate,
            page: queryParams.page
        },
        { encode: false }
    );

    // Erstatter replaceUrlParams
    window.history.pushState({}, '', `${window.location.pathname}?${queryString}`);
};

export interface SearchRequestState {
    queryParams: QueryParams;
    lang: string;
    page: number;
    isChanged?: boolean;
}

export interface SuggestSearchRequestState {
    query: string;
    lang: string;
}

export interface SearchFilters {
    isLoading: boolean;
    filterList?: GlobalSearchFilter[];
}

export interface SearchResultState {
    loading: boolean;
    searchResult?: SearchResult;
}

export interface SuggestedResultState {
    loading: boolean;
    suggestedResult?: SearchResultSuggestion[];
}

const initialSearchResultState: SearchResultState = {
    loading: false,
};

const initialSearchBoxState: SearchRequestState = {
    queryParams: {
        queryString: '',
        activeFilters: [],
        sortByDate: false,
        page: 1
    },
    lang: getLocale(),
    page: 1,
};

const initialSearchFilters: SearchFilters = {
    isLoading: false,
};

const initialSuggestedResultState: SuggestedResultState = {
    loading: false,
};

export const initialState = {
    searchResult: initialSearchResultState,
    searchBox: initialSearchBoxState,
    searchFilters: initialSearchFilters,
    suggestedResult: initialSearchResultState,
};

const suggestedResult = (state = initialSuggestedResultState, action: Actions) => {
    switch (action.type) {
        case getType(Actions.fetchSuggestedResult.request):
            return { ...state, loading: true };

        case getType(Actions.fetchSuggestedResult.success):
            return {
                ...state,
                loading: false,
                suggestedResult: action.payload,
            };

        default:
            return state;
    }
};

const searchResult = (state = initialSearchResultState, action: Actions) => {
    switch (action.type) {
        case getType(Actions.fetchSearchResults.request):
            if (action.payload.isChanged) {
                clearSessionStorage();
            }

            return {
                ...state,
                loading: true,
            };

        case getType(Actions.fetchSearchResults.success):
            return {
                ...state,
                loading: false,
                searchResult: action.payload,
            };

        case getType(Actions.loadMore.success): {
            const obj = action.payload;
            if (state.searchResult) {
                obj.hits = [...action.payload.hits];
            }
            setSessionStorageResult('searchPageResult', obj);

            return {
                ...state,
                loading: false,
                searchResult: action.payload,
            };
        }
        default:
            return state;
    }
};

const searchBox = (state = initialSearchBoxState, action: Actions) => {
    switch (action.type) {
        case getType(Actions.fetchSearchResults.request):
            return { ...state, queryParams: action.payload.queryParams };

        case getType(Actions.queryParamsChanged.success):
            updateUrl(action.payload.queryParams);
            return action.payload;

        default:
            return state;
    }
};

const searchFilters = (state = initialSearchFilters, action: Actions) => {
    switch (action.type) {
        case getType(Actions.fetchFilters.request):
            return { ...state, isLoading: true };

        case getType(Actions.fetchFilters.success):
            return { ...state, isLoading: false, filterList: action.payload };

        default:
            return state;
    }
};

export const globalSearchReducer = combineReducers({
    searchResult,
    searchBox,
    searchFilters,
    suggestedResult
});
