import React, { useContext, useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsExportData from "highcharts/modules/export-data";
import highchartsData from "highcharts/modules/data";
import highchartsExporting from "highcharts/modules/exporting";
import highchartsAccessibility from "highcharts/modules/accessibility";
import highchartsAnnotations from "highcharts/modules/annotations";
import highchartsPatterns from "highcharts/modules/pattern-fill";
import { HighchartsBlockViewModel } from '../../generatedTypes/FT.Web.ViewModels.Blocks';
import { LanguageContext } from '../../components/LanguageContext';
import { noNbOptions } from './no-nb';
import { mergeObjects, MergeObjectType } from '../../util/mergeObjects';
import { getDynamicOptions, highchartsOptions } from './options';

export const HighchartsComponent: React.FC<HighchartsBlockViewModel> = ({
    chart
}: HighchartsBlockViewModel) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

    const { code: language } = useContext(LanguageContext);

    const [optionsState, setOptionsState] = useState<Highcharts.Options>();

    const getChartConfig = (): null | { options: Highcharts.Options; template: Highcharts.Options } => {
        if (chart) {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = String(chart);
            const elementWithDataHc = tempDiv.querySelector('[data-hc]');
            if (elementWithDataHc) {
                const dataHcValue = elementWithDataHc.getAttribute('data-hc');
                try {
                    return JSON.parse(dataHcValue ?? "");
                } catch (error) {
                    console.error("Invalid JSON in data-hc attribute:", error);
                }
            }
        }
        return null;
    }

    useEffect(() => {
        highchartsAccessibility(Highcharts);
        highchartsData(Highcharts);
        highchartsExporting(Highcharts);
        highchartsExportData(Highcharts); // exporting must be added before exportData.
        highchartsAnnotations(Highcharts)
        highchartsPatterns(Highcharts)
        // modules must be added before setOptions is run.
        Highcharts.setOptions({
            lang: language === 'no' ? noNbOptions : {},
        });
    }, [])

    useEffect(() => {
        if (chartComponentRef.current) {
            const chartConfig = getChartConfig()
            if (chartConfig) {
                const { options, template } = chartConfig;
                const chartOptionConfig = mergeObjects(options.chart as MergeObjectType, template.chart as MergeObjectType)
                const plotOptionsConfig = mergeObjects(options.plotOptions as MergeObjectType, template.plotOptions as MergeObjectType)

                const mergedOptions = {
                    ...mergeObjects(highchartsOptions as MergeObjectType, options as MergeObjectType),
                    chart: mergeObjects(highchartsOptions.chart as MergeObjectType, chartOptionConfig),
                    plotOptions: mergeObjects(highchartsOptions.plotOptions as MergeObjectType, plotOptionsConfig),
                }

                setOptionsState(getDynamicOptions(mergedOptions))

            }
        };

        return () => {
            chartComponentRef.current?.chart.destroy()
        }
    }, [chartComponentRef])

    return (
        <HighchartsReact highcharts={Highcharts} options={optionsState} ref={chartComponentRef} />
    );
};
