import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../../redux/supervisionStore';
import { getLanguage, LanguageContext } from '../LanguageContext';
import { SubjectSelector } from './subjectSelector';

interface SubjectSelectorProps {
    language: string;
    dropdownText: string;
}

export class SubjectContainer extends React.Component<SubjectSelectorProps, unknown> {
    constructor(props: SubjectSelectorProps) {
        super(props);

        let currentState = store.getState();

        store.subscribe(() => {
            if (
                currentState.checkedSupervisionCategories !==
                store.getState().checkedSupervisionCategories
            ) {
                localStorage.setItem(
                    'supervisionCategories',
                    JSON.stringify(store.getState().checkedSupervisionCategories)
                );
            }
            currentState = store.getState();
        });
    }

    render(): React.ReactNode {
        return (
            <LanguageContext.Provider value={getLanguage(this.props.language)}>
                <Provider store={store}>
                    <SubjectSelector dropdownText={this.props.dropdownText} />
                </Provider>
            </LanguageContext.Provider>
        );
    }

}