import React, { FC, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { translateLang } from "../../../translate";
import { vregSearchActions } from "../../Services/VregSearchState";
import { VregRootState } from "../vregSearchStore";
import { LanguageContext } from "../../../components/LanguageContext";
import { LicenceTypeFilter } from "./LicenceTypeFilter";
import { RegisterTypeFilter } from "./RegisterTypeFilter";
import { CountryFilter } from "./CountryFilter";
import { CountyFilter } from "./CountyFilter";
import { PageIndex } from "../../../components/pageIndex/PageIndex";

export const FilterWrapper: FC = () => {

    const mobileFilterOpen = useSelector((state: VregRootState) => state.vregSearchState.mobileFilterOpen);
    const dispatch = useDispatch();
    const languageContext = useContext(LanguageContext);

    return (
        <div className={`fixed lg:relative bg-[rgba(40,40,40,0.8)] lg:bg-transparent lg:block inset-0 ${mobileFilterOpen ? '' : 'hidden'}`}>
            <div className={`absolute z-1000 lg:relative bottom-0 left-0 h-[85vh] lg:h-auto w-full bg-white-400 lg:bg-transparent rounded-t-[20px] mb-6 lg:block ${mobileFilterOpen ? '' : 'hidden'}`}>
                {/* <!-- Mobile header --> */}
                {mobileFilterOpen &&
                    <div className="w-full lg:hidden flex flex-col pt-3 mb-4 px-6 items-center">
                        <button type="button" onClick={() => { dispatch(vregSearchActions.toggleMobileFilter()) }} aria-label={translateLang('vreg.filters.close', languageContext.code)}
                            className="w-10 h-2 bg-black-200 rounded-lg mb-7">
                            <span className="sr-only">{translateLang('vreg.filters.close', languageContext.code)}</span>
                        </button>
                        <h2 className="font-semibold text-base border-b border-black-200 pb-6 w-full text-center">
                            {translateLang('vreg.filters.filter', languageContext.code)}
                        </h2>
                    </div>
                }
                {/* <!-- // Mobile header --> */}

                <div className="h-[calc(85vh-180px)] overflow-scroll lg:h-auto lg:overflow-hidden px-6 lg:px-0">
                    <LicenceTypeFilter />

                    <RegisterTypeFilter />

                    <CountryFilter />

                    <CountyFilter />

                </div>

                {!mobileFilterOpen &&
                    <PageIndex
                        language={languageContext.code}                    
                    />
                }
            </div>

            

        </div>
    );
}